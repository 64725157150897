// @ts-nocheck
export default function throttle<T>(func: (props: T) => void, timeFrame: number) {
  let lastTime = 0;
  return function (...args) {
      let now = new Date();
      if (now - lastTime >= timeFrame) {
          func(...args);
          lastTime = now;
      }
  };
}
