import { useCallback, useEffect, useRef, useState } from 'react';
import { AnimationControls, useAnimation } from 'framer-motion';
import {
  IArrangeItemsItemOption,
  IArrangeItemsTaskSession,
  IPlayArrangeItemsItem,
  IPlayArrangeItemsTask,
  tAnswer,
  tArrangeItemsAnswer
} from '@adeptlms/lingu-students-react-shared';
import {
  usePlayAnswers,
  useStreakFactor
} from 'students/views/pages/LessonTasks/TaskSubjects/Play/common/hooks';
import { usePromptContext } from 'students/views/shared/hooks';

const DROP_SHADOW_ANIMATION_TIMEOUT = 2400;
const NEXT_ITEM_TIMEOUT = 1200;

interface ISelectArrangeItemsAnswerPayload {
  itemId: number;
  mistakesCount: number;
  itemIndex: number;
}

interface IReturnedValue {
  items: Array<IPlayArrangeItemsItem>;
  instruction: string | null;
  streakNumber: number;
  streakPercent: number;
  currentItemIndex: number;
  shadowAnimation: AnimationControls;
  submitTask: () => void;
  handleSelectAnswer: (payload: ISelectArrangeItemsAnswerPayload) => void;
  handleCompleteTask: (goToFinishScreen: () => void) => () => void;
  handleSkip: (itemId: number) => void;
  handleFinishTask: () => void;
}

function usePlayArrangeItemsTask({
  task,
  taskSession,
  onNext,
  onAnswer,
  onFinish
}: {
  task: IPlayArrangeItemsTask;
  taskSession: IArrangeItemsTaskSession | undefined;
  onNext: (() => void) | undefined;
  onAnswer: ((answer: tArrangeItemsAnswer) => void) | undefined;
  onFinish: ((results: tAnswer) => void) | undefined;
}): IReturnedValue {
  const { items, instruction } = task;
  const answersFn = usePlayAnswers<tArrangeItemsAnswer[]>();

  const { streakNumber, streakPercent, onTheFirstTry } = useStreakFactor();
  const { makeDirty } = usePromptContext();
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const shadowAnimation = useAnimation();
  const nextItemTimer = useRef<number | null>(null);
  const dropShadowTimer = useRef<number | null>(null);

  useEffect(() => {
    nextItemTimer.current = null;
    dropShadowTimer.current = null;

    return () => {
      if (nextItemTimer.current) {
        clearTimeout(nextItemTimer.current);
      }

      if (dropShadowTimer.current) {
        clearTimeout(dropShadowTimer.current);
      }
    };
  }, [task.id]);

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      answersFn(taskSession.taskItemSessions);
    } else {
      answersFn(
        items.map((item) => {
          return {
            completed: false,
            options: [],
            taskItemId: item.id,
            mistakesCount: 0,
            skipped: false,
            url: ''
          };
        })
      );
    }

    onTheFirstTry(false);
  }, [taskSession, items, onTheFirstTry, answersFn]);

  const updateAnswer = (itemId: number, data: Partial<tArrangeItemsAnswer>) => {
    const answerIndex = answersFn().findIndex((answer) => answer.taskItemId === itemId);

    const { mistakesCount, skipped } = data;

    if (answerIndex < 0) return;

    const answer = answersFn()[answerIndex];
    const newAnswerData = { ...answer, ...data };

    answersFn([
      ...answersFn().slice(0, answerIndex),
      newAnswerData,
      ...answersFn().slice(answerIndex + 1)
    ]);

    onAnswer && onAnswer(newAnswerData);

    onTheFirstTry(!(mistakesCount || skipped));
  };

  const handleSelectAnswer = (payload: ISelectArrangeItemsAnswerPayload) => {
    const { itemId, mistakesCount, itemIndex } = payload;
    const item = items[itemIndex];

    makeDirty(true);

    shadowAnimation.start({
      y: 0,
      opacity: [1, 0],
      transition: {
        y: {
          duration: 1
        },
        opacity: {
          duration: 1.4,
          delay: 0.6
        }
      }
    });

    nextItemTimer.current = window.setTimeout(() => {
      setCurrentItemIndex((prev) => prev + 1);
    }, NEXT_ITEM_TIMEOUT);

    dropShadowTimer.current = window.setTimeout(() => {
      updateAnswer(itemId, {
        options: item.options.map((option: IArrangeItemsItemOption) => option.position),
        mistakesCount,
        skipped: false
      });
    }, DROP_SHADOW_ANIMATION_TIMEOUT);
  };

  const handleSkip = (itemId: number) => {
    updateAnswer(itemId, {
      skipped: true
    });

    setCurrentItemIndex((prev) => prev + 1);
  };

  const handleFinishTask = () => {
    onNext && onNext();
  };

  const submitTask = useCallback(() => {
    onFinish && onFinish(answersFn());
  }, [onFinish, answersFn]);

  const handleCompleteTask = (goToFinishScreen: () => void) => () => {
    submitTask();

    goToFinishScreen();
  };

  return {
    items,
    instruction,
    streakNumber,
    streakPercent,
    currentItemIndex,
    shadowAnimation,
    submitTask,
    handleSelectAnswer,
    handleCompleteTask,
    handleSkip,
    handleFinishTask
  };
}

export default usePlayArrangeItemsTask;
