import styled from 'styled-components';
import { customMediaQuery } from 'students/views/shared/styled';

const SHeadContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;

  ${customMediaQuery('tablet')} {
    flex-grow: 0;
  }
`;

export default SHeadContainer;
