import { Howl } from 'howler';

export type tHowlAudioPlayerStatus =
  | 'added'
  | 'played'
  | 'stopped'
  | 'paused'
  | 'ended'
  | 'visible-paused';

export interface IEventHandlerOptions {
  player: any;
  soundId: number;
}

export interface IAudioPlayer {
  play: (id?: number) => void;
  stop: (id?: number) => void;
  pause: (id?: number) => void;
  visibilityPause: (id?: number) => void;
  togglePlay: (id?: number) => void;
  playing: (id?: number) => boolean;
  mute: (mute: boolean, id?: number) => void;
  volume: (value?: number) => Howl | number | boolean;
  unload: () => void;
  unsubscribe: () => void;
  seek: (seek: number, id?: number) => void;
  getSpeed: () => number;
  setSpeed: (speed: number, id?: number) => void;
}

export interface IAudioPlayerOptions {
  src: string;
  preload?: boolean | 'metadata';
  progressInterval?: number;
  autoplay?: boolean;
  autoPlayDelay?: number;
  loop?: boolean;
  volume?: number;
  speed?: number;
  onProgress?: (progress: number) => void;
  onStop?: (options: IEventHandlerOptions) => void;
  onPause?: (options: IEventHandlerOptions) => void;
  onLoad?: (options: IEventHandlerOptions) => void;
  onMute?: (options: IEventHandlerOptions) => void;
  onVolume?: (options: IEventHandlerOptions) => void;
  onRate?: (options: IEventHandlerOptions) => void;
  onSeek?: (options: IEventHandlerOptions) => void;
  onFade?: (options: IEventHandlerOptions) => void;
  onUnlock?: (options: IEventHandlerOptions) => void;
  onEnd?: (options: IEventHandlerOptions) => void;
  onPlay?: (options: IEventHandlerOptions) => void;
  onError?: (error: unknown) => void;
}

export enum AudioTags {
  SoundEffects = 'sound-effects',
  MusicPlayback = 'music-playback'
}

export type tTaskAudioTags = (AudioTags | string)[];

export interface ITaskAudioManagerItem extends IAudioPlayerOptions {
  id: string | number;
  tags?: tTaskAudioTags;
  caching?: boolean;
}

export type tTaskAudioManagerItems = ITaskAudioManagerItem[];

export interface ITaskAudioManagerOptions {
  soundEffects?: boolean;
  musicPlayback?: boolean;
}

// export interface ITaskAudioRepositoryCollectionItem {
//   id: string | number;
//   src: string | string[];
//   tags: tTaskAudioTags;
//   player: HowlAudioPlayer;
//   caching: boolean;
// }

// export type tTaskAudioRepositoryCollection = ITaskAudioRepositoryCollectionItem[];

// export interface IAudioManagerReturnedObject {
//   manager: TaskAudioManager;
//   add: (item: ITaskAudioManagerItem) => void;
//   addUnique: (item: ITaskAudioManagerItem) => void;
//   reset: () => void;
//   unregister: (id: string | number) => void;
//   unregisterUncached: (id: string | number) => void;
//   getAll: () => tTaskAudioRepositoryCollection;
//   stop: () => void;
//   unsubscribe: () => void;
//   playById: (id: string | number) => void;
//   getById: (id: string | number) => ITaskAudioRepositoryCollectionItem | null;
//   visibilityPlay: () => void;
//   getByTag: (tag: string) => tTaskAudioRepositoryCollection;
//   unload: () => void;
//   visibilityPause: () => void;
//   addTagToMute: (tag: string) => void;
//   removeTagFromMute: (tag: string) => void;
// }
