import { IPhrase, IWordWaterfallItem } from '@adeptlms/lingu-students-react-shared';
import React, { useEffect, useState } from 'react';
import shuffle from 'students/utils/native/shuffle';
import { IGameContainerProps } from '../types';
import WordWaterfall from './WordWaterfall';

const WORDS_NUMBER_PER_ROUND = 5;
const MAX_ROUNDS_NUMBER = 8;

const WordWaterfallContainer: React.FC<IGameContainerProps> = ({
  taskId,
  phrases,
  difficulty,
  maxRounds,
  onRoundComplete,
  onNext = () => {},
  className
}) => {
  const [rounds, setRounds] = useState<IWordWaterfallItem[]>([]);
  useEffect(() => {
    setRounds(combineInRounds(phrases, maxRounds));
  }, [phrases, maxRounds]);

  if (!phrases.length) return null;

  return (
    <WordWaterfall
      taskId={taskId}
      rounds={rounds}
      difficulty={difficulty}
      onRoundComplete={onRoundComplete}
      onFinish={onNext}
      className={className}
    />
  );
};

export default WordWaterfallContainer;

function createAnswerVariants(
  phrases: IPhrase[],
  answer: IPhrase
): IWordWaterfallItem['list'] {
  const variants = shuffle(phrases).slice(0, WORDS_NUMBER_PER_ROUND);

  if (!variants.some(({ id }) => id === answer.id)) {
    variants.splice(0, 1, answer);
  }

  return shuffle(variants.map(({ body, id }) => ({ word: body, id })));
}

function combineInRounds(phrases: IPhrase[], maxRounds: number = MAX_ROUNDS_NUMBER) {
  const icons: IPhrase[] = [];
  const texts: IPhrase[] = [];

  phrases.forEach((phrase) => {
    if (phrase.animationURL || phrase.imageURL) {
      icons.push(phrase);
    } else if (phrase.wordTranslation) {
      texts.push(phrase);
    }
  });
  const result = [...shuffle(icons), ...shuffle(texts)];
  result.splice(maxRounds);

  const rounds: IWordWaterfallItem[] = result.map((word) => ({
    id: word.id,
    question: getQuestionFromPhrase(word),
    answer: word.body,
    list: createAnswerVariants(phrases, word)
  }));

  return rounds;
}

function getQuestionFromPhrase({
  wordTranslation,
  imageURL,
  animationURL,
  colorRequired
}: IPhrase): IWordWaterfallItem['question'] {
  return {
    text: wordTranslation,
    imageURL,
    animationURL,
    colorRequired
  };
}
