import {
  useTaskGameConfig,
  IGame,
  useTaskGameplay,
  ITaskGameplay,
  useTaskGamesMetrics,
  IMetrics,
  IPhrase,
  tGameType
} from '@adeptlms/lingu-students-react-shared';
import { gamesMap } from './gamesConfig';
import { tGameComponent } from './types';

type tGame = IGame & { component?: tGameComponent };
export interface IUseTaskGamesProps {
  lessonId: number;
  phrases: IPhrase[];
  gameType: tGameType;
  onGameComplete: () => void;
  silentMode?: boolean;
}

interface IReturnedObject {
  game: tGame | null;
  gameplay: ITaskGameplay;
  metrics: IMetrics;
}

export default function useTaskGames({
  gameType,
  silentMode,
  phrases,
  lessonId,
  onGameComplete
}: IUseTaskGamesProps): IReturnedObject {
  const gameConfig = useTaskGameConfig(gameType);
  const component = gamesMap.get(gameType);

  const game =
    gameConfig && component
      ? {
          config: gameConfig.config,
          component
        }
      : null;

  const metrics = useTaskGamesMetrics(gameType);
  const gameplay = useTaskGameplay({
    gameType,
    phrases,
    lessonId,
    onGameComplete,
    metrics,
    silentMode
  });

  return { game, gameplay, metrics };
}
