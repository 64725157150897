import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IPlayArrangeItemsTask } from '@adeptlms/lingu-students-react-shared';
import { useMistakesCount } from 'students/views/pages/LessonTasks/TaskSubjects/Play/common/hooks';
import DashedPagination from 'students/views/shared/components/DashedPagination';

import ArrangeItemsViewport from './components/ArrangeItemsViewport';
import ItemResultScreen from './components/ItemResultScreen';
import { ISelectAnswerPayload } from './types';
import { RESULTS_SCREEN_TIMEOUT } from './hooks/useItemResultScreen';

enum Screen {
  Item = 'item',
  Result = 'result'
}

const GO_TO_RESULTS_SCREEN_TIMEOUT = 1500;

interface IPlayArrangeItemsMainScreenProps {
  task: IPlayArrangeItemsTask;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  onComplete: () => void;
  onSkip: (itemId: number) => void;
}

const PlayArrangeItemsMainScreen: FC<IPlayArrangeItemsMainScreenProps> = ({
  task,
  onSelectAnswer,
  onComplete,
  onSkip
}) => {
  const { items } = task;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentScreen, setCurrentScreen] = useState<Screen | null>(Screen.Item);
  const currentItem = items[currentIndex];
  const { getMistakesCount, addOneMistake } = useMistakesCount(0, [currentItem.id]);
  const resultScreenTimer = useRef<number | null>(null);
  const sendAnswerTimer = useRef<number | null>(null);

  useEffect(() => {
    resultScreenTimer.current = null;
    sendAnswerTimer.current = null;

    return () => {
      if (resultScreenTimer.current) {
        clearTimeout(resultScreenTimer.current);
      }

      if (sendAnswerTimer.current) {
        clearTimeout(sendAnswerTimer.current);
      }
    };
  }, [task.id]);

  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleNext = useCallback(() => {
    setCurrentScreen(null);

    if (currentIndex + 1 === items.length) {
      handleComplete();
    } else {
      setCurrentIndex((currentIndex: number) => currentIndex + 1);
      setCurrentScreen(Screen.Item);
    }
  }, [currentIndex, items, setCurrentIndex, setCurrentScreen, handleComplete]);

  const handleSelectAnswer = useCallback(() => {
    resultScreenTimer.current = window.setTimeout(() => {
      setCurrentScreen(Screen.Result);
    }, GO_TO_RESULTS_SCREEN_TIMEOUT);

    sendAnswerTimer.current = window.setTimeout(() => {
      onSelectAnswer({
        itemId: currentItem.id,
        itemIndex: currentIndex,
        mistakesCount: getMistakesCount()
      });
    }, RESULTS_SCREEN_TIMEOUT + GO_TO_RESULTS_SCREEN_TIMEOUT);
  }, [setCurrentScreen, currentIndex, onSelectAnswer, currentItem, getMistakesCount]);

  const handleItemNext = useCallback(() => {
    onSkip(currentItem.id);
    handleNext();
  }, [onSkip, currentItem, handleNext]);

  const handleItemPrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setCurrentScreen(Screen.Item);
    }
  }, [currentIndex, setCurrentIndex, setCurrentScreen]);

  const renderScreen = useCallback(
    (screen: Screen | null): JSX.Element | null => {
      switch (screen) {
        case Screen.Item:
          return (
            <>
              <ArrangeItemsViewport
                key={currentIndex}
                options={currentItem.options}
                onAnswer={handleSelectAnswer}
                addOneMistake={addOneMistake}
              />

              <DashedPagination
                itemsNumber={items.length}
                currentIndex={currentIndex}
                taskId={task.id}
                onNextItem={handleItemNext}
                onPrevItem={handleItemPrev}
              />
            </>
          );

        case Screen.Result:
          return <ItemResultScreen item={currentItem} onFinish={handleNext} />;

        default:
          return null;
      }
    },
    [
      currentItem,
      currentIndex,
      items,
      handleNext,
      handleSelectAnswer,
      handleItemNext,
      handleItemPrev,
      addOneMistake,
      task.id
    ]
  );

  return renderScreen(currentScreen);
};

export default PlayArrangeItemsMainScreen;
