import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import {
  IInlineDropdownItem,
  IInlineDropdownTask,
  IInlineDropdownTaskItemSession,
  IInlineDropdownTaskSession,
  tInlineDropdownAnswer
} from '@adeptlms/lingu-students-react-shared';
import { usePromptContext } from 'students/views/shared/hooks';

import { ICheckTaskProps } from '../types';
import { BaseCheckTaskBody, CheckNavFooter, SCheckTaskWrapper } from '../../components';
import InlineDropdownItem, { ISelectAnswerPayload } from './InlineDropdownItem';
import { useTaskItems } from '../../useTaskItems';
import { isLessonCompleted } from '../../helpers';

const CheckInlineDropdownTask: FC<
  ICheckTaskProps<IInlineDropdownTask, IInlineDropdownTaskSession>
> = ({ task, taskSession, isCompleting, className, onNext }) => {
  const { makeDirty } = usePromptContext();
  const [answers, setAnswers] = useState<tInlineDropdownAnswer>([]);
  const { taskItems, sessionTaskItems } = useTaskItems<
    IInlineDropdownItem,
    IInlineDropdownTaskItemSession
  >(task.items, taskSession?.taskItemSessions);

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      setAnswers(sessionTaskItems);
    } else {
      setAnswers(
        taskItems.map((item: IInlineDropdownItem, index) => ({
          completed: false,
          id: index,
          taskItemId: item.id,
          answers: new Array(item.answers.length).fill(''),
          mistakesCount: 0,
          skipped: false,
          url: taskSession
            ? `/task_sessions/${taskSession.id}/task_item_sessions/${item.id}`
            : ''
        }))
      );
    }
  }, [sessionTaskItems, taskItems, taskSession]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { value, wordIndex, itemId } = payload;

    const answerIndex = answers.findIndex((answer) => answer.taskItemId === itemId);
    if (answerIndex < 0) return;

    const answer = answers[answerIndex];
    const newItemAnswers = [...answer.answers];
    newItemAnswers.splice(wordIndex, 1, value);

    setAnswers([
      ...answers.slice(0, answerIndex),
      {
        ...answer,
        completed: true,
        answers: newItemAnswers,
        skipped: false
      },
      ...answers.slice(answerIndex + 1)
    ]);

    makeDirty(true);
  };

  const handleNext = () => {
    onNext(answers);
  };

  const handleSkip = () => {
    onNext(
      answers.map((answer: IInlineDropdownTaskItemSession) => ({
        ...answer,
        answers: [],
        skipped: true
      }))
    );
  };

  if (!answers.length) return null;

  function getValuesForItem(item: IInlineDropdownItem) {
    return answers.find((answer) => answer.taskItemId === item.id)?.answers;
  }

  return (
    <>
      <SCheckTaskWrapper>
        <SBaseCheckTaskBody className={cn(className)} task={task}>
          {taskItems.map((item: IInlineDropdownItem) => (
            <InlineDropdownItem
              key={item.id}
              item={item}
              value={getValuesForItem(item)}
              onSelectAnswer={handleSelectAnswer}
            />
          ))}
        </SBaseCheckTaskBody>
      </SCheckTaskWrapper>

      <CheckNavFooter
        isCompleting={isCompleting}
        onNext={handleNext}
        onSkip={handleSkip}
        isLessonCompleted={isLessonCompleted(taskSession)}
      />
    </>
  );
};

const SBaseCheckTaskBody = styled(BaseCheckTaskBody)`
  overflow: unset;
`;

export default CheckInlineDropdownTask;
