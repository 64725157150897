import { useCallback, useContext, useEffect, useMemo } from 'react';
import { usePageVisibility } from 'students/views/shared/hooks';
import {
  AudioTags,
  IAudioManagerReturnedObject,
  IEventHandlerOptions,
  tTaskAudioManagerItems
} from '../models';
import useAudioPreferences from './useAudioPreferences';
import AudioContext from '../AudioContext';

interface IReturnedObject {
  playById: IAudioManagerReturnedObject['playById'];
}

export default function useTaskAudioPlayer(
  items: tTaskAudioManagerItems | null,
  ignorePreferences = false
): IReturnedObject {
  const {
    addUnique,
    visibilityPause,
    visibilityPlay,
    playById,
    unregisterUncached,
    addTagToMute,
    removeTagFromMute,
    getById
  } = useContext(AudioContext);

  const cleanedAudioItems = useMemo(
    () =>
      items?.map(({ autoplay, ...item }) => {
        return item;
      }),
    [items]
  );

  const handlePlay = useCallback(
    (options: IEventHandlerOptions, onPlay?: (options: IEventHandlerOptions) => void) => {
      onPlay && onPlay(options);
    },
    []
  );

  const handleBGMusicToggle = useCallback(
    (turnOn: boolean) => {
      items?.forEach((item) => {
        if (item.tags?.includes(AudioTags.MusicPlayback)) {
          turnOn ? playById(item.id) : getById(item.id)?.player.stop();
        }
      });
    },
    [items, playById, getById]
  );

  useAudioPreferences(
    addTagToMute,
    removeTagFromMute,
    ignorePreferences,
    handleBGMusicToggle
  );

  useEffect(() => {
    cleanedAudioItems?.forEach((item) => {
      const { onPlay } = item;

      addUnique({
        ...item,
        onPlay: (options) => {
          handlePlay(options, onPlay);
        }
      });
    });

    handleBGMusicToggle(true);

    return () => {
      // unregister and delete from collection tracks registered by this hook only
      cleanedAudioItems?.forEach((item) => {
        unregisterUncached(item.id);
      });
    };
  }, [addUnique, handlePlay, cleanedAudioItems, unregisterUncached, handleBGMusicToggle]);

  const onVisibilityChange = useCallback(
    (isHidden: boolean) => {
      if (isHidden) {
        visibilityPause();
      } else {
        visibilityPlay();
      }
    },
    [visibilityPause, visibilityPlay]
  );

  usePageVisibility(onVisibilityChange);

  return { playById };
}
