import { IArrangeTextItemOption } from '@adeptlms/lingu-students-react-shared';
import { Reorder } from 'framer-motion';
import React, { useCallback } from 'react';
import { ArrangeTextItem } from './ArrangeTextItem';

interface IProps {
  options: IArrangeTextItemOption[];
  itemIndex: number;
  onDragEnd: (itemIndex: number, options: IArrangeTextItemOption[]) => void;
}

export const ArrangeTextItems: React.FC<IProps> = ({ options, itemIndex, onDragEnd }) => {
  const handleReorder = useCallback(
    (items: IArrangeTextItemOption[]) => {
      onDragEnd(itemIndex, items);
    },
    [itemIndex, onDragEnd]
  );

  return (
    <Reorder.Group
      axis="y"
      style={{ padding: 0 }}
      values={options}
      onReorder={handleReorder}
    >
      {options.map((option, index) => (
        <ArrangeTextItem key={option.id} option={option} index={index} />
      ))}
    </Reorder.Group>
  );
};
