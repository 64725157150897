import { motion as m } from 'framer-motion';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { SFullHeightDiv } from '../common/styled';
import background from './assets/background.svg';

export const SContainer = styled(SFullHeightDiv)`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #0ca2cc 0%, #4dc1e2 35.42%, #0790c0 100%);

  ${customMediaQuery('tablet')} {
    background: url(${background}) center/cover,
      linear-gradient(180deg, #58bbd8 19.79%, #1086b0 100%);
  }

  @media (max-height: 580px) {
    overflow-y: auto;
    min-height: 600px;
  }
`;

export const SNextButtonContainer = styled.div`
  position: absolute;
  bottom: 40px;

  button {
    margin: 0 auto;
  }
`;

export const SHeader = styled.div`
  font-size: 1rem;
  line-height: 1.1875rem;
  color: var(--color-light-white);
  opacity: 0.8;
  margin: 0;
  padding: 2rem 0 0;

  ${customMediaQuery('tablet')} {
    margin-block-end: 2rem;
  }

  @media (min-height: 992px) {
    margin-block-end: 8.825rem;
  }

  @media (min-height: 700px) {
    margin: 3rem 0 0 0;
    padding: 2rem 0 1.5rem;
  }
`;

export const SCardContainer = styled.div`
  padding: 16px;
  max-width: 100%;
  width: 375px;
  flex: 1;
  max-height: 558px;
  position: relative;
  margin-block-end: 6rem;
`;

export const SButtonContainer = styled.div`
  position: absolute;
  left: -100px;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;

  .arrowButton {
    &.back {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  button {
    opacity: 0.6;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
    &:disabled {
      opacity: 0.2;
    }
  }
`;

export const SCard = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(251, 252, 255, 1) 0%,
    rgba(251, 252, 255, 1) 13.83%,
    var(--color-light-white) 100%
  );
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
  border-radius: 26px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 24px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.shaking {
    animation: shake 1s linear;
    animation-iteration-count: 2;
  }

  @keyframes shake {
    10%,
    90% {
      transform: rotate(-0.5deg);
    }

    20%,
    80% {
      transform: rotate(1deg);
    }

    30%,
    50%,
    70% {
      transform: rotate(-2deg);
    }

    40%,
    60% {
      transform: rotate(2deg);
    }
  }
`;

export const SCardContent = styled(m.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: stretch;
  }
`;

export const SAudio = styled.div`
  margin-block-end: 76px;

  &.hasImage {
    margin-block-start: 28px;
    margin-block-end: 28px;
  }
`;

export const SOrigin = styled.p`
  font-weight: bold;
  font-size: 2rem;
  line-height: 91.02%;
  color: var(--color-black);
`;

export const STranslation = styled.p`
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--color-black);
  opacity: 0.5;
`;

export const SProgressBar = styled.div`
  width: 100%;
  position: absolute;
  bottom: 16px;
  display: flex;
  justify-content: center;

  ${customMediaQuery('tablet')} {
    bottom: 22px;
  }
`;

export const SWordListContainer = styled.div`
  -webkit-mask-image: -webkit-gradient(
    linear,
    left bottom,
    left 80%,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 1))
  );
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60px);
`;

export const SNotice = styled.p`
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: var(--color-light-white);
  opacity: 0.8;
`;

export const SWordList = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  max-width: 344px;
  margin: 0 auto;
  padding: 50px 0;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left 20%,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 1))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60px);

  ${customMediaQuery('tablet')} {
    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(200, 200, 200, 1);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--color-white);
      -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
    }
  }
`;

export const SWordItem = styled.div`
  display: flex;
  align-items: stretch;
  margin-block-end: 10px;
  padding: 6px;
  background: /* gradient can be an image */ linear-gradient(
      to left,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    left bottom no-repeat;
  background-size: 100% 1px;
  position: relative;
`;

export const SIconContainer = styled.div`
  background: var(--color-white);
  opacity: 0.9;
  border-radius: 10px;
  padding: 8px;
  margin-inline-end: 16px;
  width: 56px;
  height: 58px;
`;

export const SWordOrigin = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: var(--color-white);
  text-align: start;
`;

export const SWordTranslation = styled.p`
  font-size: 1rem;
  line-height: 1.1875rem;
  color: var(--color-white);
  opacity: 0.7;
  text-align: start;
`;

export const SWordAudio = styled.div`
  margin-inline-start: auto;
  margin-inline-end: 6px;
  display: flex;
  align-items: center;
`;

export const STranslatedByLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: rgba(255, 255, 255, 0.4);
  text-transform: lowercase;

  & > div {
    margin-inline-start: 4px;
    opacity: 0.8;
  }
`;
