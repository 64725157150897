import { tAnswer } from '@adeptlms/lingu-students-react-shared';
import { useCallback, useRef } from 'react';

export default function usePlayAnswers<T extends tAnswer>() {
  const answers = useRef<T>([] as unknown as T);

  function _answersFn(): T;
  function _answersFn(newAnswers: T): undefined;
  function _answersFn(newAnswers?: T): T | undefined {
    if (newAnswers) {
      answers.current = newAnswers;
      return undefined;
    } else {
      return answers.current;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const answersFn = useCallback(_answersFn, []);

  return answersFn;
}
