import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IMinimalPairsTask } from '@adeptlms/lingu-students-react-shared';
import DashedPagination from 'students/views/shared/components/DashedPagination';
import MinimalPairsItem, { ISelectAnswerPayload } from './MinimalPairsItem';

interface IProps {
  task: IMinimalPairsTask;
  onComplete: () => void;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  onSkip: (itemId: number) => void;
}

const PlayMinimalPairsMainScreen: React.FC<IProps> = ({
  task,
  onComplete,
  onSelectAnswer,
  onSkip
}) => {
  const { items } = task;
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentItem = useMemo(() => items[currentIndex], [currentIndex, items]);

  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleNext = useCallback(() => {
    if (currentIndex + 1 >= items.length) {
      handleComplete();
    } else {
      setCurrentIndex((index) => index + 1);
    }
  }, [handleComplete, items.length, currentIndex]);

  useEffect(() => {
    if (!currentItem) handleNext();
  }, [currentItem, handleNext]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    onSelectAnswer(payload);
    setTimeout(() => handleNext(), 1000);
  };

  const handleItemNext = () => {
    onSkip(currentItem.id);

    handleNext();
  };

  const handleItemPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
  };

  if (!currentItem) return null;

  return (
    <>
      <MinimalPairsItem onSelectAnswer={handleSelectAnswer} item={currentItem} />

      <DashedPagination
        itemsNumber={items.length}
        currentIndex={currentIndex}
        taskId={task.id}
        onNextItem={handleItemNext}
        onPrevItem={handleItemPrev}
      />
    </>
  );
};

export default PlayMinimalPairsMainScreen;
