import { useCallback, useEffect, useRef } from 'react';
import { useHintVisibility } from '../../common/helpers';

const IDLE_MATCH_TIMEOUT = 10000;

export const useMatchWordHint = (): [boolean, () => void, (needed: boolean) => void] => {
  const needsHintRef = useRef(true);

  const [hintVisible, updateLastInteractionTime] = useHintVisibility(
    needsHintRef.current,
    IDLE_MATCH_TIMEOUT
  );

  useEffect(() => {
    if (hintVisible) needsHintRef.current = false;
  }, [hintVisible]);

  const markInteracted = useCallback(() => {
    updateLastInteractionTime(IDLE_MATCH_TIMEOUT);
  }, [updateLastInteractionTime]);

  const setHintNeeded = (needed: boolean) => {
    needsHintRef.current = needed;
  };

  return [hintVisible, markInteracted, setHintNeeded];
};
