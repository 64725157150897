import styled from 'styled-components';
import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { customMediaQuery } from 'students/views/shared/styled';

export const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 55px;
  height: 55px;

  ${customMediaQuery('tablet')} {
    width: 58px;
    height: 58px;
  }
`;
