import { useMemo } from 'react';
import { IItemBlock } from '../types';
import { ANSWER_BLOCK_ADDITIONAL_HEIGHT } from '../utils';

export const SCROLLABLE_CONTAINER_CLASSNAME = 'arrange-items-scrollable-container';
interface IReturnedValue {
  blockType: 'audio' | 'picture' | 'word';
  position: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

const FINISHED_ITEM_PADDING = 5;

function useAnswerItem({ itemBlock }: { itemBlock: IItemBlock }): IReturnedValue {
  const blockType = useMemo(() => {
    if (itemBlock.option.audio) {
      return 'audio';
    } else if (itemBlock.option.image) {
      return 'picture';
    }

    return 'word';
  }, [itemBlock]);

  const position = useMemo(() => {
    const scrollableContainerRef = document.querySelector(
      `.${SCROLLABLE_CONTAINER_CLASSNAME}`
    );
    const scrollableContainerScrollX = scrollableContainerRef?.scrollLeft || 0;
    const { x: scrollableContainerX } = scrollableContainerRef
      ? scrollableContainerRef.getBoundingClientRect()
      : { x: 0 };
    const destinationRect = itemBlock?.destinationRef?.node.getBoundingClientRect();

    return {
      x:
        (destinationRect?.x || 0) -
        scrollableContainerX +
        FINISHED_ITEM_PADDING +
        scrollableContainerScrollX,
      y: FINISHED_ITEM_PADDING,
      width: (destinationRect?.width || 0) - FINISHED_ITEM_PADDING * 2,
      height:
        (destinationRect?.height || 0) -
        FINISHED_ITEM_PADDING * 2 -
        ANSWER_BLOCK_ADDITIONAL_HEIGHT
    };
  }, [itemBlock]);

  return {
    blockType,
    position
  };
}

export default useAnswerItem;
