import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import {
  IArrangeItemsTaskSession,
  IPlayArrangeItemsTask
} from '@adeptlms/lingu-students-react-shared';
import { t } from 'i18n';

import PlayArrangeItemsMainScreen from './PlayArrangeItemsMainScreen';
import usePlayArrangeItemsTask from './hooks/usePlayArrangeItemsTask';
import { getBackgroundGradientClassname } from './utils';
import bgImage from './assets/arrangeItemsBg.svg';
import { IPlayTaskProps, tPlayTaskComponentType } from '../types';
import PlayTaskScreenSwitcher from '../../common/screens/PlayTaskScreenSwitcher';
import Streak from '../../common/components/Streak';

const TASK_SOLID_COLORS = {
  green: '#1c9e67',
  orange: '#e76d47',
  pink: '#ea5176',
  red: '#f7513f',
  blue: '#5197ea'
};

const PlayArrangeItemsTask: tPlayTaskComponentType<
  IPlayTaskProps<IPlayArrangeItemsTask, IArrangeItemsTaskSession>
> = ({ task, taskSession, isCompleting, onFinish, onNext, onAnswer, className }) => {
  const {
    items,
    instruction,
    streakNumber,
    streakPercent,
    currentItemIndex,
    shadowAnimation,
    submitTask,
    handleFinishTask,
    handleCompleteTask,
    handleSelectAnswer,
    handleSkip
  } = usePlayArrangeItemsTask({ task, taskSession, onNext, onAnswer, onFinish });
  const colorName = getBackgroundGradientClassname(currentItemIndex);
  const taskColor = TASK_SOLID_COLORS[colorName] || '#1c9e67';
  return (
    <>
      <Streak streakNumber={streakNumber} streakPercent={streakPercent} />

      <SOwnBackground className={colorName} />

      <SShade
        as={motion.div}
        key={`${currentItemIndex}-shadow`}
        initial={{ y: '-100%' }}
        animate={shadowAnimation}
        className={getBackgroundGradientClassname(currentItemIndex + 1)}
      />

      <SPlayTaskScreenSwitcher
        isCompleting={isCompleting}
        onExitFinishScreen={handleFinishTask}
        className={className}
        startScreenHeading={
          instruction ||
          t('frontend.lesson_task.tasks.play.arrange_items.start_screen.heading')
        }
        itemsExist={!!items.length}
        taskStatus={taskSession?.status}
        completeTask={submitTask}
        taskColor={taskColor}
      >
        {(goToFinishScreen) => (
          <PlayArrangeItemsMainScreen
            task={task}
            onSelectAnswer={handleSelectAnswer}
            onComplete={handleCompleteTask(goToFinishScreen)}
            onSkip={handleSkip}
          />
        )}
      </SPlayTaskScreenSwitcher>
    </>
  );
};

export default PlayArrangeItemsTask;

const SOwnBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.green {
    background-image: linear-gradient(180deg, #6ee49e 0%, #1c9e67 100%);
  }

  &.orange {
    background-image: linear-gradient(180deg, #ffa782 0%, #e76d47 100%);
  }

  &.pink {
    background-image: linear-gradient(180deg, #ff99b8 0%, #ea5176 100%);
  }

  &.red {
    background-image: linear-gradient(180deg, #fa9379 0%, #f7513f 100%);
  }

  &.blue {
    background-image: linear-gradient(180deg, #88c6ff 0%, #5197ea 100%);
  }

  @media (min-width: ${({ theme }) => theme.linguBptLg}) {
    &.green {
      background-image: url(${bgImage}), linear-gradient(180deg, #6ee49e 0%, #1c9e67 100%);
    }

    &.orange {
      background-image: url(${bgImage}), linear-gradient(180deg, #ffa782 0%, #e76d47 100%);
    }

    &.pink {
      background-image: url(${bgImage}), linear-gradient(180deg, #ff99b8 0%, #ea5176 100%);
    }

    &.red {
      background-image: url(${bgImage}), linear-gradient(180deg, #fa9379 0%, #f7513f 100%);
    }

    &.blue {
      background-image: url(${bgImage}), linear-gradient(180deg, #88c6ff 0%, #5197ea 100%);
    }
  }
`;

const SPlayTaskScreenSwitcher = styled(PlayTaskScreenSwitcher)`
  z-index: 1;
`;

const SShade = styled.div`
  position: fixed;
  width: 100%;
  height: 110%;
  top: 0;
  left: 0;
  clip-path: ellipse(150% 100% at 50% 0%);
  z-index: 2;

  &.green {
    background: linear-gradient(180deg, #6ee49e 0%, #1c9e67 100%);
  }

  &.orange {
    background: linear-gradient(180deg, #ffa782 0%, #e76d47 100%);
  }

  &.pink {
    background: linear-gradient(180deg, #ff99b8 0%, #ea5176 100%);
  }

  &.red {
    background: linear-gradient(180deg, #fa9379 0%, #f7513f 100%);
  }

  &.blue {
    background: linear-gradient(180deg, #88c6ff 0%, #5197ea 100%);
  }
`;
