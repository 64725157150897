import React, { FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  ILeaderboardRow,
  tLeaderboardTable
} from '@adeptlms/lingu-students-react-shared';

import LeaderboardRow from './LeaderboardRow';
import { useFadingOnScroll } from '../../hooks';

interface ILeaderboardTable {
  leaderboard: tLeaderboardTable;
  foundUserIndex: number;
  isHiddenProfile: boolean;
  className?: string;
}

const LeaderboardTable: FC<ILeaderboardTable> = ({
  leaderboard,
  foundUserIndex,
  isHiddenProfile,
  className
}) => {
  useFadingOnScroll(
    () => document.getElementById('LeaderboardTable'),
    () => document.querySelectorAll<HTMLElement>('.LeaderboardRow'),
    true
  );

  const renderLeaderboardUsers = () => {
    const currentFoundStudentId = leaderboard[foundUserIndex]?.student.id;

    if (foundUserIndex < 5) {
      return (
        <>
          {leaderboard.slice(0, 10).map((row: ILeaderboardRow) => (
            <LeaderboardRow
              row={row}
              isActive={currentFoundStudentId === row.student.id}
              isHiddenProfile={isHiddenProfile}
              key={row.student.id}
              className="LeaderboardUser"
            />
          ))}
        </>
      );
    }

    return (
      <>
        {leaderboard.slice(0, 3).map((row: ILeaderboardRow) => (
          <LeaderboardRow
            row={row}
            isActive={currentFoundStudentId === row.student.id}
            isHiddenProfile={isHiddenProfile}
            key={row.student.id}
            className="LeaderboardUser"
          />
        ))}

        <SDivider />

        {leaderboard
          .slice(foundUserIndex - 1, foundUserIndex + 6)
          .map((row: ILeaderboardRow) => (
            <LeaderboardRow
              row={row}
              isActive={currentFoundStudentId === row.student.id}
              isHiddenProfile={isHiddenProfile}
              key={row.student.id}
              className="LeaderboardUser"
            />
          ))}
      </>
    );
  };

  return (
    <SWrapper
      className={cn(className)}
      id="LeaderboardTable"
      data-testid="students/components/Leaderboard/LeaderboardTable"
    >
      {renderLeaderboardUsers()}
    </SWrapper>
  );
};

export default LeaderboardTable;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  position: relative;

  .LeaderboardRow {
    opacity: 1;
  }
`;

const SDivider = styled.div`
  width: calc(100% - 32px);
  margin: 16px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;
