import Picture from 'students/views/shared/components/Picture';
import styled from 'styled-components';

const SSuccessPoint = styled(Picture)<{
  top: number;
  left: number;
  width: number;
  height: number;
}>`
  position: absolute;
  height: ${({ height }) => `${height}px` || '0'};
  width: ${({ width }) => `${width}` || '0'}px;
  top: ${({ top, height }) => `calc(${top}% - ${height / 2}px)` || '0'};
  left: ${({ left, width }) => `calc(${left}% - ${width / 2}px)` || '0'};
`;

export default SSuccessPoint;
