import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

const SItemWrapper = styled.div`
  margin-block-end: 1.75rem;

  ${customMediaQuery('desktop')} {
    margin-block-end: 2rem;
  }

  &:last-child {
    margin-block-end: 0;
  }
`;

export default SItemWrapper;
