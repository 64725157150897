import { useEffect } from 'react';

function initBeforeUnload(showExitPrompt: boolean): void {
  // eslint-disable-next-line consistent-return
  window.onbeforeunload = function (event: BeforeUnloadEvent): string | undefined {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();

      if (e) {
        e.returnValue = '';
      }

      return '';
    }
  };
}

function useExitPrompt(showExitPrompt: boolean, cb?: () => void): void {
  window.onload = function () {
    initBeforeUnload(showExitPrompt);
    cb && cb();
  };

  useEffect(() => {
    initBeforeUnload(showExitPrompt);
  }, [showExitPrompt]);
}

export default useExitPrompt;
