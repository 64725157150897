import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { PADDINGABLE_FOR_MOBILE_STATUS_BAR_CLASS } from 'students/constants';
import { SPage, SPageContainer } from 'students/views/shared/styled';
import styled, { StyledComponent } from 'styled-components';

export const PLAY_PAGE_DEFAULT_COLOR = '#4f398e';

export interface IPlayPage {
  className?: string;
  backgroundComponent?: StyledComponent<'div', any, any, never>;
}

const PlayPage: FC<PropsWithChildren<IPlayPage>> = ({
  children,
  className,
  backgroundComponent
}) => {
  const BackgroundComponent = backgroundComponent || defaultDiv;

  return (
    <SPage className={cn(className)}>
      <BackgroundComponent
        className={cn(
          'ln-flex ln-flex-col ln-grow',
          PADDINGABLE_FOR_MOBILE_STATUS_BAR_CLASS
        )}
      >
        <SPageContainer>{children}</SPageContainer>
      </BackgroundComponent>
    </SPage>
  );
};

const defaultDiv = styled.div`
  background-color: ${PLAY_PAGE_DEFAULT_COLOR};
`;

export default PlayPage;
