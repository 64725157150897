import React from 'react';
import {
  IArrangeTextTask,
  IArrangeTextTaskSession
} from '@adeptlms/lingu-students-react-shared';
import { ICheckTaskProps } from '../types';
import { BaseCheckTaskBody, CheckNavFooter, SCheckTaskWrapper } from '../../components';
import { ArrangeTextItems, SItem, SDescription } from './components';
import { useArrangeTextTask } from './hooks';
import { isLessonCompleted } from '../../helpers';

type tArrangeTextTaskProps = ICheckTaskProps<IArrangeTextTask, IArrangeTextTaskSession>;

const CheckArrangeTextTask: React.FC<tArrangeTextTaskProps> = ({
  task,
  isCompleting,
  taskSession,
  onNext
}) => {
  const { taskItems, handleDragEnd, handleNext, handleSkip } = useArrangeTextTask(
    task,
    onNext,
    taskSession
  );

  return (
    <>
      <SCheckTaskWrapper>
        <BaseCheckTaskBody task={task}>
          {taskItems.map((item, index) => (
            <SItem key={item.id}>
              <SDescription>{item.description}</SDescription>
              <ArrangeTextItems
                itemIndex={index}
                onDragEnd={handleDragEnd}
                options={item.options}
              />
            </SItem>
          ))}
        </BaseCheckTaskBody>
      </SCheckTaskWrapper>
      <CheckNavFooter
        isCompleting={isCompleting}
        onNext={handleNext}
        onSkip={handleSkip}
        isLessonCompleted={isLessonCompleted(taskSession)}
      />
    </>
  );
};

export default CheckArrangeTextTask;
