import { IInlineDropdownItem } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { FC, Fragment } from 'react';
import WordSelector from 'students/views/shared/components/WordSelector';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

export interface ISelectAnswerPayload {
  value: string;
  wordIndex: number;
  itemId: number;
}

interface IProps {
  item: IInlineDropdownItem;
  onSelectAnswer: ({ value, wordIndex, itemId }: ISelectAnswerPayload) => void;
  value?: string[];
  className?: string;
}

const InlineDropdownItem: FC<IProps> = ({ item, onSelectAnswer, className, value }) => {
  const { statement, answers: options } = item;
  const blocks = statement.split('**');

  const handleSelectAnswer = ({ index, value }: { index: number; value: string }) => {
    onSelectAnswer({ value, wordIndex: index, itemId: item.id });
  };

  return (
    <SInlineDropdownItem className={cn(className)} dir="ltr">
      {blocks.map((block: string, index: number) => (
        <Fragment key={index}>
          {block}

          {index < options.length && (
            <WordSelector
              key={index}
              index={index}
              onSelectAnswer={handleSelectAnswer}
              options={options[index]}
              statusStyles={BUTTON_STATUS_STYLES}
              value={value?.[index]}
              dropdownArrow
            />
          )}
        </Fragment>
      ))}
    </SInlineDropdownItem>
  );
};

export default InlineDropdownItem;

const SInlineDropdownItem = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: 16px 0 0;

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    font-size: 1.375rem;
    line-height: 3.125rem;
    max-width: 670px;
  }
`;

const BUTTON_STATUS_STYLES: {
  init: FlattenSimpleInterpolation;
  selected: FlattenSimpleInterpolation;
  opened: FlattenSimpleInterpolation;
} = {
  init: css`
    color: #c9c9df;
    background: linear-gradient(180deg, #e6e6f0 0%, #f5f5fa 100%);
    box-shadow: 0 2px 0 #d6d6f1, inset 0 2px 0 #f5f5f9;
    text-align: start;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #035f94;
      background: linear-gradient(180deg, #e6e6f0 0%, #f5f5fa 100%);
      box-shadow: none;
    }

    &:focus:before {
      top: -1px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border: 2px solid #035f94;
    }
  `,
  selected: css`
    background: #e6e6f0;
    color: var(--color-black);
    text-align: start;
    border: 1px solid #e6e6f0;

    &:focus:before {
      top: -1px;
      left: -3px;
      right: -4px;
      bottom: -3px;
      border: 2px solid #035f94;
    }
  `,
  opened: css`
    background: linear-gradient(180deg, #e6e6f0 0%, #f5f5fa 100%), #e6e6f0;
    border: 2px solid var(--color-blue-800);
    margin: 0;
    text-align: start;
    color: transparent;

    &:focus:before {
      top: -1px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border: 2px solid #035f94;
    }
  `
};
