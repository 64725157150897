import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { StartButton } from 'students/views/shared/components/Buttons';
import { customMediaQuery } from 'students/views/shared/styled';
import bubbleAnimationManager from 'students/views/shared/bundles/bubbleAnimationManager';
import { useAnimateScenarios } from 'students/views/shared/hooks';
import { startButtonScenarios, startScreenHeadingScenarios } from './animations';

interface IProps {
  heading: string;
  onStart: () => void;
}

const GameStartScreen: React.FC<IProps> = ({ heading, onStart }) => {
  useLayoutEffect(() => {
    if (bubbleAnimationManager.showBubbleAnimation) {
      document.body.style.visibility = 'hidden';
    }
  }, []);

  const [buttonControls, animateButton] = useAnimateScenarios(startButtonScenarios);
  const [headingControls, animateHeading] = useAnimateScenarios(
    startScreenHeadingScenarios
  );
  const buttonRef = useRef<HTMLDivElement>(null);
  const [startButtonPositioned, setStartButtonPositioned] = useState(false);

  useEffect(() => {
    if (bubbleAnimationManager.showBubbleAnimation) {
      if (buttonRef.current) {
        const viewportOffset = buttonRef.current.getBoundingClientRect();
        bubbleAnimationManager.startButtonPosition = {
          left: viewportOffset.left,
          top: viewportOffset.top,
          width: buttonRef.current.offsetWidth,
          height: buttonRef.current.offsetHeight
        };
        document.body.style.visibility = 'visible';
        setStartButtonPositioned(true);
      }
    } else {
      animateButton(['appear']);
    }
  }, [animateButton, buttonRef]);

  const handleStart = async () => {
    await Promise.all([animateButton(['disappear']), animateHeading(['disappear'])]);
    onStart();
  };

  return (
    <SContainer>
      <SHeading animate={headingControls}>{heading}</SHeading>
      <motion.div
        ref={buttonRef}
        animate={buttonControls}
        initial={{
          width: bubbleAnimationManager.showBubbleAnimation ? 100 : 210,
          height: bubbleAnimationManager.showBubbleAnimation ? 100 : 210
        }}
      >
        <SStartButton onClick={handleStart} size="100%" />
      </motion.div>
      {startButtonPositioned && bubbleAnimationManager.shrinkingBubbleChanger}
    </SContainer>
  );
};

export default GameStartScreen;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  height: 100vh;
  width: 100%;
  padding: 2rem;
`;

const SHeading = styled(motion.h2)`
  color: var(--color-light-white);
  margin: 0 0 50px;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.625rem;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 2.375rem;
    line-height: 3.125rem;
    margin: 0 0 90px;
  }
`;

const SStartButton = styled(StartButton)`
  font-size: 1.125rem;
  margin: 0;

  ${customMediaQuery('tablet')} {
    font-size: 1.375rem;
  }
`;
