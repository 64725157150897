import { lessonSelectors } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { t } from 'i18n';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { useTypedSelector } from 'students/stores/_utils';
import { useMobileBreakPoint } from '../../hooks';
import CircleButton from '../CircleButton';
import { AngleArrowRightIcon, DoubleAngleArrowLeftIcon } from '../icons';

interface IProps {
  className?: string;
  currentIndex: number;
  itemsNumber: number;
  taskId: number;
  onNextItem?: () => void;
  onPrevItem?: () => void;
  isDisabled?: boolean;
}

const DESKTOP_MAX_DASH_ITEMS = 19;
const MOBILE_MAX_DASH_ITEMS = 10;

const DashedPagination: React.FC<IProps> = ({
  className,
  currentIndex,
  itemsNumber,
  taskId,
  onNextItem,
  onPrevItem,
  isDisabled
}) => {
  const isMobile = useMobileBreakPoint();
  const lessonSession = useTypedSelector(lessonSelectors.selectLessonSession);
  const history = useHistory();
  const { lessonId } = useParams<{ lessonId: string }>();

  const { prevTask, nextTask } = useMemo(() => {
    const allTasks = [
      ...(lessonSession?.summary.teach?.tasks ?? []),
      ...(lessonSession?.summary.engage?.tasks ?? []),
      ...(lessonSession?.summary.test?.tasks ?? [])
    ];

    const currentTaskIndex = allTasks.findIndex((task) => task.id === taskId);

    if (currentTaskIndex !== -1) {
      return {
        prevTask: currentTaskIndex === 0 ? null : allTasks[currentTaskIndex - 1],
        nextTask:
          currentTaskIndex === allTasks.length - 1 ? null : allTasks[currentTaskIndex + 1]
      };
    }

    return {
      prevTask: null,
      nextTask: null
    };
  }, [lessonSession, taskId]);

  const renderProgress = useCallback(() => {
    return itemsNumber > (isMobile ? MOBILE_MAX_DASH_ITEMS : DESKTOP_MAX_DASH_ITEMS) ? (
      <SProgressBarWrapper className={cn(className)}>
        <SProgressBar $width={((currentIndex + 1) / itemsNumber) * 100} />
      </SProgressBarWrapper>
    ) : (
      <SDashedPagination className={cn(className)}>
        {Array(itemsNumber)
          .fill(0)
          .map((_, index) => (
            <SDash key={index} className={cn({ passed: currentIndex >= index })} />
          ))}
      </SDashedPagination>
    );
  }, [isMobile, itemsNumber, currentIndex, className]);

  const onPrevTask = useCallback(() => {
    if (lessonId && prevTask) {
      history.replace(`/lessons/${lessonId}/tasks/${prevTask.id}`);
    }
  }, [history, lessonId, prevTask]);

  const onNextTask = useCallback(() => {
    if (lessonId && nextTask) {
      history.replace(`/lessons/${lessonId}/tasks/${nextTask.id}`);
    }
  }, [history, lessonId, nextTask]);

  return (
    <SWrapper className={cn(className, 'dashed-pagination')}>
      <SSkipTaskButton
        disabled={!prevTask || isDisabled}
        onClick={onPrevTask}
        size="32px"
        title={t('frontend.lesson_task.check.navigation_prev_task')}
        bgColor="rgba(0, 0, 0, 0.3);"
        shadowColor="none"
        color="#fff"
      >
        <DoubleAngleArrowLeftIcon
          className="dir-auto"
          color="#FFFFFF"
          height="16px"
          width="16px"
        />
      </SSkipTaskButton>

      <SSkipItemButton
        disabled={isDisabled}
        onClick={onPrevItem}
        className="mirrored"
        size="32px"
        title={t('frontend.lesson_task.check.navigation_prev_item')}
        bgColor="transparent"
        shadowColor="none"
        color="#fff"
      >
        <AngleArrowRightIcon
          className="dir-auto"
          color="#FFFFFF"
          height="16px"
          width="16px"
        />
      </SSkipItemButton>

      {renderProgress()}

      <SSkipItemButton
        disabled={isDisabled}
        onClick={onNextItem}
        size="32px"
        title={t('frontend.lesson_task.check.navigation_skip_item')}
        bgColor="transparent"
        shadowColor="none"
        color="#fff"
      >
        <AngleArrowRightIcon
          className="dir-auto"
          color="#FFFFFF"
          height="16px"
          width="16px"
        />
      </SSkipItemButton>

      <SSkipTaskButton
        disabled={!nextTask || isDisabled}
        onClick={onNextTask}
        className="mirrored"
        size="32px"
        title={t('frontend.lesson_task.check.navigation_skip_task')}
        bgColor="rgba(0, 0, 0, 0.3);"
        shadowColor="none"
        color="#fff"
      >
        <DoubleAngleArrowLeftIcon
          className="dir-auto"
          color="#FFFFFF"
          height="16px"
          width="16px"
        />
      </SSkipTaskButton>
    </SWrapper>
  );
};

export default DashedPagination;

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 100%;
  max-width: 100%;
  padding: 2px;

  &.contrast {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 50px;
  }

  ${customMediaQuery('tablet')} {
    max-width: ${({ theme }) => theme.linguBptMd};

    &.contrast {
      background: transparent;
    }
  }
`;

const SDashedPagination = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex: 1;

  ${customMediaQuery('tablet')} {
    flex: none;
  }
`;

const SDash = styled.div`
  width: 0.75rem;
  height: 0.25rem;
  border-radius: 1rem;
  background: #00000030;
  margin: 0.25rem;

  &.passed {
    background: var(--color-white);
  }

  ${customMediaQuery('tablet')} {
    width: 1.375rem;
  }
`;

const SSkipItemButton = styled(CircleButton)`
  margin-block: 0;
  margin-inline: 6px;

  &.mirrored {
    transform: rotate(180deg);
  }

  ${customMediaQuery('tablet')} {
    margin-block: 0;
    margin-inline: 12px;
  }
`;

const SSkipTaskButton = styled(CircleButton)`
  margin: 0;

  &.mirrored {
    transform: rotate(180deg);
  }
`;

const SProgressBarWrapper = styled.div`
  width: calc(100% - 128px);
  background: #00000030;
  border-radius: 1rem;
  height: 0.25rem;
`;

const SProgressBar = styled.div<{ $width: number }>`
  background: var(--color-white);
  border-radius: 1rem;
  height: 0.25rem;
  transition: width 0.4s;
  width: ${({ $width }) => `${$width}%`};
`;
