import { IDictationTask } from '@adeptlms/lingu-students-react-shared';
import React, { FC, useEffect, useState } from 'react';
import DashedPagination from 'students/views/shared/components/DashedPagination';

import DictationTaskItem, { ISelectAnswerPayload } from './components/DictationTaskItem';
import ErrorMessage from './components/ErrorMessage';

enum Screen {
  Item = 'item',
  Error = 'error'
}

interface IProps {
  task: IDictationTask;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  onComplete: () => void;
  onSkip: (itemId: number) => void;
}

const initialScreenErrorPayload: ISelectAnswerPayload = {
  itemId: -1,
  mistakesCount: 0,
  answer: ''
};

const PlayDictationMainScreen: FC<IProps> = ({
  task,
  onSelectAnswer,
  onComplete,
  onSkip
}) => {
  const { items, caseSensitive, checkPunctuation } = task;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentScreen, setCurrentScreen] = useState<Screen>(Screen.Item);
  const [screenErrorPayload, setScreenErrorPayload] = useState<ISelectAnswerPayload>(
    initialScreenErrorPayload
  );
  const currentItem = items[currentIndex];

  useEffect(() => {
    setScreenErrorPayload(initialScreenErrorPayload);
  }, [currentIndex]);

  const handleComplete = () => {
    onComplete();
  };

  const handleNext = () => {
    if (currentIndex + 1 === items.length) {
      handleComplete();
    } else {
      setCurrentIndex((currentIndex) => currentIndex + 1);
      setCurrentScreen(Screen.Item);
    }
  };

  const selectAnswer = (payload: ISelectAnswerPayload) => {
    onSelectAnswer(payload);
    handleNext();
  };

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    selectAnswer(payload);
  };

  const goToWrongAnswerScreen = (payload: ISelectAnswerPayload) => {
    setCurrentScreen(Screen.Error);
    setScreenErrorPayload(payload);
  };

  const handleItemNext = () => {
    onSkip(currentItem.id);

    handleNext();
  };

  const handleItemPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
      setCurrentScreen(Screen.Item);
    }
  };

  const renderScreen = (screen: Screen): JSX.Element | null => {
    switch (screen) {
      case Screen.Item:
        return (
          <>
            <DictationTaskItem
              key={currentItem.id}
              item={currentItem}
              characters={task.characters}
              onSelectAnswer={handleSelectAnswer}
              goToWrongAnswerScreen={goToWrongAnswerScreen}
              showHint
              validation={{ caseSensitive, checkPunctuation }}
            />

            <DashedPagination
              itemsNumber={items.length}
              currentIndex={currentIndex}
              taskId={task.id}
              onNextItem={handleItemNext}
              onPrevItem={handleItemPrev}
            />
          </>
        );

      case Screen.Error:
        return (
          <ErrorMessage
            header={currentItem.sentence}
            subHeader={currentItem.description}
            onClickNext={() => selectAnswer(screenErrorPayload)}
          />
        );

      default:
        return null;
    }
  };

  return renderScreen(currentScreen);
};

export default PlayDictationMainScreen;
