import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { useBreakPoint } from 'students/views/shared/hooks';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IHintTooltip {
  show: boolean;
  correctPart: string;
  hintPart: string;
  className?: string;
}

const HintTooltip: FC<PropsWithChildren<IHintTooltip>> = ({
  show,
  correctPart,
  hintPart,
  children,
  className
}) => {
  const isMobile = useBreakPoint('tablet', true);

  return (
    <div className="ln-relative ln-w-full">
      <div className="ln-w-full">{children}</div>

      {show ? (
        <SWrapper
          data-testid="dictation_item-hint"
          className={cn(className, isMobile ? 'onTop' : 'onBottom', 'ln-animate-bounce')}
        >
          <span>{correctPart}</span>
          {hintPart ? <SHintWord>{hintPart}</SHintWord> : null}
        </SWrapper>
      ) : null}
    </div>
  );
};

export default HintTooltip;

const SWrapper = styled.p`
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-gray-700);
  padding: 1rem;
  min-height: 46px;
  min-width: 125px;
  border-radius: 8px;
  margin-block-end: 18px;
  position: absolute;
  left: calc(50% - 60px);
  z-index: 10;

  &.onTop {
    top: -80px;
  }

  &.onBottom {
    bottom: -120px;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    background: transparent;
    transform: translate(-50%, 50%) rotate(-45deg);
    position: absolute;
    bottom: 0;
    left: 50%;
    border-left: 8px solid rgba(255, 255, 255, 0.88);
    border-bottom: 8px solid rgba(255, 255, 255, 0.88);
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
  }

  ${customMediaQuery('tablet')} {
    font-size: 1.5rem;
    min-height: 60px;
    min-width: 165px;
    margin-block-start: 16px;
    box-shadow: 0 5px 26px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    left: calc(50% - 80px);

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
      top: 0;
      border-left: 10px solid rgba(255, 255, 255, 0.88);
      border-top: 10px solid rgba(255, 255, 255, 0.88);
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
`;

const SHintWord = styled.span`
  color: var(--color-black);
`;
