import { useState, useRef, useCallback } from 'react';

export function useAnswerInput(): {
  inputHasMistakes: boolean;
  setInputHasMistakes: React.Dispatch<React.SetStateAction<boolean>>;
  currentAnswerInput: string;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  initInput: () => void;
  _handleInputChange: (value: string) => void;
  _handleClickOnCharacter: (value: string) => void;
} {
  const [currentAnswerInput, setCurrentAnswerInput] = useState<string>('');
  const [inputHasMistakes, setInputHasMistakes] = useState<boolean>(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const initInput = useCallback(() => {
    setCurrentAnswerInput('');
    inputRef.current && inputRef.current.focus();
  }, []);

  const _handleInputChange = (value: string) => {
    setCurrentAnswerInput(value);
    setInputHasMistakes(false);
  };

  const _handleClickOnCharacter = (character: string) => {
    const inputNode = inputRef.current;
    if (inputNode) {
      const position = inputNode.selectionStart;

      setCurrentAnswerInput((prevValue: string) => {
        if (prevValue.length === position) {
          return prevValue + character;
        } else {
          return [
            prevValue.slice(0, position),
            character,
            prevValue.slice(position)
          ].join('');
        }
      });
      inputNode.focus();
      setTimeout(() => {
        inputNode.setSelectionRange(position + 1, position + 1);
      }, 0);
    }
  };
  return {
    inputHasMistakes,
    setInputHasMistakes,
    currentAnswerInput,
    inputRef,
    initInput,
    _handleInputChange,
    _handleClickOnCharacter
  };
}
