import { FC } from 'react';
import {
  IArrangeTextTask,
  IArrangeTextTaskSession,
  ICheckFillInBlanksTask,
  tCheckTaskType,
  ICheckTrueFalseTask,
  IInlineDropdownTask,
  IMultipleChoiceTask,
  IMultipleChoiceTaskSession,
  IInlineDropdownTaskSession,
  ITrueFalseTaskSession,
  IFillInBlanksTaskSession,
  IFillInTableTask,
  IFillInTableTaskSession,
  IAssignmentTask,
  IAssignmentTaskSession
} from '@adeptlms/lingu-students-react-shared';

import CheckTrueFalseTask from './TrueFalseTask';
import CheckMultipleChoiceTask from './MultipleChoiceTask';
import CheckInlineDropdownTask from './InlineDropdownTask';
import CheckFillInBlanksTask from './FillInBlanksTask';
import CheckFillInTableTask from './FillInTableTask';
import CheckAssignmentTask from './AssignmentTask';
import CheckAssignmentAITask from './AssignmentAITask';
import CheckArrangeTextTask from './ArrangeTextTask';
import { ICheckTaskProps } from './types';

export type tCheckComponentType =
  | FC<ICheckTaskProps<IMultipleChoiceTask, IMultipleChoiceTaskSession>>
  | FC<ICheckTaskProps<IInlineDropdownTask, IInlineDropdownTaskSession>>
  | FC<ICheckTaskProps<ICheckTrueFalseTask, ITrueFalseTaskSession>>
  | FC<ICheckTaskProps<ICheckFillInBlanksTask, IFillInBlanksTaskSession>>
  | FC<ICheckTaskProps<IFillInTableTask, IFillInTableTaskSession>>
  | FC<ICheckTaskProps<IAssignmentTask, IAssignmentTaskSession>>
  | FC<ICheckTaskProps<IArrangeTextTask, IArrangeTextTaskSession>>;

export const checkTaskMap = new Map<tCheckTaskType['type'], tCheckComponentType>([
  ['Tasks::SelectText', CheckMultipleChoiceTask],
  ['Tasks::InlineDropdown', CheckInlineDropdownTask],
  ['Tasks::TrueFalse', CheckTrueFalseTask],
  ['Tasks::FillInBlanks', CheckFillInBlanksTask],
  ['Tasks::FillInTable', CheckFillInTableTask],
  ['Tasks::Essay', CheckAssignmentTask],
  ['Tasks::Audio', CheckAssignmentTask],
  ['Tasks::File', CheckAssignmentTask],
  ['Tasks::EssayAI', CheckAssignmentAITask],
  ['Tasks::ArrangeText', CheckArrangeTextTask]
]);
