import React, { FC } from 'react';
import { Prompt as RPrompt } from 'react-router-dom';
import { t } from 'i18n';

import { usePromptContext } from '../../hooks';

interface IPrompt {
  message?: string;
}

const Prompt: FC<IPrompt> = ({ message }) => {
  const { isDirty } = usePromptContext();

  return (
    <RPrompt
      message={message || t('frontend.lesson_page.prompt_message')}
      when={isDirty}
    />
  );
};

export default Prompt;
