import { useCallback } from 'react';
import { lessonTaskActions } from '@adeptlms/lingu-students-react-shared';
import { useTypedDispatch } from 'students/stores/_utils';

function useHeartBeatPing(): () => void {
  const dispatch = useTypedDispatch();
  const pingAction = useCallback(() => {
    dispatch(lessonTaskActions.heartBeat());
  }, [dispatch]);
  return pingAction;
}

export default useHeartBeatPing;
