import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import ButtonGeneral, {
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import hintBulb from '../assets/hint_bulb.png';

interface IHintButtons {
  onGetHint: () => void;
  onSkip: () => void;
  disableGetHintBtn?: boolean;
  className?: string;
}

const HintButtons: FC<IHintButtons> = ({
  onGetHint,
  onSkip,
  disableGetHintBtn,
  className
}) => {
  return (
    <SWrapper className={cn(className)}>
      <SButton variant="light" onClick={onGetHint} disabled={disableGetHintBtn ?? false}>
        <SIconWrapper>
          <UrlIcon url={hintBulb} height="20px" width="20px" />
        </SIconWrapper>
        <Translate str="frontend.lesson_task.tasks.play.dictation.get_a_hint" />
      </SButton>

      <SButton variant="light" onClick={onSkip}>
        <Translate str="frontend.lesson_task.tasks.play.dictation.i_do_not_know" />
        {' :('}
      </SButton>
    </SWrapper>
  );
};

export default HintButtons;

const SWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

const SButton = styled(ButtonGeneral)`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border-color: transparent;
  width: 100%;

  &:first-child {
    margin-inline-end: 8px;
  }

  ${styleInnerButton()} {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    padding: 0.25rem 0.5rem;
  }

  ${customMediaQuery('tablet')} {
    width: auto;
    border-radius: 12px;

    ${styleInnerButton()} {
      font-size: 1rem;
      padding: 1rem 2rem;
    }
  }
`;

const SIconWrapper = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 4px;
  margin-inline-end: 4px;
`;
