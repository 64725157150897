import { IArrangeItemsItemOption } from '@adeptlms/lingu-students-react-shared';
import { useCallback, useMemo, useRef, useState } from 'react';

import { IItemBlock, IWordBlockRef } from '../types';

function useDraggableBlocks(
  options: Array<IArrangeItemsItemOption>
): [(node: HTMLDivElement) => void, DOMRect | null, Array<IItemBlock>] {
  const [rect, setRect] = useState<DOMRect | null>(null);

  const viewportRef = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  const blocksRefMap = useRef<Map<string, IWordBlockRef>>(new Map());

  const setBlockRef = useCallback(
    (node: HTMLDivElement | null, optionIdString: string, index: number) => {
      if (node !== null) {
        blocksRefMap.current.set(optionIdString, {
          width: node.offsetWidth,
          height: node.offsetHeight,
          node,
          randX: generateItemPosition(index, 'x'),
          randY: generateItemPosition(index, 'y')
        });
      }
    },
    [blocksRefMap]
  );

  const itemBlocks: Array<IItemBlock> = useMemo(
    () =>
      [...options]
        .sort((u1: IArrangeItemsItemOption, u2: IArrangeItemsItemOption) =>
          u1.position > u2.position ? 1 : -1
        )
        .map((option: IArrangeItemsItemOption, index: number) => [
          {
            option,
            origin: true,
            sequenceIndex: option.position - 1 + index,
            optionId: `${option.id}_origin`,
            setRef: setBlockRef,
            originRef: blocksRefMap.current.get(`${option.id}_origin`)
          },
          {
            option,
            origin: false,
            sequenceIndex: option.position + index,
            optionId: `${option.id}_not_origin`,
            setRef: setBlockRef,
            originRef: blocksRefMap.current.get(`${option.id}_not_origin`),
            destinationRef: blocksRefMap.current.get(`${option.id}_origin`)
          }
        ])
        .flat(),
    /* eslint-disable-next-line */
    [options, rect, setBlockRef, blocksRefMap]
  );

  return [viewportRef, rect, itemBlocks];
}

export default useDraggableBlocks;

const X_AXIS_LEFT_BORDER = 30;
const X_AXIS_RIGHT_BORDER = 70;
const X_AXIS_DEVIATION = 30;
const Y_AXIS_BORDER = 16;
const Y_AXIS_DEVIATION = 8;

function generateItemPosition(index: number, axis: 'x' | 'y') {
  if (axis === 'x') {
    return index % 2 === 1
      ? X_AXIS_LEFT_BORDER + (Math.random() * X_AXIS_DEVIATION - X_AXIS_DEVIATION)
      : X_AXIS_RIGHT_BORDER + (Math.random() * X_AXIS_DEVIATION - X_AXIS_DEVIATION);
  }

  return index % 2 === 1
    ? Y_AXIS_BORDER + (Math.random() * Y_AXIS_DEVIATION * 2 - Y_AXIS_DEVIATION)
    : Y_AXIS_BORDER + (Math.random() * Y_AXIS_DEVIATION * 2 - Y_AXIS_DEVIATION);
}
