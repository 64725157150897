import { IDictationItem, IDictationTask } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { LayoutGroup, motion as m } from 'framer-motion';
import { FC } from 'react';
import HintButtons from 'students/views/pages/LessonTasks/TaskSubjects/Play/Tasks/DictationTask/components/HintButtons';
import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import CharacterButton from '../CharacterButton';
import HintTooltip from '../HintTooltip';
import WordInput from '../WordInput';
import { ISelectAnswerPayload } from './types';
import useDictationTaskItem, {
  RESULT_BLOCK_WRONG_CLASS_NAME
} from './useDictationTaskItem';

interface IDictationTaskItem {
  item: IDictationItem;
  characters: IDictationTask['characters'];
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  goToWrongAnswerScreen: (payload: ISelectAnswerPayload) => void;
  className?: string;
  showHint?: boolean;
  validation: {
    caseSensitive: boolean;
    checkPunctuation: boolean;
  };
}

const AUTOPLAY_DELAY = 2000;

const DictationTaskItem: FC<IDictationTaskItem> = ({
  item,
  characters,
  onSelectAnswer,
  goToWrongAnswerScreen,
  className,
  showHint,
  validation
}) => {
  const { answer, animations, handlers, hint, refs } = useDictationTaskItem({
    item,
    onSelectAnswer,
    goToWrongAnswerScreen,
    showHint,
    validation
  });

  return (
    <LayoutGroup>
      <SWordsItemWrapper key={item.id} className={cn(className)}>
        <STimeoutBlock
          initial={{ clipPath: 'ellipse(150% 100% at 50% -100%)' }}
          animate={animations.timeoutBlockControls}
          key={`${item.id}_timeout`}
        />

        <SHeader
          initial={{
            opacity: 0,
            y: 50
          }}
          animate={animations.headerAnimationControls}
        >
          <SSvgAudioPlayer
            src={item.audioURL}
            color="#834581"
            progressColor="#fbfcff"
            autoplay
            autoPlayDelay={AUTOPLAY_DELAY}
            onAudioStopped={handlers.handleStopAudio}
            onEnd={handlers.handleStopAudio}
          />
          {/* commented out until has real translation */}
          {/* <STranslationText>{item.description}</STranslationText> */}

          <SForm onSubmit={handlers.handleFormSubmit}>
            <HintTooltip
              show={hint.tooltipVisible}
              correctPart={hint.currentHint.correctPart}
              hintPart={hint.currentHint.hintWord}
            >
              <SWordInput
                ref={refs.inputRef}
                key={item?.id}
                animationRef={refs.inputAnimationRef}
                value={answer.currentAnswer}
                onChange={handlers.handleInputChange}
                onEnterKeyPress={handlers.handleEnterKeyPress}
                onBlur={handlers.handleBlur}
                isCorrect={!answer.inputHasMistakes}
                isFinished={answer.isItemFinished}
                highlightHint={hint.tooltipVisible}
              />
            </HintTooltip>
          </SForm>
        </SHeader>

        <m.div
          initial={{
            opacity: 0,
            y: 50,
            zIndex: 1
          }}
          animate={animations.contentAnimationControls}
        >
          {characters.length > 0 && (
            <SCharactersBlock>
              {characters.map((character: string) => (
                <CharacterButton
                  key={character}
                  onClick={handlers.handleClickOnCharacter}
                  character={character}
                />
              ))}
            </SCharactersBlock>
          )}
        </m.div>
      </SWordsItemWrapper>

      <SHintsButtonsWrapper
        initial={{
          opacity: 0,
          y: 30,
          width: '100%'
        }}
        animate={animations.hintButtonsAnimationControls}
        className={cn({ hidden: !hint.hintButtonsBlockVisible })}
      >
        <HintButtons
          onGetHint={handlers.handleOnGetHint}
          onSkip={handlers.handleWrongAnswer}
          disableGetHintBtn={hint.tooltipVisible || !answer.inputHasMistakes}
        />
      </SHintsButtonsWrapper>
    </LayoutGroup>
  );
};

export default DictationTaskItem;

const SWordsItemWrapper = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;

  ${customMediaQuery('tablet')} {
    width: 557px;
  }
`;

const SHeader = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  position: relative;
  margin: 0 0 16px;

  ${customMediaQuery('tablet')} {
    margin: 0 0 82px;
  }
`;

const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  margin-block-end: 80px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 70px;
  }
`;

const SForm = styled.form`
  width: 100%;
  display: flex;
`;

const SCharactersBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & > button {
    margin: 0 6px;
  }
`;

const SWordInput = styled(WordInput)`
  width: 100%;

  @keyframes wrongAnimation {
    20% {
      background: #ffffff35;
    }
    100% {
      background: #00000035;
    }
  }

  @keyframes wrongShake {
    33% {
      transform: translateX(-3px);
    }
    66% {
      transform: translateX(3px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &.${RESULT_BLOCK_WRONG_CLASS_NAME} {
    animation: 0.8s wrongAnimation, 0.4s wrongShake;
  }
`;

const STimeoutBlock = styled(m.div)`
  background: linear-gradient(180deg, rgba(184, 101, 181, 0) 0%, #6f348d 100%);
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const SHintsButtonsWrapper = styled(m.div)`
  z-index: 2;

  &.hidden {
    pointer-events: none;
  }
`;
