import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion as m } from 'framer-motion';
import cn from 'classnames';
import { tCardRef, IPhrase } from '@adeptlms/lingu-students-react-shared';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { SAudio, SCard, SCardContent, SOrigin, STranslation } from './styled';
import hintAnimation from './assets/hint-animation.json';
import HandHint from '../common/HandHint';
import { INITIAL_OPACITY, INITIAL_ROTATE, useSwipeCard, variants } from './hooks';

interface IProps {
  phrase: IPhrase;
  isActive: boolean;
  onSwipe: () => void;
  hasImage: string | null;
  round: number;
  canGoNext: boolean;
  hintVisible: boolean;
  shaking: boolean;
  isPrev: boolean;
  key?: number | string;
}

export const SwipeCard = React.forwardRef<tCardRef, IProps>(
  (
    {
      phrase,
      isActive,
      onSwipe,
      hasImage,
      round,
      canGoNext,
      hintVisible,
      shaking,
      isPrev
    },
    forwardedRef
  ) => {
    const { x, rotate, opacity, handleDragEnd, controls, isContentHidden } = useSwipeCard(
      {
        isActive,
        isPrev,
        forwardedRef,
        canGoNext,
        onSwipe
      }
    );

    return (
      <SCard
        as={m.div}
        style={{
          x,
          rotate: isActive ? rotate : INITIAL_ROTATE,
          opacity: isActive ? opacity : INITIAL_OPACITY
        }}
        drag={isActive}
        onDragEnd={handleDragEnd}
        initial={isPrev ? 'remove' : 'initial'}
        animate={controls}
        variants={variants}
        className={cn({ shaking: isActive ? shaking : false })}
        dragElastic={0.2}
      >
        <AnimatePresence>
          {!isContentHidden ? (
            <SCardContent
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.2 } }}
              exit={{ opacity: 0 }}
            >
              <div>
                <SPhraseIcon
                  imageUrl={phrase.imageURL}
                  colorRequired
                  animationUrl={phrase.animationURL}
                  iconClassName="flashcard-cardIcon"
                  text=""
                />
                {phrase.audioURL ? (
                  <SAudio className={cn({ hasImage })}>
                    <SSvgAudioPlayer
                      src={isActive ? phrase.audioURL : ''}
                      key={`${phrase.id}_${isActive}`}
                      autoplay
                      autoPlayDelay={round === 0 ? 300 : 0}
                      color="#0094c540"
                      progressColor="#0094c5"
                    />
                  </SAudio>
                ) : null}
                <div>
                  <SOrigin>{phrase.body}</SOrigin>
                  <STranslation>{phrase.wordTranslation}</STranslation>
                </div>
              </div>

              {/* <div>
                <FrequencyIndicator frequency={phrase.frequency} />
              </div> */}
            </SCardContent>
          ) : null}
        </AnimatePresence>

        {hintVisible && isActive ? (
          <HandHint top="50%" left="40%" animationData={hintAnimation} />
        ) : null}
      </SCard>
    );
  }
);

const SPhraseIcon = styled(PhraseIcon)`
  display: flex;
  justify-items: stretch;
  align-items: stretch;
  padding: 10px;

  .flashcard-cardIcon {
    min-width: 100px !important;
    min-height: 100px !important;
    height: 100%;

    ${customMediaQuery('tablet')} {
      min-width: 160px !important;
      min-height: 160px !important;
    }

    @media (max-height: 630px) {
      min-width: 120px !important;
      min-height: 120px !important;
    }
  }
`;

const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 52px;
  height: 52px;
`;
