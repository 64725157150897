import {
  IMetrics,
  IWordGamesTask,
  IWordGamesTaskSession,
  tWordGamesAnswer,
  useUncompletedGames,
  useWordGamesTaskSkipEmptyPhrasesEffect
} from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { useEffect } from 'react';
import { IGameAnswerPayload } from 'students/views/pages/LessonTasks/TaskSubjects/Play/Tasks/WordGamesTask/WordGames';
import styled from 'styled-components';
import { SGameTaskWrapper } from '../../common/components';
import { usePlayAnswers } from '../../common/hooks';
import { IPlayTaskProps, tPlayTaskComponentType } from '../types';
import WordGames from './WordGames';

const PlayWordGames: tPlayTaskComponentType<
  IPlayTaskProps<IWordGamesTask, IWordGamesTaskSession>
> = ({ taskSession, lessonPhrases, task, className, onAnswer, onSkip }) => {
  const { items, lessonId, id } = task;
  const answersFn = usePlayAnswers<tWordGamesAnswer>();

  const { uncompletedGames } = useUncompletedGames({ task, taskSession });

  const skipHandler = onSkip || (() => {});
  const phrases = lessonPhrases ?? null;
  useWordGamesTaskSkipEmptyPhrasesEffect({
    lessonPhrases: phrases,
    onSkip: skipHandler
  });

  useEffect(() => {
    if (!uncompletedGames.length) {
      onSkip?.();
    }
  }, [uncompletedGames, onSkip]);

  useEffect(() => {
    if (lessonPhrases && !lessonPhrases.length) onSkip?.();
  }, [lessonPhrases, onSkip]);

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      answersFn(taskSession.taskItemSessions);
    } else {
      answersFn(
        items.map((item, index) => {
          return {
            id: index,
            taskItemId: item.id,
            completed: false,
            mistakesCount: 0,
            timeSpent: 0,
            rounds: [],
            skipped: false,
            url: ''
          };
        })
      );
    }
  }, [taskSession, items, answersFn]);

  const handleCompleteTask = () => {
    onSkip?.();
  };

  const handleCompleteGame = (payload: IGameAnswerPayload) => {
    const { itemId, mistakesCount, timeSpent, rounds } = payload;
    const answerIndex = answersFn().findIndex((answer) => answer.taskItemId === itemId);
    if (answerIndex < 0) return;
    const newAnswers = structuredClone(answersFn());
    newAnswers[answerIndex].completed = true;
    newAnswers[answerIndex].mistakesCount = mistakesCount;
    newAnswers[answerIndex].timeSpent = timeSpent;
    newAnswers[answerIndex].rounds = rounds;
    answersFn(newAnswers);
  };

  const handleItemComplete = (metrics: IMetrics, itemId: number) => {
    // If we didn't find needed answer - fill with default one
    const currentAnswer = answersFn().find((answer) => answer.taskItemId === itemId) || {
      id: itemId,
      taskItemId: itemId,
      completed: false,
      mistakesCount: 0,
      timeSpent: 0,
      rounds: [],
      skipped: false,
      url: ''
    };

    onAnswer &&
      onAnswer({
        ...currentAnswer,
        completed: true,
        skipped: false,
        mistakesCount: metrics.attempts,
        timeSpent: metrics.timeSpent
      });
  };

  if (!lessonPhrases || !lessonPhrases.length) return null;

  return (
    <SGameTaskWrapper className={cn(className)}>
      <WordGames
        silentMode={!taskSession}
        lessonId={lessonId}
        taskId={id}
        items={uncompletedGames}
        lessonPhrases={lessonPhrases}
        onGameComplete={handleCompleteGame}
        onComplete={handleCompleteTask}
        onItemComplete={handleItemComplete}
      />
    </SGameTaskWrapper>
  );
};

export default PlayWordGames;

PlayWordGames.backgroundComponent = styled.div`
  background-color: var(--color-white);
`;
