import { tGameType } from '@adeptlms/lingu-students-react-shared';
import { tGameComponent } from './types';
import MatchWordContainer from './MatchWord';
import FlashcardContainer from './Flashcard';
import WordWaterfallContainer from './WordWaterfall';

export const gamesMap = new Map<tGameType, tGameComponent>([
  ['flashcard', FlashcardContainer],
  ['waterfall', WordWaterfallContainer],
  ['match', MatchWordContainer]
  // ['nodes', WordNodesContainer]
]);

export interface IGameConfig {
  maxRounds: number;
  difficulty: 'easy' | 'medium' | 'hard';
  showTutorial?: boolean;
}

export const gamesConfigMap = new Map<tGameType, IGameConfig>([
  ['flashcard', { maxRounds: 30, difficulty: 'easy' }],
  ['waterfall', { maxRounds: 4, difficulty: 'easy' }],
  ['match', { maxRounds: 6, difficulty: 'easy' }]
  // ['nodes', { maxRounds: 6, difficulty: 'easy', showTutorial: true }]
]);
