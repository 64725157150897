import React, { FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  IProfile,
  tLeaderboardTable,
  ILeaderboardRow
} from '@adeptlms/lingu-students-react-shared';

import { customMediaQuery } from 'students/views/shared/styled';

import LeaderboardTable from './components/LeaderboardTable';
import ResultPointsMessage from './components/ResultPointsMessage';

interface ILeaderboardProps {
  leaderboard: tLeaderboardTable;
  currentUserProfile: IProfile | null;
  taskSessionXP: number;
  className?: string;
  bgColor: string;
}

const Leaderboard: FC<ILeaderboardProps> = ({
  leaderboard,
  currentUserProfile,
  taskSessionXP,
  className,
  bgColor
}) => {
  const foundUserInLeaderboardIndex = leaderboard.findIndex(
    (row: ILeaderboardRow) => row.student.id === currentUserProfile?.id
  );

  return (
    <div className={cn(className)}>
      <SResultPointsMessage
        points={taskSessionXP}
        $extraMargin={leaderboard.length > 0}
      />

      {leaderboard.length > 0 ? (
        <SLeaderboardTable
          leaderboard={leaderboard}
          foundUserIndex={foundUserInLeaderboardIndex}
          isHiddenProfile={currentUserProfile?.privateProfile ?? false}
        />
      ) : null}

      <SFader $color={bgColor ?? '#00a5d7'} />
    </div>
  );
};

export default Leaderboard;

const SResultPointsMessage = styled(ResultPointsMessage)<{ $extraMargin: boolean }>``;

const SLeaderboardTable = styled(LeaderboardTable)`
  height: 50vh;
  padding-top: 40px;
  overflow-y: auto;
  padding-bottom: 120px;

  ${customMediaQuery('tablet')} {
    height: 40vh;
    padding-bottom: 20px;
  }
`;

const SFader = styled.div<{ $color: string }>`
  &:before {
    content: '';
    width: 100%;
    height: 30vh;
    background: ${({ $color }) =>
      `linear-gradient(to bottom, transparent, ${$color}, ${$color})`};
    background-repeat: no-repeat;
    background-size: 100% 30vh;
    display: block;
    position: fixed;
    bottom: -32px;
    left: 0;
    right: 0;
  }

  ${customMediaQuery('tablet')} {
    &:before {
      display: none;
    }
  }
`;
