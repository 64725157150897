import { AnimationControls, useAnimation } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { IArrangeItemsItemOption } from '@adeptlms/lingu-students-react-shared';

export const RESULTS_SCREEN_TIMEOUT = 3000;
interface IReturnedValue {
  getBlockType: (option: IArrangeItemsItemOption) => 'audio' | 'picture' | 'word';
  animationControls: AnimationControls;
}

function useItemResultScreen({ onFinish }: { onFinish: () => void }): IReturnedValue {
  const [startFinalAnimation, setStartFinalAnimation] = useState(false);
  const animationControls = useAnimation();

  const handleEnd = useCallback(() => {
    setStartFinalAnimation(true);
  }, []);

  const getBlockType = useCallback((option: IArrangeItemsItemOption) => {
    if (option.audio) {
      return 'audio';
    } else if (option.image) {
      return 'picture';
    }

    return 'word';
  }, []);

  useEffect(() => {
    const timer = window.setTimeout(handleEnd, RESULTS_SCREEN_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [handleEnd]);

  useEffect(() => {
    animationControls.start({
      y: [-100, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.6
      }
    });
  }, [animationControls]);

  useEffect(() => {
    async function startFinishAnimation() {
      await animationControls.start({
        opacity: [1, 0],
        transition: {
          duration: 0.6
        }
      });

      onFinish && onFinish();
    }

    if (startFinalAnimation) {
      startFinishAnimation();
    }
  }, [startFinalAnimation, animationControls, onFinish]);

  return {
    getBlockType,
    animationControls
  };
}

export default useItemResultScreen;
