import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { SFullHeightDiv } from '../common/styled';
import background from './assets/bg-image.png';
import backgroundWebp from './assets/bg-image.webp';

const PAGINATION_HEIGHT = '68px';

export const SContainer = styled(SFullHeightDiv)`
  padding: 36px 16px 16px;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(180deg, #26989b 0%, #82cdaf 100%);
  overflow: hidden;

  &.justifyCenter {
    justify-content: center;
  }

  ${customMediaQuery('tablet')} {
    background: url(${background}) center,
      linear-gradient(180deg, #26989b 0%, #82cdaf 100%),
      linear-gradient(180deg, #c7367d 0%, #e467a2 100%);

    .webp & {
      background: url(${backgroundWebp}) center,
        linear-gradient(180deg, #26989b 0%, #82cdaf 100%),
        linear-gradient(180deg, #c7367d 0%, #e467a2 100%);
    }
  }
`;

export const SSuccessText = styled.p`
  font-weight: bold;
  font-size: 44px;
  line-height: 55px;
  color: var(--color-light-white);
  max-width: 500px;

  ${customMediaQuery('mobile')} {
    font-size: 30px;
    line-height: 37px;
  }
`;

export const SPaginationContainer = styled.div`
  bottom: 16px;
  position: absolute;
  height: ${PAGINATION_HEIGHT};
  z-index: 999;
`;

export const SViewport = styled.div`
  margin-block-start: 36px;
  width: 100%;
  height: calc(100% - ${PAGINATION_HEIGHT});
  max-width: 550px;
  max-height: 600px;
  position: relative;
  margin-block-end: ${PAGINATION_HEIGHT};
`;

export const SShade = styled.div`
  position: absolute;
  width: 100%;
  height: 110%;
  top: 0;
  left: 0;
  background: #20505e;
  opacity: 0.3;
  clip-path: ellipse(150% 100% at 50% 0%);
`;

export const SNextButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 30px;
  left: 0;
  right: 0;

  button {
    margin: 0 auto;
  }
`;

export const SEndPara = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 120%;
  color: var(--color-light-white);
  margin: 1em 0.5em 2em 0.5em;
  position: relative;
`;

export const SWordContainer = styled.div`
  position: absolute;
  cursor: move;
  z-index: 10;
  border-radius: 10px;
  background: #fffae9;
  color: var(--color-black);
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;

  // limit block size for long texts
  max-width: 70%;
  overflow: hidden;

  & p.translation {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ${customMediaQuery('tablet')} {
    max-width: 50%;
  }
  // end: limit block size for long texts

  & > div {
    box-shadow: 0 4px 0 rgba(23, 105, 108, 0.5);
    border-radius: inherit;
    padding: 12px 16px;
  }

  &.wordOrigin {
    background: #fbedbc;
    z-index: 11;

    & > div {
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &.hovering {
    opacity: 0.8 !important;
    transform: scale(1.1);

    &[data-type='picture'] {
      box-shadow: none;
    }

    & > div {
      box-shadow: inset 0 2px 0 rgba(4, 104, 108, 0.6);
    }
  }

  &[data-type='word'].incorrect {
    background: #ff7678;
  }

  &[data-type='picture'].incorrect {
    -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%)
      hue-rotate(-50deg) saturate(400%) contrast(2);
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%)
      contrast(0.8);
  }

  &.correct {
    background: linear-gradient(0deg, #57de7c 6.9%, #48d46e 94.83%);
    box-shadow: inset 0 4px 0 #65f68c, inset 0px -4px 0px #4fa85d;
  }

  &.correct > div::before {
    color: white;
    content: '\\2713';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.correct span {
    visibility: hidden;
  }

  &[data-type='picture'] img {
    pointer-events: none;
  }

  &[data-type='audio'] svg {
    display: block;
  }
`;

export const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 40px;
  height: 40px;

  ${customMediaQuery('tablet')} {
    width: 48px;
    height: 48px;
  }
`;
