export const streakContainerVariants = {
  appear: {
    scaleY: 1,
    rotateY: '0deg',
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    },
    transitionEnd: {
      overflow: 'initial'
    }
  },

  disappear: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.2
    }
  }
};

export const textVariants = {
  appear: {
    rotateY: '0deg',
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.1
    }
  }
};

export const iconVariants = {
  appear: {
    y: '-65%',
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  disappear: {
    scale: 1.15,
    y: -45,
    transition: {
      duration: 0.2
    }
  }
};
