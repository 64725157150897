import { useCallback, useMemo, useState } from 'react';

interface IReturnedObject {
  streakNumber: number;
  streakPercent: number;
  onTheFirstTry: (condition: boolean) => void;
}

const useStreakFactor = (): IReturnedObject => {
  const [streakNumber, setStreakNumber] = useState(0);

  const streakPercent = useMemo(
    () => (streakNumber < 13 ? streakNumber * 2 : 26),
    [streakNumber]
  );

  const increaseStreakNumber = useCallback(() => {
    setStreakNumber((prev) => prev + 1);
  }, []);

  const resetStreakNumber = useCallback(() => {
    setStreakNumber(0);
  }, []);

  const onTheFirstTry = useCallback(
    (condition: boolean) => {
      if (condition) {
        increaseStreakNumber();
      } else {
        resetStreakNumber();
      }
    },
    [increaseStreakNumber, resetStreakNumber]
  );

  return {
    streakNumber,
    streakPercent,
    onTheFirstTry
  };
};

export default useStreakFactor;
