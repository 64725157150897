import { useCallback, useRef } from 'react';

export function useHasChangesAfterLastCheck(): {
  isCheckNeeded: (newValue: any) => boolean;
  markChecked: (value: string) => void;
} {
  const valueFromLastCheck = useRef('');
  const markChecked = useCallback((value: string) => {
    valueFromLastCheck.current = value.trim();
  }, []);
  const isCheckNeeded = useCallback((newValue: string) => {
    return newValue.trim() !== valueFromLastCheck.current;
  }, []);

  return {
    isCheckNeeded,
    markChecked
  };
}
