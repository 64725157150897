import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import { customMediaQuery } from 'students/views/shared/styled';

import { itemBlockVariants } from './animationVariants';
import { SSvgAudioPlayer } from '../styled';
import useItemBlock, { IItemBlockProps } from '../../hooks/useItemBlock';
import { IItemBlockRef } from '../../types';

const ItemBlock = forwardRef<IItemBlockRef, IItemBlockProps>(
  (
    {
      sequence,
      itemBlock,
      onItemDrag,
      onItemDragStart,
      onItemDragEnd,
      onItemTap,
      onItemAnimationComplete,
      getHintMove
    },
    forwardedRef
  ) => {
    const {
      animationControls,
      isDragging,
      handleDrag,
      handleDragStart,
      handleDragEnd,
      handleTap,
      handleAnimationComplete,
      blockType
    } = useItemBlock({
      forwardedRef,
      onItemDrag,
      onItemDragEnd,
      onItemDragStart,
      onItemTap,
      onItemAnimationComplete,
      sequence,
      itemBlock
    });

    return (
      <SItemBlockContainer
        drag
        dragMomentum={false}
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onTap={handleTap}
        layout
        key={`${itemBlock.option.id}_not_origin`}
        ref={(el: HTMLDivElement) =>
          itemBlock.setRef(
            el,
            `${itemBlock.option.id}_not_origin`,
            itemBlock.option.position
          )
        }
        initial={{
          x: 0,
          y: 0,
          scale: 0.8,
          opacity: 0
        }}
        animate={animationControls}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        variants={itemBlockVariants}
        custom={{
          getHintMove,
          currentRef: itemBlock.originRef,
          destinationRef: itemBlock.destinationRef
        }}
        onAnimationComplete={handleAnimationComplete}
        data-type={blockType}
        style={{
          left: `${itemBlock?.originRef?.randX || 0}%`,
          top: `${itemBlock?.originRef?.randY || 0}%`
        }}
      >
        {blockType === 'word' ? (
          <span>{itemBlock.option.text || ''}</span>
        ) : blockType === 'picture' ? (
          <PhraseIcon
            imageUrl={itemBlock.option?.image || ''}
            colorRequired
            animationUrl={null}
            text={`${itemBlock.option.id}_image`}
            width="110px"
            height="110px"
          />
        ) : blockType === 'audio' ? (
          <SSvgAudioPlayer
            src={itemBlock.option?.audio || ''}
            progressColor="#363658"
            disabled={isDragging}
          />
        ) : null}
      </SItemBlockContainer>
    );
  }
);

export default ItemBlock;

const SItemBlockContainer = styled(motion.div)`
  position: absolute;
  cursor: move;
  z-index: 10;
  border-radius: 10px;
  background: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 272px;
  transition: box-shadow 0.3s;
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }

  &[data-type='word'] {
    padding: 12px;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: var(--color-black);
    text-align: center;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.hoverOnAnswer > span {
      display: initial !important;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    ${customMediaQuery('tablet')} {
      font-size: 1.25rem;
      line-height: 1.5rem;
      width: max-content;
    }
  }

  &[data-type='audio'] {
    padding: 12px;
  }
`;
