import sixPercent from '../assets/six_percent.svg';
import eightPercent from '../assets/eight_percent.svg';
import tenPercent from '../assets/ten_percent.svg';
import twelvePercent from '../assets/twelve_percent.svg';
import fourteenPercent from '../assets/fourteen_percent.svg';
import sixteenPercent from '../assets/sixteen_percent.svg';
import eighteenPercent from '../assets/eighteen_percent.svg';
import twentyPercent from '../assets/twenty_percent.svg';
import twentyTwoPercent from '../assets/twenty_two_percent.svg';
import twentyFourPercent from '../assets/twenty_four_percent.svg';
import twentySixPercent from '../assets/twenty_six_percent.svg';

const iconsMap = new Map<number, string>([
  [6, sixPercent],
  [8, eightPercent],
  [10, tenPercent],
  [12, twelvePercent],
  [14, fourteenPercent],
  [16, sixteenPercent],
  [18, eighteenPercent],
  [20, twentyPercent],
  [22, twentyTwoPercent],
  [24, twentyFourPercent],
  [26, twentySixPercent]
]);

export default iconsMap;
