import {
  IArrangeWordsTaskSession,
  IInlineDropdownTask,
  IInlineDropdownTaskSession,
  IPlayArrangeWordsTask,
  tPlayTaskType,
  IFillSentenceGapTask,
  IFillSentenceGapTaskSession,
  IMarkWordTask,
  IMarkWordTaskSession,
  IMarkWordAudioTask,
  IMarkWordAudioTaskSession,
  IPlayImageObjectTask,
  IImageObjectTaskSession,
  IPlayAuditionTask,
  IAuditionTaskSession,
  IMinimalPairsTask,
  IMinimalPairsTaskSession,
  IPlayImageHotspotTask,
  IImageHotspotTaskSession,
  IWordGamesTask,
  IWordGamesTaskSession,
  IDictationTask,
  IDictationTaskSession,
  IPlayArrangeItemsTask,
  IArrangeItemsTaskSession
} from '@adeptlms/lingu-students-react-shared';

import PlayWordGames from 'students/views/pages/LessonTasks/TaskSubjects/Play/Tasks/WordGamesTask';
import PlayArrangeItemsTask from './ArrangeItemsTask';
import PlayArrangeWordsTask from './ArrangeWordsTask';
import PlayFillSentenceGapTask from './FillSentenceGap';
import PlayInlineDropdownTask from './InlineDropdownTask';
import PlayMarkWordTask from './MarkWordTask';
import PlayMarkWordAudioTask from './MarkWordAudioTask';
import PlayImageObjectTask from './ImageObjectTask';
import PlayAuditionTask from './AuditionTask';
import PlayMinimalPairsTask from './MinimalPairsTask';
import PlayImageHotspotTask from './ImageHotspotTask';
import PlayDictationTask from './DictationTask';
import { IPlayTaskProps, tPlayTaskComponentType } from './types';

export type tPlayComponentType =
  | tPlayTaskComponentType<
      IPlayTaskProps<IPlayArrangeItemsTask, IArrangeItemsTaskSession>
    >
  | tPlayTaskComponentType<
      IPlayTaskProps<IPlayArrangeWordsTask, IArrangeWordsTaskSession>
    >
  | tPlayTaskComponentType<
      IPlayTaskProps<IInlineDropdownTask, IInlineDropdownTaskSession>
    >
  | tPlayTaskComponentType<
      IPlayTaskProps<IFillSentenceGapTask, IFillSentenceGapTaskSession>
    >
  | tPlayTaskComponentType<IPlayTaskProps<IMarkWordTask, IMarkWordTaskSession>>
  | tPlayTaskComponentType<IPlayTaskProps<IMarkWordAudioTask, IMarkWordAudioTaskSession>>
  | tPlayTaskComponentType<IPlayTaskProps<IMinimalPairsTask, IMinimalPairsTaskSession>>
  | tPlayTaskComponentType<IPlayTaskProps<IPlayImageObjectTask, IImageObjectTaskSession>>
  | tPlayTaskComponentType<
      IPlayTaskProps<IPlayImageHotspotTask, IImageHotspotTaskSession>
    >
  | tPlayTaskComponentType<IPlayTaskProps<IPlayAuditionTask, IAuditionTaskSession>>
  | tPlayTaskComponentType<IPlayTaskProps<IWordGamesTask, IWordGamesTaskSession>>
  | tPlayTaskComponentType<IPlayTaskProps<IDictationTask, IDictationTaskSession>>;

export const playTaskMap = new Map<tPlayTaskType['type'], tPlayComponentType>([
  ['Tasks::ArrangeItems', PlayArrangeItemsTask],
  ['Tasks::ArrangeWords', PlayArrangeWordsTask],
  ['Tasks::Audition', PlayAuditionTask],
  ['Tasks::Dictation', PlayDictationTask],
  ['Tasks::InlineDropdown', PlayInlineDropdownTask],
  ['Tasks::FillGap', PlayFillSentenceGapTask],
  ['Tasks::MarkWord', PlayMarkWordTask],
  ['Tasks::MarkWordAudio', PlayMarkWordAudioTask],
  ['Tasks::SelectVideo', PlayMinimalPairsTask],
  ['Tasks::ImageObject', PlayImageObjectTask],
  ['Tasks::ImageHotspot', PlayImageHotspotTask],
  ['Tasks::WordGames', PlayWordGames]
]);
