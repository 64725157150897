import { FC, PropsWithChildren, useEffect, useState } from 'react';

interface IProps {
  waitBeforeShow?: number;
}

const Delayed: FC<PropsWithChildren<IProps>> = ({ children, waitBeforeShow = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => window.clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? <>{children}</> : null;
};

export default Delayed;
