import { tCheckTaskType } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { AudioContent } from 'common/modules/AudioPlayer';
import { FC, PropsWithChildren } from 'react';
import useZoomableImage from 'students/views/shared/bundles/zoomableImage/useZoomableImage';
import ImageContent from 'students/views/shared/components/ImageContent';
import { VideoContent } from 'students/views/shared/components/MediaPlayer/VideoPlayer';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import STaskDescription from './styled/STaskDescription';
import STaskTitle from './styled/STaskTitle';

interface IProps {
  task: tCheckTaskType;
  className?: string;
}

const BaseCheckTaskBody: FC<PropsWithChildren<IProps>> = ({
  children,
  task,
  className
}) => {
  const {
    imageURL,
    audioURL,
    videoURL,
    mobileImageURL,
    coverImg,
    giphyImage,
    introduction,
    title,
    imageAlt
  } = task;

  const hasDescription = richContentHasText(introduction);

  const showImage = (!!imageURL || !!coverImg || giphyImage) && !videoURL;
  const showVideo = !!videoURL;
  const showAudio = !!audioURL && !videoURL;

  const { contentRef, backdropVisible, setElementRefAndInitialize } = useZoomableImage(
    {}
  );

  return (
    <SBaseCheckTaskBody className={cn(className)} ref={contentRef}>
      {showVideo && <SVideoContent videoURL={videoURL || ''} />}

      {showImage && (
        <ImageContent
          image={imageURL}
          cover={coverImg}
          smallImage={mobileImageURL}
          giphyImage={giphyImage}
          isZoomable
          alt={imageAlt || ''}
        />
      )}

      <SPaddedContent>
        {showAudio && (
          <SAudioContent audio={audioURL} size="sm" className="ln-self-center" />
        )}

        {!!title && (
          <STaskTitle dir="ltr" className={cn({ extraSpace: hasDescription })}>
            {title}
          </STaskTitle>
        )}

        {hasDescription && introduction && (
          <STaskDescription
            dir="ltr"
            ref={setElementRefAndInitialize}
            dangerouslySetInnerHTML={{
              __html: introduction
            }}
          />
        )}

        <SBackdrop className={cn({ visible: backdropVisible })} />

        {children}
      </SPaddedContent>
    </SBaseCheckTaskBody>
  );
};

export default BaseCheckTaskBody;

export const SBaseCheckTaskBody = styled.div`
  background: white;
  border-radius: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 288px;
  overflow: hidden;

  ${customMediaQuery('desktop')} {
    min-width: calc(${({ theme }) => theme.linguBptMd} - 20px);
    border-radius: 20px;
  }

  &.no-overflow {
    overflow: initial;
  }
`;

const SVideoContent = styled(VideoContent)`
  border-radius: 0;
`;

const SAudioContent = styled(AudioContent)`
  margin: 0 0 30px 0;
`;

export const SPaddedContent = styled.div`
  padding: 32px 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    padding: 50px 70px 40px;
  }
`;

const SBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(45, 45, 58, 0.9);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.4s;

  &.visible {
    opacity: 1;
    z-index: 1;
  }
`;

function richContentHasText(__html?: string): boolean {
  if (!__html) return false;
  const tempElement = document.createElement('div');
  tempElement.innerHTML = __html || '';
  const hasContent = tempElement.innerText.trim() !== '';
  tempElement.remove();
  return hasContent;
}
