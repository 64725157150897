import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

interface IReturnedValue {
  showFirstLevelTooltip: boolean;
  showSecondLevelTooltip: boolean;
  openSecondLevelTooltip: () => void;
  tooltipCoordinates: { x: string; y: string };
  selectedText: string;
  handleTextSelect: (
    text: string,
    { clientX, clientY }: { clientX: number; clientY: number },
    refNode: Node | null
  ) => void;
  firstLevelTooltipRef: RefObject<HTMLDivElement>;
  secondLevelTooltipRef: RefObject<HTMLDivElement>;
}

function useTextSelectTooltip(): IReturnedValue {
  const [selectedText, setSelectedText] = useState<string>('');
  const [showFirstLevelTooltip, setShowFirstLevelTooltip] = useState<boolean>(false);
  const firstLevelTooltipRef = useRef(null);
  const [showSecondLevelTooltip, setShowSecondLevelTooltip] = useState<boolean>(false);
  const secondLevelTooltipRef = useRef(null);
  const [tooltipCoordinates, setTooltipCoordinates] = useState<{ x: string; y: string }>({
    x: '0',
    y: '0'
  });
  const [selectionRefNode, setSelectionNodeRef] = useState<Node | null>(null);

  const openFirstLevelTooltip = useCallback(() => {
    setShowFirstLevelTooltip(true);
  }, [setShowFirstLevelTooltip]);

  const closeFirstLevelTooltip = useCallback(() => {
    setShowFirstLevelTooltip(false);
  }, [setShowFirstLevelTooltip]);

  const openSecondLevelTooltip = useCallback(() => {
    setShowSecondLevelTooltip(true);
  }, [setShowSecondLevelTooltip]);

  const closeSecondLevelTooltip = useCallback(() => {
    setShowSecondLevelTooltip(false);
  }, [setShowSecondLevelTooltip]);

  const handleTextSelect = useCallback(
    (
      text: string,
      { clientX, clientY }: { clientX: number; clientY: number },
      refNode: Node | null
    ) => {
      if (text.length) {
        setSelectedText(text);
        closeSecondLevelTooltip();
        openFirstLevelTooltip();
        setTooltipCoordinates({ x: `${String(clientX)}px`, y: `${String(clientY)}px` });
        setSelectionNodeRef(refNode);
      } else {
        closeFirstLevelTooltip();
        closeSecondLevelTooltip();
        setSelectionNodeRef(null);
      }
    },
    [
      setTooltipCoordinates,
      openFirstLevelTooltip,
      closeFirstLevelTooltip,
      closeSecondLevelTooltip
    ]
  );

  const handleClickOutside = useCallback(() => {
    closeFirstLevelTooltip();
    closeSecondLevelTooltip();
    setSelectedText('');
  }, [closeFirstLevelTooltip, closeSecondLevelTooltip]);

  useEffect(() => {
    const listener = (event: Event) => {
      if (!selectionRefNode || selectionRefNode.contains(event.target as Node)) {
        return;
      }

      if (
        [firstLevelTooltipRef, secondLevelTooltipRef].some(
          (ref: RefObject<HTMLDivElement>) => ref.current?.contains(event.target as Node)
        )
      ) {
        return;
      }

      handleClickOutside();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [selectionRefNode, showFirstLevelTooltip, handleClickOutside]);

  return {
    showFirstLevelTooltip,
    showSecondLevelTooltip,
    openSecondLevelTooltip,
    tooltipCoordinates,
    selectedText,
    handleTextSelect,
    firstLevelTooltipRef,
    secondLevelTooltipRef
  };
}

export default useTextSelectTooltip;
