import React from 'react';
import { motion } from 'framer-motion';
import LottieIcon from 'students/views/shared/components/LottieIcon';
import Delayed from 'students/views/shared/components/Delayed';
import HandHint from '../../common/HandHint';
import { IArea } from '../types';
import handHintAnimation from './assets/handHint.json';
import pulsationHintAnimation from './assets/pulsationHint.json';

const PULSATION_TIMEOUT = 8000;
const CIRCLE_RADIUS = 96;
const POINTER_ICON_OFFSET = {
  x: 20,
  y: 10
};

interface IProps {
  area1: IArea;
  area2: IArea;
}

const MatchWordHint: React.FC<IProps> = ({ area1, area2 }) => (
  <>
    <motion.div
      className="ln-absolute"
      animate={{
        left: [
          area1.x,
          area1.x,
          area1.x + POINTER_ICON_OFFSET.x,
          area2.x + POINTER_ICON_OFFSET.x
        ],
        top: [
          area1.y + 100,
          area1.y + 50,
          area1.y + POINTER_ICON_OFFSET.y,
          area2.y + POINTER_ICON_OFFSET.y
        ]
      }}
      transition={{ duration: 4, times: [0, 0.5, 0.75, 1] }}
      style={{ zIndex: 11 }}
    >
      <LottieIcon
        destroyOnComplete
        width="6rem"
        loop={false}
        autoPlay
        animationJSONData={handHintAnimation}
      />
    </motion.div>
    <Delayed waitBeforeShow={PULSATION_TIMEOUT}>
      <HandHint
        animationData={pulsationHintAnimation}
        left={area1.x + area1.width / 2 - CIRCLE_RADIUS}
        top={area1.y + area1.height / 2 - CIRCLE_RADIUS}
        width={CIRCLE_RADIUS * 2}
        loop
      />
      <Delayed>
        <HandHint
          animationData={pulsationHintAnimation}
          left={area2.x + area2.width / 2 - CIRCLE_RADIUS}
          top={area2.y + area2.height / 2 - CIRCLE_RADIUS}
          width={CIRCLE_RADIUS * 2}
          loop
        />
      </Delayed>
    </Delayed>
  </>
);

export default MatchWordHint;
