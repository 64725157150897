import { motion, useAnimation } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import { withFade2 } from 'students/views/shared/HOCs';
import bubbleAnimationManager from 'students/views/shared/bundles/bubbleAnimationManager';
import { StartButton } from 'students/views/shared/components/Buttons';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { usePlayAudioPlayer } from '../hooks';

const FINAL_MEASURE_PX = 100;

interface IProps {
  onStart: () => void;
  isCompleting: boolean;
  heading: string;
}

const MIN_LETTERS = 5;
const MAX_LETTERS = 10;

const MAX_FONT_SIZE = 22;
const MIN_FONT_SIZE = 10;

const StartScreen: FC<IProps> = ({ isCompleting, onStart, heading }) => {
  const animationControls = useAnimation();
  const headingControls = useAnimation();
  const { playStartSound } = usePlayAudioPlayer();
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const startButtonRef = useRef<HTMLButtonElement | null>(null);
  const textWrapperRef = useRef<HTMLSpanElement | null>(null);

  // apply animations
  useEffect(() => {
    if (bubbleAnimationManager.showBubbleAnimation) {
      if (buttonWrapperRef.current) {
        const viewportOffset = buttonWrapperRef.current.getBoundingClientRect();
        bubbleAnimationManager.startButtonPosition = {
          left: viewportOffset.left,
          top: viewportOffset.top,
          width: buttonWrapperRef.current.offsetWidth,
          height: buttonWrapperRef.current.offsetHeight
        };
      }
    } else {
      animationControls.start({
        width: FINAL_MEASURE_PX,
        height: FINAL_MEASURE_PX,
        transition: {
          duration: 0.6,
          ease: 'easeInOut'
        }
      });
    }
  }, [animationControls, buttonWrapperRef]);

  // resize text if it doesn't fit into button
  useEffect(() => {
    if (!startButtonRef.current || !textWrapperRef.current) return;

    const textLength = textWrapperRef.current.innerText?.length;

    const clampedTextLength = Math.max(MIN_LETTERS, Math.min(MAX_LETTERS, textLength));

    // Interpolate fontSize between 22px and 10px
    const fontSize =
      MAX_FONT_SIZE -
      ((clampedTextLength - MIN_LETTERS) / (MAX_LETTERS - MIN_LETTERS)) *
        (MAX_FONT_SIZE - MIN_FONT_SIZE);

    textWrapperRef.current.style.fontSize = `${fontSize}px`;
  }, [textWrapperRef, startButtonRef]);

  const handleStart = async () => {
    playStartSound();

    await Promise.all([
      animationControls.start({
        width: 200,
        height: 200,
        opacity: [1, 0],
        transition: {
          duration: 0.6,
          ease: 'easeOut'
        },
        transitionEnd: { display: 'none' }
      }),
      headingControls.start({
        y: [0, 80],
        opacity: [1, 0],
        transition: {
          duration: 0.6,
          ease: 'easeOut'
        }
      })
    ]);

    onStart();
  };

  return (
    <SWrapper
      className="ln-flex ln-flex-col ln-grow ln-justify-center ln-items-center"
      dir="ltr"
    >
      <SHeading animate={headingControls}>{heading}</SHeading>
      <motion.div
        ref={buttonWrapperRef}
        animate={animationControls}
        initial={{
          width: bubbleAnimationManager.showBubbleAnimation ? 100 : 210,
          height: bubbleAnimationManager.showBubbleAnimation ? 100 : 210
        }}
      >
        <SStartButton
          data-testid="students/components/TaskSubjects/Play/StartScreen/StartButton"
          onClick={handleStart}
          disabled={isCompleting}
          size="100%"
          ref={startButtonRef}
          innerTextRef={textWrapperRef}
        />
      </motion.div>
    </SWrapper>
  );
};

export default withFade2<IProps>({
  duration: 800,
  className: 'ln-flex ln-grow'
})(StartScreen);

const SWrapper = styled.div`
  max-width: 670px;
`;

const SHeading = styled(motion.h2)`
  color: var(--color-light-white);
  margin: 0 0 50px;
  padding: 0;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.625rem;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 2.375rem;
    line-height: 3.125rem;
    margin: 0 0 90px;
  }
`;

const SStartButton = styled(StartButton)`
  font-size: 1.125rem;
  margin: 0;

  ${customMediaQuery('tablet')} {
    font-size: 1.375rem;
  }
`;
