import { useState, useCallback, useEffect } from 'react';

interface IUseScrollToTarget {
  showButton: boolean;
  handleClick: () => void;
  updateScrollCheck: () => void;
}

export interface IOptions {
  targetRef?: React.RefObject<HTMLElement | null | undefined>;
}

export const useScrollToTarget = ({ targetRef }: IOptions): IUseScrollToTarget => {
  const [showButton, setShowButton] = useState<boolean>(false);

  const scrollCheck = useCallback(() => {
    if (targetRef?.current) {
      const isAtElementOrLower =
        window.innerHeight + window.scrollY >=
        targetRef.current.offsetTop + targetRef.current.offsetHeight / 2;
      if (isAtElementOrLower === showButton) {
        setShowButton(!showButton);
      }
    } else {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      if (isAtBottom === showButton) {
        setShowButton(!showButton);
      }
    }
  }, [showButton, targetRef]);

  const handleClick = useCallback(() => {
    if (targetRef?.current) {
      targetRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      targetRef.current?.focus();
    } else {
      window.scrollTo({
        top: document.body.offsetHeight,
        behavior: 'smooth'
      });
    }
  }, [targetRef]);

  useEffect(() => {
    scrollCheck();
  }, [scrollCheck]);

  const updateScrollCheck = useCallback(() => {
    scrollCheck();
  }, [scrollCheck]);

  useEffect(() => {
    document.addEventListener('scroll', scrollCheck);

    // call scrollCheck in order user changes device orientation
    window.addEventListener('resize', scrollCheck);

    return () => {
      document.removeEventListener('scroll', scrollCheck);
      window.removeEventListener('resize', scrollCheck);
    };
  }, [scrollCheck]);

  return { showButton, handleClick, updateScrollCheck };
};
