import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { PanInfo } from 'framer-motion';
import { IPhrase } from '@adeptlms/lingu-students-react-shared';
import { useGameAudioPlayer } from '../../common/helpers';
import { IArea, IWordAnswer, IWordPosition } from '../types';
import { useMatchWordHint } from '../MatchWordHint';
import { IMatchWordBlockRef } from '../MatchWordViewport/MatchWordBlock';
import { useDraggableBlocks } from '../MatchWordViewport/useDraggableBlocks';

const HINT_INDEX = 0;

interface IItemMatch {
  status: '' | 'correct' | 'incorrect';
  first: number;
  second: number;
}

export interface IMatchWordViewport {
  phrases: IPhrase[];
  onComplete: () => void;
  onRoundComplete: (payload: IWordAnswer) => void;
}

interface IReturnType {
  viewportRef: (node: HTMLDivElement) => void;
  wordPositions: IWordPosition[];
  hoveringIndex: number;
  getCorrectClassName: (index: number) => '' | 'correct' | 'incorrect';
  onDrag: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    _index: number
  ) => void;
  onDragStart: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    index: number
  ) => void;
  onDragEnd: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    _info: PanInfo,
    _index: number
  ) => void;
  onBlockAnimationComplete: (index: number) => void;
  hintMoveX: number;
  hintMoveY: number;
  wordsRefs: Record<string, RefObject<IMatchWordBlockRef>>;
  hintVisible: boolean;
  hintAreas: IArea[];
}

export const useMatchWordViewport = ({
  phrases,
  onRoundComplete,
  onComplete
}: IMatchWordViewport): IReturnType => {
  const [hoveringIndex, setHoveringIndex] = useState(-1);
  const [matchedWords, setMatchedWords] = useState<number[]>([]);
  const [itemMatch, setItemMatch] = useState<IItemMatch>({
    status: '',
    first: -1,
    second: -1
  });
  const [viewportRef, rect, wordPositions] = useDraggableBlocks(phrases);
  const { playCorrectSound, playIncorrectSound } = useGameAudioPlayer();
  const [hintVisible, markInteracted, setHintNeeded] = useMatchWordHint();
  const hintAreas = useMemo(
    () => [wordPositions[HINT_INDEX].area, wordPositions[HINT_INDEX + 1].area],
    [wordPositions]
  );

  const selectedIndexRef = useRef(-1);

  const wordsRefs = useMemo(
    () =>
      wordPositions.reduce(
        (obj: Record<string, RefObject<IMatchWordBlockRef>>, _word, index) => {
          obj[index] = createRef();
          return obj;
        },
        {}
      ),
    [wordPositions]
  );

  const hintMoveX = useMemo(() => hintAreas[1].x - hintAreas[0].x, [hintAreas]);
  const hintMoveY = useMemo(() => hintAreas[1].y - hintAreas[0].y, [hintAreas]);

  const initBlocks = useCallback(
    (itemIndex = -1) => {
      if (itemIndex !== -1) {
        wordsRefs[itemIndex].current?.triggerAnimation('initPosition');
        return;
      }
      if (selectedIndexRef.current === -1) {
        for (const key in wordsRefs) {
          wordsRefs[key].current?.triggerAnimation('visible');
        }
      }
    },
    [wordsRefs]
  );

  useEffect(() => {
    initBlocks();
  }, [initBlocks]);

  useEffect(() => {
    if (hintVisible) {
      wordsRefs[HINT_INDEX].current?.triggerAnimation('hint');
    } else {
      wordsRefs[HINT_INDEX].current?.cancelAnimation();
    }
  }, [hintVisible, wordsRefs]);

  useEffect(() => {
    if (itemMatch.first !== -1 && itemMatch.second !== -1) {
      if (itemMatch.status === 'correct') {
        wordsRefs[itemMatch.first].current?.triggerAnimation('correct');
        wordsRefs[itemMatch.second].current?.triggerAnimation('correct');
      }
      if (itemMatch.status === 'incorrect') {
        wordsRefs[itemMatch.first].current?.triggerAnimation('incorrectDragged');
        wordsRefs[itemMatch.second].current?.triggerAnimation('incorrectDraggedTo');
      }
    }
  }, [itemMatch.first, itemMatch.second, itemMatch.status, initBlocks, wordsRefs]);

  const getHoveringIndex = useCallback(
    (x: number, y: number) => {
      for (let i = 0; i < wordPositions.length; i += 1) {
        if (
          x >= wordPositions[i].area.x &&
          x <= wordPositions[i].area.x + wordPositions[i].area.width &&
          y >= wordPositions[i].area.y &&
          y <= wordPositions[i].area.y + wordPositions[i].area.height
        ) {
          return i;
        }
      }
      return -1;
    },
    [wordPositions]
  );

  const onDrag = useCallback(
    (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo, _index: number) => {
      if (selectedIndexRef.current !== -1 && rect) {
        const offset = {
          x: (rect.left || 0) + document.documentElement.scrollLeft,
          y: (rect.top || 0) + document.documentElement.scrollTop
        };
        const hoveredItemIndex = getHoveringIndex(
          info.point.x - offset.x,
          info.point.y - offset.y
        );
        if (
          hoveredItemIndex !== selectedIndexRef.current &&
          hoveredItemIndex !== hoveringIndex
        ) {
          setHoveringIndex(hoveredItemIndex);
          markInteracted();
        }
      }
    },
    [getHoveringIndex, hoveringIndex, markInteracted, rect]
  );

  const onDragStart = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo, index: number) => {
      selectedIndexRef.current = index;
      markInteracted();
    },
    [markInteracted]
  );

  const onDragEnd = useCallback(
    (_event: MouseEvent | TouchEvent | PointerEvent, _info: PanInfo, _index: number) => {
      const selectedWord = wordPositions[selectedIndexRef.current];
      if (hoveringIndex !== -1 && matchedWords.indexOf(hoveringIndex) === -1) {
        const isCorrect =
          wordPositions[selectedIndexRef.current].phrase.id ===
          wordPositions[hoveringIndex].phrase.id;
        setHoveringIndex(-1);
        onRoundComplete({
          word: selectedWord.phrase.body,
          solved: isCorrect
        });
        if (isCorrect) {
          setItemMatch({
            status: 'correct',
            first: selectedIndexRef.current,
            second: hoveringIndex
          });
          setMatchedWords([...matchedWords, hoveringIndex, selectedIndexRef.current]);
          playCorrectSound();
          setHintNeeded(false);
        } else {
          setItemMatch({
            status: 'incorrect',
            first: selectedIndexRef.current,
            second: hoveringIndex
          });
          playIncorrectSound();
        }
      } else if (selectedIndexRef.current !== -1) {
        initBlocks(selectedIndexRef.current);
      }
      selectedIndexRef.current = -1;
      markInteracted();
    },
    [
      hoveringIndex,
      initBlocks,
      markInteracted,
      matchedWords,
      onRoundComplete,
      playCorrectSound,
      playIncorrectSound,
      setHintNeeded,
      wordPositions
    ]
  );

  const onBlockAnimationComplete = useCallback(
    (index: number) => {
      if (itemMatch.status !== '') {
        if (itemMatch.status !== 'correct' || itemMatch.first !== index) {
          setItemMatch({ status: '', first: -1, second: -1 });
          if (matchedWords.length >= phrases.length * 2) onComplete();
        }
      }
    },
    [itemMatch.first, itemMatch.status, matchedWords.length, onComplete, phrases.length]
  );

  const getCorrectClassName = useCallback(
    (index: number) => {
      if (itemMatch.status === 'correct') {
        if (itemMatch.second === index || itemMatch.first === index) {
          return 'correct';
        } else {
          return '';
        }
      } else if (itemMatch.status === 'incorrect') {
        return itemMatch.second === index ? 'incorrect' : '';
      }
      return '';
    },
    [itemMatch.first, itemMatch.second, itemMatch.status]
  );

  return {
    viewportRef,
    wordPositions,
    hoveringIndex,
    getCorrectClassName,
    onDrag,
    onDragStart,
    onDragEnd,
    onBlockAnimationComplete,
    hintMoveX,
    hintMoveY,
    wordsRefs,
    hintVisible,
    hintAreas
  };
};
