import React, { FC } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { IMetrics, IPhrase, tGameType } from '@adeptlms/lingu-students-react-shared';
import { PADDINGABLE_FOR_MOBILE_STATUS_BAR_CLASS } from 'students/constants';
import useTaskGames from './useTaskGames';

export interface IWordGamesProps {
  lessonId: number;
  taskId: number;
  silentMode?: boolean;
  phrases: IPhrase[];
  gameType: tGameType;
  onGameComplete: () => void;
  onRoundComplete: (metrics: IMetrics) => void;
}

const TaskGames: FC<IWordGamesProps> = ({
  lessonId,
  taskId,
  silentMode,
  phrases,
  gameType,
  onGameComplete,
  onRoundComplete
}) => {
  const { game, gameplay, metrics } = useTaskGames({
    silentMode,
    lessonId,
    phrases,
    gameType,
    onGameComplete
  });
  const ActiveGame = game?.component;
  const gameConfig = game?.config;

  const handleOnNext = () => {
    onRoundComplete(metrics);

    gameplay.nextGame();
  };

  return (
    <SGameWrapper>
      {ActiveGame && (
        <ActiveGame
          taskId={taskId}
          showTutorial={gameConfig?.showTutorial}
          difficulty={gameConfig?.difficulty}
          maxRounds={gameConfig?.maxRounds}
          phrases={phrases}
          onNext={handleOnNext}
          metrics={metrics}
          onRoundComplete={gameplay.roundComplete}
          className={cn(PADDINGABLE_FOR_MOBILE_STATUS_BAR_CLASS)}
        />
      )}
    </SGameWrapper>
  );
};

export default TaskGames;

const SGameWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
