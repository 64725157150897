// NOTE: this is not a "translation" tooltip. It's a ordnett.no tooltip
import { LanguagesApiTypes } from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import { FC, ReactElement, RefObject, useCallback } from 'react';
import { VocabularyIcon } from 'students/views/shared/components/icons';
import styled from 'styled-components';

import { Carousel } from 'students/views/pages/Member/components/common';

import OrdnettLogo from 'students/views/shared/assets/ordnett-logo.svg';
import ButtonGeneral from '../ButtonGeneral/ButtonGeneral';
import LinkElem from '../LinkElem';
import { Spinner } from '../Spinner';
import TextSelectTooltip from '../TextSelectTooltip';
import UrlIcon from '../UrlIcon';
import useTranslationTextTooltip from './useTranslationTextTooltip';

interface ITranslationTextTooltip {
  children: ReactElement;
}

const TranslationTextTooltip: FC<ITranslationTextTooltip> = ({ children }) => {
  const { definitions, isDefinitionLoading, handleOpenSecondLevelTooltip } =
    useTranslationTextTooltip();

  const firstLevelTooltip = useCallback(
    (
      ref: RefObject<HTMLDivElement>,
      selectedText: string,
      openSecondLevelTooltip: () => void
    ) => (
      <div ref={ref}>
        <SFirstLevelTooltip
          onClick={handleOpenSecondLevelTooltip(selectedText, openSecondLevelTooltip)}
        >
          <VocabularyIcon height="24px" width="24px" color="var(--color-blue)" />
          <Translate str="frontend.lesson_page.look_up" />
        </SFirstLevelTooltip>
      </div>
    ),
    [handleOpenSecondLevelTooltip]
  );

  const secondLevelTooltip = useCallback(
    (ref: RefObject<HTMLDivElement>) => (
      <div ref={ref}>
        <SSecondLevelTooltip>
          <SBody>
            {isDefinitionLoading ? <Spinner variant="darkBlue" /> : null}

            {!isDefinitionLoading && definitions?.length ? (
              <Carousel
                withControls
                slidesVisible={1}
                adaptive
                panels={definitions.map(
                  (definition: LanguagesApiTypes.IWordSearchArticle, index: number) => (
                    <div
                      key={`carousel-panel-${index}`}
                      dangerouslySetInnerHTML={{ __html: definition.html }}
                    />
                  )
                )}
              />
            ) : null}

            {!isDefinitionLoading && definitions?.length === 0 ? (
              <Translate str="frontend.member.home_page.no_results" />
            ) : null}
          </SBody>
          <SFooter>
            Powered by
            <SLink to="https://www.ordnett.no/" target="blank">
              <UrlIcon url={OrdnettLogo} width="56px" height="11px" />
            </SLink>
          </SFooter>
        </SSecondLevelTooltip>
      </div>
    ),
    [isDefinitionLoading, definitions]
  );

  return (
    <TextSelectTooltip
      firstLevelTooltip={firstLevelTooltip}
      secondLevelTooltip={secondLevelTooltip}
    >
      {children}
    </TextSelectTooltip>
  );
};

export default TranslationTextTooltip;

const SFirstLevelTooltip = styled(ButtonGeneral)`
  background: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  border: none;
  color: var(--color-blue);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 16px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  transition: background 0.3s;

  &:hover {
    background: rgb(230, 230, 230);
  }
`;

const SSecondLevelTooltip = styled.div`
  background: var(--color-white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Carousel {
    &_Button--prev {
      left: -30px;
    }

    &_Button--next {
      right: -30px;
    }
  }

  .article {
    max-height: 278px;
    overflow-y: auto;
    font-size: 1rem;
    color: var(--color-black);

    .headword {
      color: var(--color-black);

      & + span {
        color: var(--color-black) !important;
        font-weight: 600;
      }
    }

    .betydningsseksjon {
      span {
        font-size: 0.875rem !important;
        color: var(--color-black) !important;
        opacity: 0.5;
      }

      .idiom {
        font-size: 1rem !important;
        color: var(--color-black) !important;
        opacity: 1;
      }
    }

    .overskrift {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      color: var(--color-black) !important;
      opacity: 0.5;
    }

    .reference_arrow {
      display: none;
    }

    .reference {
      pointer-events: none;
      color: var(--color-blue);
      text-transform: none;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #a7aab650;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const SBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 18px;
  min-width: 278px;
  max-width: 300px;
  color: var(--color-black);
`;

const SFooter = styled.div`
  background: var(--color-light-grey);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-family: var(--lingu-text-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.02em;
  color: #a7aab6;
  padding: 7px 16px 9px;

  & > span {
    margin-inline-end: 6px;
  }
`;

const SLink = styled(LinkElem)`
  display: flex;
  padding-top: 2px;
  padding-inline-start: 2px;
`;
