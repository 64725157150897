import React from 'react';
import { motion as m } from 'framer-motion';
import styled from 'styled-components';
import { Translate } from 'i18n';
import { customMediaQuery } from 'students/views/shared/styled';
import { useStreak } from './hooks';
import StreakIcon from './StreakIcon';
import { iconVariants, streakContainerVariants, textVariants } from './animations';

interface IProps {
  streakNumber: number;
  streakPercent: number;
  minStreakNumber?: number;
}

const Streak: React.FC<IProps> = ({
  streakNumber,
  streakPercent,
  minStreakNumber = 3
}) => {
  const { show, wrapperAnimations, handleClick } = useStreak(
    streakNumber,
    minStreakNumber
  );

  if (!show) {
    return null;
  }

  return (
    <SPlacementWrapper>
      <SWrapper onClick={handleClick}>
        <SStreak
          animate={wrapperAnimations}
          initial={{ rotateY: '60deg', scaleY: 0 }}
          key={streakNumber}
          variants={streakContainerVariants}
        >
          <SIconWrapper initial={{ y: 5, opacity: 0 }} variants={iconVariants}>
            <StreakIcon streakPercent={streakPercent} />
          </SIconWrapper>
          <STextWrapper initial={{ opacity: 0.2 }} variants={textVariants}>
            <Translate
              str="frontend.lesson_task.tasks.play.streak_factor.streak_text"
              params={{ streakNumber }}
            />
          </STextWrapper>
        </SStreak>
      </SWrapper>
    </SPlacementWrapper>
  );
};

export default Streak;

const SPlacementWrapper = styled.div`
  z-index: 4;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 18px;
  width: 100%;

  ${customMediaQuery('tablet')} {
    width: auto;
    bottom: auto;
    top: 28px;
  }
`;

const SWrapper = styled.div`
  overflow: hidden;
  cursor: pointer;
  padding: 20px 1rem 0 1rem;
`;

const SStreak = styled(m.div)`
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 18px;
  padding: 11px 23px 11px 144px;
  display: flex;
  transform-origin: left;

  ${customMediaQuery('tablet')} {
    padding: 13px 25px 13px 151px;
  }
`;
const SIconWrapper = styled(m.div)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
`;

const STextWrapper = styled(m.div)`
  min-width: 180px;
  max-width: 180px;
  font-family: var(--lingu-text-font-family);
  font-size: 0.875rem;
  color: var(--color-white);
  line-height: 110%;
  letter-spacing: -0.02em;
  opacity: 0.8;
  overflow: hidden;

  ${customMediaQuery('tablet')} {
    font-size: 1.125rem;
    line-height: 100%;
    min-width: 230px;
    max-width: 240px;
  }
`;
