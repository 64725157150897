import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

const SBodyContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 14px;
  width: 90vw;
  max-width: 100vw;
  height: 100%;
  max-height: none;
  flex-grow: 4;
  overflow: auto;
  margin-block-end: 2rem;
  scroll-behavior: smooth;

  ${customMediaQuery('tablet')} {
    justify-content: flex-start;
    width: auto !important;
    height: 100%;
    max-width: 90vw;
    flex-grow: 0;
    margin-block-end: 0;
  }
`;

export default SBodyContainer;
