import { useCallback, useEffect, useRef } from 'react';
import { AnimationControls, useAnimation } from 'framer-motion';

interface IReturnedObject {
  wrapperAnimations: AnimationControls;
  handleClick: () => Promise<void>;
}

export const useStreakAnimation = (
  streakNumber: number,
  show: boolean,
  onHide: () => void
): IReturnedObject => {
  const wrapperAnimations = useAnimation();

  const timeoutRef = useRef<number | null>(null);

  const handleDisappear = useCallback(
    () =>
      wrapperAnimations.start('disappear').then(() => {
        onHide();
      }),
    [onHide, wrapperAnimations]
  );

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    if (show) {
      wrapperAnimations.start('appear').then(() => {
        timeoutRef.current = window.setTimeout(() => {
          handleDisappear();
        }, 1000);
      });
    }

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [handleDisappear, show, streakNumber, wrapperAnimations]);

  const handleClick = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    return handleDisappear();
  }, [handleDisappear]);

  return {
    wrapperAnimations,
    handleClick
  };
};
