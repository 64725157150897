import { IInlineDropdownTask } from '@adeptlms/lingu-students-react-shared';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DashedPagination from 'students/views/shared/components/DashedPagination';
import styled from 'styled-components';
import InlineDropdownItem, {
  ISelectAnswerPayload
} from './components/InlineDropdownItem';
import ItemResultScreen from './components/ItemResultScreen';

const ITEM_ANSWER_TIMEOUT = 2000;

interface IProps {
  task: IInlineDropdownTask;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  onComplete: () => void;
  onSkip: (itemId: number) => void;
}

const PlayInlineDropdownMainScreen: React.FC<IProps> = ({
  task,
  onSelectAnswer,
  onComplete,
  onSkip
}) => {
  const { items } = task;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isResultVisible, setIsResultVisible] = useState(false);
  const currentItem = items[currentIndex];
  const timeoutRef = useRef<number | null>(null);
  const [isNavigationDisabled, setIsNavigationDisabled] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const handleNext = useCallback(() => {
    setIsNavigationDisabled(false);

    if (currentIndex + 1 === items.length) {
      handleComplete();
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, [handleComplete, currentIndex, items.length]);

  const handleItemFinished = useCallback(() => {
    if (currentItem.audioURL) {
      setIsResultVisible(true);
    } else {
      timeoutRef.current = window.setTimeout(handleNext, ITEM_ANSWER_TIMEOUT);
    }
  }, [currentItem, handleNext]);

  const handleItemResultFinish = useCallback(() => {
    setIsNavigationDisabled(false);
    setIsResultVisible(false);
    handleNext();
  }, [handleNext]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    setIsNavigationDisabled(true);
    onSelectAnswer(payload);
  };

  const handleItemNext = () => {
    onSkip(currentItem.id);

    handleNext();
  };

  const handleItemPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((currentIndex) => currentIndex - 1);
    }
  };

  if (isResultVisible) {
    return <ItemResultScreen item={currentItem} onFinish={handleItemResultFinish} />;
  }

  return (
    <>
      <SInlineDropdownItem
        key={currentItem.id}
        item={currentItem}
        onSelectAnswer={handleSelectAnswer}
        onItemFinished={handleItemFinished}
      />

      <DashedPagination
        itemsNumber={items.length}
        currentIndex={currentIndex}
        taskId={task.id}
        onNextItem={handleItemNext}
        onPrevItem={handleItemPrev}
        isDisabled={isNavigationDisabled}
      />
    </>
  );
};

export default PlayInlineDropdownMainScreen;

const SInlineDropdownItem = styled(InlineDropdownItem)`
  margin-block-start: -40px;

  @media (min-width: ${({ theme }) => theme.linguBptMs}) {
    margin-block-start: -80px;
  }
`;
