import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  IInlineDropdownTask,
  IInlineDropdownTaskSession,
  tInlineDropdownAnswer
} from '@adeptlms/lingu-students-react-shared';
import { usePromptContext } from 'students/views/shared/hooks';
import { t } from 'i18n';
import PlayInlineDropdownMainScreen from './PlayInlineDropdownMainScreen';
import { ISelectAnswerPayload } from './components/InlineDropdownItem';
import bgImage from './assets/inlineDropdownBg.svg';
import { IPlayTaskProps, tPlayTaskComponentType } from '../types';
import { usePlayAnswers, useStreakFactor } from '../../common/hooks';
import PlayTaskScreenSwitcher from '../../common/screens/PlayTaskScreenSwitcher';
import Streak from '../../common/components/Streak';

const PlayInlineDropdownTask: tPlayTaskComponentType<
  IPlayTaskProps<IInlineDropdownTask, IInlineDropdownTaskSession>
> = ({ task, taskSession, isCompleting, onFinish, onAnswer, onNext, className }) => {
  const { items, instruction } = task;
  const answersFn = usePlayAnswers<tInlineDropdownAnswer>();

  const { streakNumber, streakPercent, onTheFirstTry } = useStreakFactor();
  const { makeDirty } = usePromptContext();

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      answersFn(taskSession.taskItemSessions);
    } else {
      answersFn(
        items.map((item, index) => {
          return {
            id: index,
            taskItemId: item.id,
            answers: new Array(item.solution.length).fill(''),
            completed: false,
            mistakesCount: 0,
            skipped: false,
            url: ''
          };
        })
      );
    }

    onTheFirstTry(false);
  }, [taskSession, items, onTheFirstTry, answersFn]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { value, wordIndex, itemId, mistakesCount } = payload;

    const answerIndex = answersFn().findIndex((a) => a.taskItemId === itemId);
    if (answerIndex < 0) return;

    const answer = answersFn()[answerIndex];
    const newItemAnswers = [...answer.answers];
    newItemAnswers.splice(wordIndex, 1, value);
    const newAnswerData = {
      ...answer,
      mistakesCount,
      answers: newItemAnswers,
      skipped: false
    };
    answersFn([
      ...answersFn().slice(0, answerIndex),
      newAnswerData,
      ...answersFn().slice(answerIndex + 1)
    ]);

    onAnswer && onAnswer(newAnswerData);

    onTheFirstTry(!mistakesCount);

    makeDirty(true);
  };

  const handleSkip = (itemId: number) => {
    const answerIndex = answersFn().findIndex((answer) => answer.taskItemId === itemId);

    if (answerIndex < 0) return;

    const answer = answersFn()[answerIndex];
    const newAnswerData = { ...answer, skipped: true };
    answersFn([
      ...answersFn().slice(0, answerIndex),
      newAnswerData,
      ...answersFn().slice(answerIndex + 1)
    ]);

    onAnswer && onAnswer(newAnswerData);
    onTheFirstTry(false);
  };

  const handleFinishTask = () => {
    onNext && onNext();
  };

  const submitTask = useCallback(() => {
    onFinish && onFinish(answersFn());
  }, [onFinish, answersFn]);

  const handleCompleteTask = (goToFinishScreen: () => void) => () => {
    submitTask();

    goToFinishScreen();
  };

  return (
    <>
      <Streak streakNumber={streakNumber} streakPercent={streakPercent} />
      <PlayTaskScreenSwitcher
        isCompleting={isCompleting}
        onExitFinishScreen={handleFinishTask}
        className={className}
        startScreenHeading={
          instruction ||
          t('frontend.lesson_task.tasks.play.inline_dropdown.start_screen.heading')
        }
        itemsExist={!!items.length}
        taskStatus={taskSession?.status}
        completeTask={submitTask}
        taskColor="#0898cb"
      >
        {(goToFinishScreen) => (
          <PlayInlineDropdownMainScreen
            task={task}
            onSelectAnswer={handleSelectAnswer}
            onComplete={handleCompleteTask(goToFinishScreen)}
            onSkip={handleSkip}
          />
        )}
      </PlayTaskScreenSwitcher>
    </>
  );
};

export default PlayInlineDropdownTask;

PlayInlineDropdownTask.backgroundComponent = styled.div`
  background-color: #1a8fb9;
  background-image: linear-gradient(180deg, #004374 0%, #1a8fb9 45.25%, #0898cb 69.98%);

  @media (min-width: ${({ theme }) => theme.linguBptLg}) {
    background-image: url(${bgImage}),
      linear-gradient(180deg, #004374 0%, #1a8fb9 45.25%, #0898cb 69.98%);
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: cover, auto;
  }
`;
