import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { IPhrase } from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import {
  SIconContainer,
  SNotice,
  SWordAudio,
  SWordItem,
  SWordList,
  SWordListContainer,
  SWordOrigin,
  SWordTranslation
} from './styled';

interface IProps {
  words: IPhrase[];
}
const WordList: React.FC<IProps> = ({ words }) => {
  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  const item = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <SWordListContainer>
      <SNotice>
        <Translate
          str="frontend.games.flashcard.keep_practice_to_learn_all_words"
          params={{ count: words.length }}
        />
      </SNotice>
      <SWordList as={motion.div} variants={container} initial="hidden" animate="show">
        {words.map((word) => (
          <SWordItem as={motion.div} variants={item} key={word.id}>
            {word.imageURL || word.animationURL ? (
              <SIconContainer>
                <PhraseIcon
                  imageUrl={word.imageURL}
                  colorRequired
                  animationUrl={word.animationURL}
                  text=""
                  width="40px"
                  height="40px"
                />
              </SIconContainer>
            ) : null}

            <div>
              <SWordOrigin>{word.body}</SWordOrigin>
              <SWordTranslation>{word.wordTranslation}</SWordTranslation>
            </div>
            {word.audioURL && (
              <SWordAudio>
                <SSvgAudioPlayer
                  src={word.audioURL}
                  color="#ffffff40"
                  progressColor="#ffffff"
                />
              </SWordAudio>
            )}
          </SWordItem>
        ))}
      </SWordList>
    </SWordListContainer>
  );
};

export default WordList;

const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`;
