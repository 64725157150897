import React from 'react';
import styled from 'styled-components';
import { Reorder } from 'framer-motion';
import { IArrangeTextItemOption } from '@adeptlms/lingu-students-react-shared';
import { iconCreator } from 'students/views/shared/components/icons/iconCreator';
import { customMediaQuery } from 'students/views/shared/styled';
import dragIconSVG from '../assets/drag-icon.svg';
import { SAnswer, SAnswerBlock, SIndexNumber, SText } from './styled';

const DragIcon = iconCreator(dragIconSVG);

interface IProps {
  option: IArrangeTextItemOption;
  index: number;
}

export const ArrangeTextItem: React.FC<IProps> = ({ option, index }) => {
  return (
    <Reorder.Item
      key={option.id}
      value={option}
      style={{ listStyle: 'none' }}
      whileDrag={{
        rotate: -2
      }}
    >
      <SAnswer>
        <SIndexNumber>{index + 1}</SIndexNumber>
        <SAnswerBlock>
          <SDragIcon width="12px" height="46px" />
          <SText>{option.text}</SText>
        </SAnswerBlock>
      </SAnswer>
    </Reorder.Item>
  );
};

const SDragIcon = styled(DragIcon)`
  cursor: pointer;
  padding: 10px 7px 10px 7px;
  min-width: 9px;
  min-height: 34px;

  ${customMediaQuery('tablet')} {
    padding: 17px 16px 17px 18px;
    min-width: 12px;
    min-height: 46px;
  }
`;
