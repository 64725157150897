import { IPhrase } from '@adeptlms/lingu-students-react-shared';
import { useCallback, useMemo, useRef, useState } from 'react';

import { IArea, IWordPosition } from '../types';

export const useDraggableBlocks = (
  phrases: IPhrase[]
): [(node: HTMLDivElement) => void, DOMRect | null, Array<IWordPosition>] => {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const viewportRef = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  const blocksRectRef = useRef<Array<{ width: number; height: number }>>([]);
  const blockRef = useCallback((node: HTMLDivElement | null, idx: number) => {
    if (node !== null) {
      blocksRectRef.current[idx] = { width: node.offsetWidth, height: node.offsetHeight };
    }
  }, []);

  const wordPositions: Array<IWordPosition> = useMemo(() => {
    const words: Array<IWordPosition> = phrases
      .map((p) => [
        { phrase: p, origin: true },
        { phrase: p, origin: false }
      ])
      .flat()
      .map((a) => {
        return {
          phrase: a.phrase,
          origin: a.origin,
          area: { x: 0, y: 0, width: 0, height: 0, percentX: 0, percentY: 0 },
          ref: blockRef
        };
      });

    if (rect) {
      const { width, height } = rect;
      const filledAreas: IArea[] = [];
      const maxSearchIterations = 1000;
      const calcOverlap = (a1: IArea) => {
        let overlap = 0;
        for (let i = 0; i < filledAreas.length; i += 1) {
          const a2 = filledAreas[i];
          // no intersection cases
          if (
            a1.x + a1.width < a2.x ||
            a2.x + a2.width < a1.x ||
            a1.y + a1.height < a2.y ||
            a2.y + a2.height < a1.y
          ) {
            continue;
          }
          // intersection exists : calculate it !
          const x1 = Math.max(a1.x, a2.x);
          const y1 = Math.max(a1.y, a2.y);
          const x2 = Math.min(a1.x + a1.width, a2.x + a2.width);
          const y2 = Math.min(a1.y + a1.height, a2.y + a2.height);
          const intersection = (x1 - x2) * (y1 - y2);
          overlap += intersection;
        }
        return overlap;
      };
      for (let idx = 0; idx < words.length; idx += 1) {
        let randX = 0;
        let randY = 0;
        let smallestOverlap = Number.MAX_VALUE;
        let bestChoice: IArea | undefined;
        for (let i = 0; i < maxSearchIterations; i += 1) {
          randX = (Math.random() % 1) * 100;
          randY = (Math.random() % 1) * 100;
          const area = {
            x: (randX / 100.0) * width,
            y: (randY / 100.0) * height,
            width: blocksRectRef.current[idx]?.width || 0,
            height: blocksRectRef.current[idx]?.height || 0,
            percentX: randX,
            percentY: randY
          };
          if (area.x + area.width > width || area.y + area.height > height) {
            continue;
          }
          const overlap = calcOverlap(area);
          if (overlap < smallestOverlap) {
            smallestOverlap = overlap;
            bestChoice = area;
          }
          if (overlap === 0) {
            break;
          }
        }
        if (bestChoice) {
          filledAreas.push(bestChoice);
          words[idx].area = bestChoice;
        }
      }
    }
    return words;
  }, [phrases, rect, blockRef]);
  return [viewportRef, rect, wordPositions];
};
