import React, { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { IInlineDropdownItem } from '@adeptlms/lingu-students-react-shared';
import WordSelector from 'students/views/shared/components/WordSelector';
import { withFade2 } from 'students/views/shared/HOCs';

import { customMediaQuery } from 'students/views/shared/styled';
import { useMistakesCount, usePlayAudioPlayer } from '../../../common/hooks';

export interface ISelectAnswerPayload {
  value: string;
  itemId: number;
  wordIndex: number;
  mistakesCount: number;
}

interface IProps {
  item: IInlineDropdownItem;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  onItemFinished: () => void;
  className?: string;
}

const FINISH_ITEM_DELAY = 1000;

const InlineDropdownItem: React.FC<IProps> = ({
  item,
  onSelectAnswer,
  onItemFinished,
  className
}) => {
  const { statement, answers: options, solution } = item;
  const [answers, setAnswers] = useState<string[]>([]);
  const { playCorrectSound, playIncorrectSound } = usePlayAudioPlayer();
  const blocks = statement.split('**');
  const { getMistakesCount, addOneMistake } = useMistakesCount(0, [item.id]);

  function handleSelectAnswer({
    index,
    value,
    correct
  }: {
    index: number;
    value: string;
    correct?: boolean;
  }) {
    playCorrectSound();

    const newAnswers: string[] = [...answers];
    newAnswers[index] = correct ? value : '';
    setAnswers(newAnswers);

    onSelectAnswer({
      value,
      itemId: item.id,
      wordIndex: index,
      mistakesCount: getMistakesCount()
    });

    if (checkItemIsFinished(newAnswers, solution)) {
      setTimeout(onItemFinished, FINISH_ITEM_DELAY);
    }
  }

  function handleSelectWrongAnswer() {
    playIncorrectSound();

    addOneMistake();
  }

  return (
    <SInlineDropdownItem className={cn(className)} dir="ltr">
      <SItemWrapper>
        {blocks.map((block: string, index: number) => (
          <React.Fragment key={`${item.id}_${index}`}>
            {block}
            {index < options.length && (
              <WordSelector
                key={`${item.id}_${index}_btn`}
                index={index}
                onSelectAnswer={handleSelectAnswer}
                onSelectWrongAnswer={handleSelectWrongAnswer}
                solution={solution[index]}
                options={options[index]}
                disabled={!!answers[index]}
              />
            )}
          </React.Fragment>
        ))}
      </SItemWrapper>
    </SInlineDropdownItem>
  );
};

export default withFade2<IProps>()(InlineDropdownItem);

const SInlineDropdownItem = styled.div`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.25rem;

  ${customMediaQuery('tablet')} {
    font-size: 1.5rem;
    line-height: 2.75rem;
    max-width: 670px;
  }
`;

const SItemWrapper = styled.div`
  text-align: center;
`;

function checkItemIsFinished(answers: string[], solutions: string[]) {
  return solutions.every((solution, index) => solution === answers[index]);
}
