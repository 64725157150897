import { IDictationItem } from '@adeptlms/lingu-students-react-shared';
import { useEffect } from 'react';

export function useInit({
  item,
  initInput,
  initItemDurationAnimation,
  runItemAppearAnimation
}: {
  item?: IDictationItem;
  initInput: () => void;
  initItemDurationAnimation: () => void;
  runItemAppearAnimation: () => void;
}): void {
  useEffect(() => {
    if (!item) return;

    initInput();
    runItemAppearAnimation();
    initItemDurationAnimation();
  }, [initInput, initItemDurationAnimation, item, runItemAppearAnimation]);
}
