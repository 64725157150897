import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  IImageHotspotTaskItemSession,
  IImageHotspotTaskSession,
  IPlayImageHotspotTask,
  tImageHotspotAnswer
} from '@adeptlms/lingu-students-react-shared';
import { usePromptContext } from 'students/views/shared/hooks';
import { t } from 'i18n';
import bgImage from './assets/ImageHotspotTaskBg.svg';

import PlayImageHotspotMainScreen from './PlayImageHotspotMainScreen';
import { ISelectAnswerPayload } from './types';
import { IPlayTaskProps, tPlayTaskComponentType } from '../types';
import { usePlayAnswers, useStreakFactor } from '../../common/hooks';
import PlayTaskScreenSwitcher from '../../common/screens/PlayTaskScreenSwitcher';
import Streak from '../../common/components/Streak';

const PlayImageHotspotTask: tPlayTaskComponentType<
  IPlayTaskProps<IPlayImageHotspotTask, IImageHotspotTaskSession>
> = ({ task, taskSession, isCompleting, onFinish, onNext, onAnswer, className }) => {
  const { items } = task;
  const answersFn = usePlayAnswers<tImageHotspotAnswer>();

  const { streakNumber, streakPercent, onTheFirstTry } = useStreakFactor();
  const { makeDirty } = usePromptContext();

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      answersFn(taskSession.taskItemSessions);
    } else {
      answersFn(
        items.map((item, index) => ({
          id: index,
          taskItemId: item.id,
          completed: false,
          mistakesCount: 0,
          skipped: false,
          url: taskSession
            ? `/task_sessions/${taskSession.id}/task_item_sessions/${item.id}`
            : ''
        }))
      );
    }

    onTheFirstTry(false);
  }, [taskSession, items, onTheFirstTry, answersFn]);

  const updateAnswer = (itemId: number, data: Partial<tImageHotspotAnswer[number]>) => {
    const answerIndex = answersFn().findIndex(
      (answer: IImageHotspotTaskItemSession) => answer.taskItemId === itemId
    );

    if (answerIndex < 0) return;

    const { mistakesCount, skipped } = data;

    const answer = answersFn()[answerIndex];
    const newAnswerData = { ...answer, ...data };

    answersFn([
      ...answersFn().slice(0, answerIndex),
      newAnswerData,
      ...answersFn().slice(answerIndex + 1)
    ]);

    onAnswer && onAnswer(newAnswerData);

    onTheFirstTry(!(mistakesCount || skipped));
  };

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { itemId, mistakesCount } = payload;

    updateAnswer(itemId, {
      mistakesCount,
      skipped: false
    });

    makeDirty(true);
  };

  const handleSkip = (itemId: number) => {
    updateAnswer(itemId, {
      skipped: true
    });
  };

  const handleFinishTask = () => {
    onNext && onNext();
  };

  const submitTask = useCallback(() => {
    onFinish && onFinish(answersFn());
  }, [onFinish, answersFn]);

  const handleCompleteTask = (goToFinishScreen: () => void) => () => {
    submitTask();

    goToFinishScreen();
  };

  return (
    <>
      <Streak streakNumber={streakNumber} streakPercent={streakPercent} />
      <PlayTaskScreenSwitcher
        isCompleting={isCompleting}
        onExitFinishScreen={handleFinishTask}
        className={className}
        startScreenHeading={t(
          'frontend.lesson_task.tasks.play.image_hotspot.start_screen.heading'
        )}
        itemsExist={!!items.length}
        taskStatus={taskSession?.status}
        completeTask={submitTask}
        taskColor="#8b69c5"
      >
        {(goToFinishScreen) => (
          <PlayImageHotspotMainScreen
            task={task}
            onSelectAnswer={handleSelectAnswer}
            onComplete={handleCompleteTask(goToFinishScreen)}
            onSkip={handleSkip}
          />
        )}
      </PlayTaskScreenSwitcher>
    </>
  );
};

export default PlayImageHotspotTask;

PlayImageHotspotTask.backgroundComponent = styled.div`
  background-color: #8b69c5;

  @media (min-width: ${({ theme }) => theme.linguBptLg}) {
    background-image: url(${bgImage});
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: cover, auto;
  }
`;
