import styled from 'styled-components';

const STaskTitle = styled.h1`
  font-family: var(--lingu-heading-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-block-end: 0.5rem;
  color: var(--color-black);

  &.extraSpace {
    margin-block-end: 2.375rem;
  }

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    font-size: 1.875rem;
    line-height: 2.3125rem;
    margin-block-end: 1.25rem;

    &.extraSpace {
      margin-block-end: 2rem;
    }
  }
`;

export default STaskTitle;
