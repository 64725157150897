import { IFillSentenceGapItem } from '@adeptlms/lingu-students-react-shared';
import { motion as m, motion, useAnimation } from 'framer-motion';
import React, { FC, useEffect, useRef } from 'react';
import { useDelayedFinishTask } from 'students/views/pages/LessonTasks/TaskSubjects/Play/common/hooks';
import { SvgAudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { useBreakPoint } from 'students/views/shared/hooks';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IProps {
  item: IFillSentenceGapItem;
  onFinish: () => void;
}

const ItemResultScreen: FC<IProps> = ({ item, onFinish }) => {
  const { audioURL } = item;
  const animationControls = useAnimation();
  const iconControls = useAnimation();
  const isMobile = useBreakPoint('md', true);
  const playerRef = useRef(null);
  const handleFinish = useDelayedFinishTask(onFinish, playerRef);
  const blocks = item.statement.split('**');

  useEffect(() => {
    if (!item.audioURL) handleFinish();
  }, [handleFinish, item.audioURL]);

  useEffect(() => {
    animationControls.start({
      opacity: 1,
      y: [-100, 0],
      transition: {
        duration: 0.3
      }
    });
  }, [animationControls]);

  useEffect(() => {
    iconControls.start({
      width: isMobile ? 49 : 70,
      height: isMobile ? 49 : 70,
      opacity: 1,
      y: [-50, 0],
      transition: {
        duration: 0.3,
        ease: 'easeInOut'
      }
    });
  }, [iconControls, isMobile]);

  return (
    <SResultWrapper dir="ltr">
      {audioURL && (
        <SAudioPlayer initial={{ scale: 1.2, opacity: 0 }} animate={iconControls}>
          <SSvgAudioPlayer
            autoplay
            ref={playerRef}
            src={audioURL}
            onEnd={handleFinish}
            color="#ffffff51"
            progressColor="#ffffff"
          />
        </SAudioPlayer>
      )}

      <SSentence initial={{ opacity: 0 }} animate={animationControls}>
        {blocks.map((block: string, index: number) => (
          <React.Fragment key={index}>
            {block}
            {index < item.answers.length && <SAnswerWord>{item.solution[0]}</SAnswerWord>}
          </React.Fragment>
        ))}
      </SSentence>
    </SResultWrapper>
  );
};

export default React.memo(ItemResultScreen);

const SResultWrapper = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${customMediaQuery('tablet')} {
    max-width: 670px;
    width: 100%;
  }
`;

const SSentence = styled(m.h2)`
  color: var(--color-light-white);
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.75rem;
  text-align: center;

  ${customMediaQuery('tablet')} {
    max-width: 670px;
    font-size: 3rem;
    line-height: 3.875rem;
  }
`;

const SAnswerWord = styled.span`
  color: var(--color-light-white);
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.75rem;
  text-align: center;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    height: 3px;
    background: #a92968;
    border-radius: 8px;
  }

  ${customMediaQuery('tablet')} {
    font-size: 3rem;
    line-height: 3.875rem;
  }
`;

const SAudioPlayer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-block-end: 2.25rem;
`;

const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 55px;
  height: 55px;

  ${customMediaQuery('tablet')} {
    width: 66px;
    height: 66px;
  }
`;
