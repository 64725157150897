import { useRef, useCallback, useEffect } from 'react';

const DEFAULT_TIMEOUT = 1500;

const useDelayedFinishTask = (
  onFinish: () => void,
  elementToPreventClickRef?: React.RefObject<HTMLElement | null | undefined>
): (() => void) => {
  const finishTimeout = useRef<number | null>(null);

  const handleFinish = useCallback(() => {
    if (!finishTimeout.current) {
      finishTimeout.current = window.setTimeout(() => {
        onFinish();
      }, DEFAULT_TIMEOUT);
    }
  }, [onFinish]);

  const handleFinishOnClick = useCallback(
    (event: Event) => {
      if (
        elementToPreventClickRef !== undefined &&
        elementToPreventClickRef.current &&
        elementToPreventClickRef.current.contains(event.target as Node)
      ) {
        return;
      }
      if (finishTimeout.current) {
        clearTimeout(finishTimeout.current);
      }
      onFinish();
    },
    [elementToPreventClickRef, onFinish]
  );

  useEffect(() => {
    document.addEventListener('click', handleFinishOnClick);

    return () => {
      document.removeEventListener('click', handleFinishOnClick);
    };
  }, [handleFinishOnClick]);

  useEffect(() => {
    return () => {
      finishTimeout.current && clearTimeout(finishTimeout.current);
    };
  }, []);

  return handleFinish;
};

export default useDelayedFinishTask;
