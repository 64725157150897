import React, { useEffect, useState } from 'react';
import {
  ICheckTrueFalseItem,
  ICheckTrueFalseTask,
  ITrueFalseTaskItemSession,
  ITrueFalseTaskSession,
  tTrueFalseAnswer
} from '@adeptlms/lingu-students-react-shared';
import { usePromptContext } from 'students/views/shared/hooks';

import StatementItem, { ISelectAnswerPayload } from './StatementItem';
import { ICheckTaskProps } from '../types';
import { BaseCheckTaskBody, CheckNavFooter, SCheckTaskWrapper } from '../../components';
import { useTaskItems } from '../../useTaskItems';
import { isLessonCompleted } from '../../helpers';

const CheckTrueFalseTask: React.FC<
  ICheckTaskProps<ICheckTrueFalseTask, ITrueFalseTaskSession>
> = ({ task, taskSession, isCompleting, onNext }) => {
  const { makeDirty } = usePromptContext();
  const { items } = task;
  const [answers, setAnswers] = useState<tTrueFalseAnswer>([]);
  const { taskItems, sessionTaskItems } = useTaskItems<
    ICheckTrueFalseItem,
    ITrueFalseTaskItemSession
  >(items, taskSession?.taskItemSessions);

  useEffect(() => {
    if (sessionTaskItems.length) {
      setAnswers(sessionTaskItems);
    } else {
      setAnswers(
        taskItems.map((item, index) => {
          return {
            completed: false,
            id: index,
            taskItemId: item.id,
            answer: null,
            mistakesCount: 0,
            skipped: false,
            url: ''
          };
        })
      );
    }
  }, [sessionTaskItems, taskItems]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { value, itemId } = payload;

    const answerIndex = answers.findIndex((answer) => answer.taskItemId === itemId);
    if (answerIndex < 0) return;

    const answer = answers[answerIndex];

    setAnswers([
      ...answers.slice(0, answerIndex),
      {
        ...answer,
        answer: value,
        skipped: false
      },
      ...answers.slice(answerIndex + 1)
    ]);

    makeDirty(true);
  };

  const getItemAnswer = (id: number): boolean | null => {
    const answer = answers.filter((answer) => answer.taskItemId === id)[0];

    return answer ? answer.answer : null;
  };

  const handleNext = () => {
    onNext(answers);
  };

  const handleSkip = () => {
    onNext(
      answers.map((answer: ITrueFalseTaskItemSession) => ({
        ...answer,
        answer: null,
        skipped: true
      }))
    );
  };

  if (!answers.length) return null;

  return (
    <>
      <SCheckTaskWrapper>
        <BaseCheckTaskBody task={task}>
          {taskItems.map((item) => (
            <StatementItem
              key={item.id}
              options={{
                itemId: item.id,
                statement: item.statement,
                status: getItemAnswer(item.id)
              }}
              onSelectAnswer={handleSelectAnswer}
            />
          ))}
        </BaseCheckTaskBody>
      </SCheckTaskWrapper>

      <CheckNavFooter
        isCompleting={isCompleting}
        onNext={handleNext}
        onSkip={handleSkip}
        isLessonCompleted={isLessonCompleted(taskSession)}
      />
    </>
  );
};

export default CheckTrueFalseTask;
