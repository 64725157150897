import React from 'react';
import MatchWordBlock from './MatchWordBlock';
import MatchWordHint from '../MatchWordHint';
import { SViewport } from '../styled';
import { IMatchWordViewport, useMatchWordViewport } from '../hooks/useMatchWordViewport';

const MatchWordViewport: React.FC<IMatchWordViewport> = ({
  phrases,
  onRoundComplete,
  onComplete
}) => {
  const {
    viewportRef,
    wordPositions,
    hoveringIndex,
    getCorrectClassName,
    onDrag,
    onDragStart,
    onDragEnd,
    onBlockAnimationComplete,
    hintMoveX,
    hintMoveY,
    wordsRefs,
    hintVisible,
    hintAreas
  } = useMatchWordViewport({ phrases, onRoundComplete, onComplete });

  return (
    <SViewport ref={viewportRef}>
      {wordPositions.map((word, index) => {
        return (
          <MatchWordBlock
            key={index}
            isHovering={hoveringIndex === index}
            correctClassName={getCorrectClassName(index)}
            sequence={index}
            word={word}
            onItemDrag={onDrag}
            onItemDragStart={onDragStart}
            onItemDragEnd={onDragEnd}
            onItemAnimationComplete={onBlockAnimationComplete}
            hintMoveX={hintMoveX}
            hintMoveY={hintMoveY}
            ref={wordsRefs[index]}
          />
        );
      })}
      {hintVisible && <MatchWordHint area1={hintAreas[0]} area2={hintAreas[1]} />}
    </SViewport>
  );
};

export default MatchWordViewport;
