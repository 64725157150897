import { useShowStreak } from './useShowStreak';
import { useStreakAnimation } from './useStreakAnimation';

type tReturnedObject = { show: boolean } & ReturnType<typeof useStreakAnimation>;

export const useStreak = (
  streakNumber: number,
  minStreakNumber: number
): tReturnedObject => {
  const { show, handleHide } = useShowStreak(streakNumber, minStreakNumber);
  const streakAnimation = useStreakAnimation(streakNumber, show, handleHide);

  return {
    show,
    ...streakAnimation
  };
};
