import { IDictationItem } from '@adeptlms/lingu-students-react-shared';
import { AnimationControls } from 'framer-motion';
import { FocusEvent, useCallback, useEffect } from 'react';
import { ISelectAnswerPayload } from '../types';
import { useMistakesCount } from '../../../../../common/hooks';
import { useHasChangesAfterLastCheck } from './useHasChangesAfterLastCheck';
import { CLEAN_SPECIAL_REG_EXP } from '../constants';
import { useItemTime } from './useItemTime';

type tFn = () => void;

export function useItemFlow({
  playCorrectSound,
  item,
  currentAnswerInput,
  goToWrongAnswerScreen,
  madeMistake,
  onSelectAnswer,
  validation,
  hideHintButtonsBlock,
  hideAndResetHint,
  runItemDisappearAnimation,
  timeoutBlockControls
}: {
  playCorrectSound: tFn;
  item: IDictationItem;
  currentAnswerInput: string;
  goToWrongAnswerScreen: (payload: ISelectAnswerPayload) => void;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  madeMistake: tFn;
  validation: {
    caseSensitive: boolean;
    checkPunctuation: boolean;
  };
  hideHintButtonsBlock: tFn;
  hideAndResetHint: tFn;
  runItemDisappearAnimation: tFn;
  timeoutBlockControls: AnimationControls;
}): {
  handleOnAnswer: tFn;
  handleWrongAnswer: tFn;
  handleBlur: (e: FocusEvent) => void;
  handleEnterKeyPress: tFn;
  handleStopAudio: tFn;
  increaseMistakes: tFn;
  initItemDurationAnimation: tFn;
  isItemFinished: boolean;
} {
  const checkCorrectness = useCallback(
    (isFullCheck = false) => {
      const { caseSensitive, checkPunctuation } = validation;
      return compareSentences(
        currentAnswerInput,
        item.sentence,
        caseSensitive,
        checkPunctuation,
        isFullCheck
      );
    },
    [currentAnswerInput, item.sentence, validation]
  );
  const { markChecked, isCheckNeeded } = useHasChangesAfterLastCheck();

  const { getMistakesCount, addOneMistake, resetMistakes } = useMistakesCount(0, []);

  const handleWrongAnswer = () => {
    goToWrongAnswerScreen({
      itemId: item.id,
      mistakesCount: getMistakesCount(),
      answer: currentAnswerInput
    });
  };

  const { initItemDurationAnimation, handleStopAudio, stopTimer } = useItemTime({
    item,
    timeoutBlockControls,
    onItemTimeIsUp: handleWrongAnswer
  });

  const handleOnAnswer = useCallback(() => {
    onSelectAnswer({
      itemId: item.id,
      mistakesCount: getMistakesCount(),
      answer: currentAnswerInput
    });

    resetMistakes();
    hideHintButtonsBlock();
  }, [
    currentAnswerInput,
    getMistakesCount,
    hideHintButtonsBlock,
    item.id,
    onSelectAnswer,
    resetMistakes
  ]);

  const checkAnswer = (isFullCheck = false) => {
    hideAndResetHint();
    markChecked(currentAnswerInput);
    if (!checkCorrectness(isFullCheck)) {
      addOneMistake();
      madeMistake();
    }
  };

  const handleEnterKeyPress = () => {
    return checkAnswer(true);
  };

  const handleBlur = (e: FocusEvent) => {
    if (
      currentAnswerInput.length &&
      isCheckNeeded(currentAnswerInput) &&
      !e.relatedTarget?.className?.includes('character-btn')
    ) {
      checkAnswer();
    }
  };

  // react on answer changing and finish item successfully if it's correct.
  useEffect(() => {
    if (checkCorrectness(true)) {
      stopTimer();

      playCorrectSound();

      // Stopping timeout background animation if sentence is correct
      timeoutBlockControls.stop();

      const headerAnimationTimer = window.setTimeout(() => {
        runItemDisappearAnimation();
      }, 1000);

      const onSelectAnswerTimer = window.setTimeout(handleOnAnswer, 2500);

      return () => {
        clearTimeout(headerAnimationTimer);
        clearTimeout(onSelectAnswerTimer);
      };
    }
    return () => null;
    /* eslint-disable-next-line */
  }, [currentAnswerInput]);

  return {
    handleOnAnswer,
    handleWrongAnswer,
    handleBlur,
    handleEnterKeyPress,
    increaseMistakes: addOneMistake,
    initItemDurationAnimation,
    handleStopAudio,
    isItemFinished: compareSentences(
      currentAnswerInput,
      item.sentence,
      validation.caseSensitive,
      validation.checkPunctuation,
      true
    )
  };
}

export function compareSentences(
  input: string,
  sentence: string,
  caseSensitive?: boolean,
  checkPunctuation?: boolean,
  fullMatch?: boolean
): boolean {
  let workingInput = input.trim();
  let cleanSentenceWithNoExtraSpaces = sentence.trim();

  if (!caseSensitive) {
    workingInput = workingInput.toLowerCase();
    cleanSentenceWithNoExtraSpaces = cleanSentenceWithNoExtraSpaces.toLowerCase();
  }

  if (!checkPunctuation) {
    workingInput = workingInput.replace(CLEAN_SPECIAL_REG_EXP, '');
    cleanSentenceWithNoExtraSpaces = cleanSentenceWithNoExtraSpaces.replace(
      CLEAN_SPECIAL_REG_EXP,
      ''
    );
  }

  return fullMatch
    ? cleanSentenceWithNoExtraSpaces === workingInput
    : cleanSentenceWithNoExtraSpaces.startsWith(workingInput);
}
