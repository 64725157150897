export const dictationAnimateScenarios = [
  {
    name: 'header-appear',
    animate: {
      y: [50, 0],
      opacity: [0, 1],
      transition: {
        opacity: { duration: 0.7 },
        y: { duration: 0.7, delay: 1, ease: 'easeInOut' }
      }
    }
  },
  {
    name: 'header-disappear',
    animate: {
      y: [50, 0],
      opacity: [0, 1],
      transition: {
        opacity: { duration: 0.7 },
        y: { duration: 0.7, delay: 1, ease: 'easeInOut' }
      }
    }
  },
  {
    name: 'content-appear',
    animate: {
      y: [50, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.7,
        delay: 1,
        ease: 'easeInOut'
      }
    }
  },

  {
    name: 'content-disappear',
    animate: {
      opacity: [1, 0],
      y: [0, -50],
      transition: {
        opacity: { duration: 0.7 },
        y: { duration: 0.7, ease: 'easeInOut' }
      }
    }
  }
];

export const contentVariants = {
  initial: {
    opacity: 0,
    y: 50
  },
  appear: {
    y: [50, 0],
    opacity: [0, 1],
    transition: {
      duration: 0.7,
      delay: 1,
      ease: 'easeInOut'
    }
  },
  disappear: {
    opacity: [1, 0],
    y: [0, -50],
    transition: {
      opacity: { duration: 0.7 },
      y: { duration: 0.7, ease: 'easeInOut' }
    }
  }
};

export const hintButtonsScenarios = [
  {
    name: 'appear',
    animate: {
      y: [30, 0],
      opacity: [0, 1],
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: 'easeInOut'
      }
    }
  },
  {
    name: 'disappear',
    animate: {
      opacity: [1, 0],
      y: [0, -30],
      transition: {
        opacity: { duration: 0.5 },
        y: { duration: 0.5, ease: 'easeInOut' }
      }
    }
  }
];
