import {
  tAnswer,
  tCheckTaskType,
  useCheckTaskActions
} from '@adeptlms/lingu-students-react-shared';
import { FC, useMemo } from 'react';
import {
  DEFAULT_LESSON_BACKGROUND,
  getBackgroundColorIfLoaded
} from 'students/views/pages/Lessons/Lesson/LessonPage/helpers';
import Page from 'students/views/pages/Page';
import bgLeft from 'students/views/shared/assets/bgLightBubbles/bgLightBubbleLeft.svg';
import bgRight from 'students/views/shared/assets/bgLightBubbles/bgLightBubbleRight.svg';
import useHeartBeatPing from 'students/views/shared/components/HeartBeat/useHeartBeatPing';
import Prompt from 'students/views/shared/components/Prompt';
import { useExitPrompt, usePromptContext } from 'students/views/shared/hooks';
import styled from 'styled-components';

import { SpinnerBlock } from 'students/views/shared/components/Spinner';
import LessonTaskTitle from '../../LessonTask/LessonTaskTitle';
import NotSupportedTask from '../../NotSupportedTask';
import { tSubjectComponentProps } from '../types';
import { checkTaskMap } from './Tasks';

export interface ICheckSubjectComponentProps extends tSubjectComponentProps {
  task: tCheckTaskType;
}

const CheckTaskContainer: FC<ICheckSubjectComponentProps> = ({
  lesson,
  task,
  taskSession,
  isLoading,
  onFinishAndNext,
  isCompleting
}) => {
  const TaskComponent = checkTaskMap.get(task.type);

  const { gradient, background } = getBackgroundColorIfLoaded(
    lesson?.color || null,
    !!lesson
  );

  const pingAction = useHeartBeatPing();
  const { isDirty, makeDirty } = usePromptContext();

  const { onNext, onSkip } = useCheckTaskActions({
    lessonTaskSession: taskSession ?? null,
    isCompleting: isCompleting ?? false,
    onFinishAndNext(answers?: tAnswer): Promise<void> {
      makeDirty(false);

      return onFinishAndNext(answers) as Promise<void>;
    }
  });

  useExitPrompt(isDirty, pingAction);

  const taskBody = useMemo(() => {
    let currentTaskBody: JSX.Element | null;
    if (isLoading) {
      currentTaskBody = (
        <SSpinnerWrapper>
          <SpinnerBlock />
        </SSpinnerWrapper>
      );
    } else {
      currentTaskBody = TaskComponent ? (
        <TaskComponent
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          task={task}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          taskSession={taskSession}
          isCompleting={!!isCompleting}
          onNext={onNext}
          onSkip={onSkip}
        />
      ) : (
        <NotSupportedTask
          onNext={onSkip}
          color={lesson?.color || DEFAULT_LESSON_BACKGROUND}
          isCompleting={!!isCompleting}
        />
      );
    }

    return currentTaskBody;
  }, [
    TaskComponent,
    onNext,
    onSkip,
    isCompleting,
    isLoading,
    lesson?.color,
    task,
    taskSession
  ]);

  return (
    <SPage
      background={background}
      gradient={gradient}
      bgImage={`url(${bgLeft}), url(${bgRight})`}
    >
      <LessonTaskTitle title={lesson?.title} />

      {taskBody}

      <Prompt />
    </SPage>
  );
};

export default CheckTaskContainer;

const SPage = styled(Page)`
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    justify-content: normal;
  }
`;

const SSpinnerWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  place-content: center;
`;
