import React, { useMemo, FC } from 'react';
import styled from 'styled-components';
import {
  useLearnTaskActions,
  tAnswer,
  tLearnTaskType
} from '@adeptlms/lingu-students-react-shared';
import Page from 'students/views/pages/Page';
import {
  getBackgroundColorIfLoaded,
  DEFAULT_LESSON_BACKGROUND
} from 'students/views/pages/Lessons/Lesson/LessonPage/helpers';
import { isLightFontNeeded } from 'students/views/shared/helpers';
import bgRight from 'students/views/shared/assets/bgLightBubbles/bgLightBubbleRight.svg';
import bgLeft from 'students/views/shared/assets/bgLightBubbles/bgLightBubbleLeft.svg';
import { SpinnerBlock } from 'students/views/shared/components/Spinner';

import { learnTaskMap } from './Tasks';
import { tSubjectComponentProps } from '../types';
import NotSupportedTask from '../../NotSupportedTask';
import LessonTaskTitle from '../../LessonTask/LessonTaskTitle';

export interface ILearnSubjectComponentProps extends tSubjectComponentProps {
  task: tLearnTaskType;
}

const LearnTaskContainer: FC<ILearnSubjectComponentProps> = ({
  lesson,
  task,
  taskSession,
  isLoading,
  onFinishAndNext,
  isCompleting
}) => {
  const { onFinish } = useLearnTaskActions({
    lessonTaskSession: taskSession ?? null,
    isCompleting: isCompleting ?? false,
    onFinishAndNext(answers?: tAnswer): Promise<void> {
      return onFinishAndNext(answers) as Promise<void>;
    }
  });

  const { gradient, background } = getBackgroundColorIfLoaded(
    lesson?.color || null,
    !!lesson
  );
  const isLightFont = isLightFontNeeded(background);
  const TaskComponent = learnTaskMap.get(task.type);

  const taskBody = useMemo(() => {
    let currentTaskBody: JSX.Element | null = null;
    if (isLoading) {
      currentTaskBody = (
        <SSpinnerWrapper>
          <SpinnerBlock />
        </SSpinnerWrapper>
      );
    } else {
      currentTaskBody = TaskComponent ? (
        <TaskComponent
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          task={task}
          onFinish={onFinish}
          lightFont={isLightFont}
          isCompleting={!!isCompleting}
        />
      ) : (
        <NotSupportedTask
          onNext={onFinish}
          color={lesson?.color || DEFAULT_LESSON_BACKGROUND}
          isCompleting={!!isCompleting}
        />
      );
    }

    return currentTaskBody;
  }, [
    isCompleting,
    isLoading,
    TaskComponent,
    task,
    isLightFont,
    onFinish,
    lesson?.color
  ]);

  return (
    <Page
      background={background}
      gradient={gradient}
      bgImage={`url(${bgLeft}), url(${bgRight})`}
    >
      <LessonTaskTitle title={lesson?.title} />
      {taskBody}
    </Page>
  );
};

export default LearnTaskContainer;

const SSpinnerWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  place-content: center;
`;
