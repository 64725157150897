import { IDictationItem } from '@adeptlms/lingu-students-react-shared';
import { AnimationControls } from 'framer-motion';
import { useRef, useState, useCallback, useEffect } from 'react';
import {
  DEFAULT_TASK_TIMEOUT,
  TASK_TIMEOUT_MULTIPLIER,
  TASK_MINIMAL_TIMEOUT
} from '../constants';

export function useItemTime({
  item,
  timeoutBlockControls,
  onItemTimeIsUp
}: {
  item: IDictationItem;
  timeoutBlockControls: AnimationControls;
  onItemTimeIsUp: () => void;
}): {
  initItemDurationAnimation: () => void;
  handleStopAudio: () => void;
  stopTimer: () => void;
} {
  const itemDurationRef = useRef<number>(DEFAULT_TASK_TIMEOUT);
  const taskTimeout = useRef<number | null>(null);
  const startTime = useRef<number | null>(null);
  const [audioHasPlayed, setAudioHasPlayed] = useState(false);

  const startItemTimer = useCallback(() => {
    if (!taskTimeout.current) {
      taskTimeout.current = window.setTimeout(() => {
        onItemTimeIsUp();
      }, itemDurationRef.current);

      startTime.current = new Date().getTime();
    }
  }, [onItemTimeIsUp]);

  const stopItemTimer = useCallback(() => {
    taskTimeout.current && clearTimeout(taskTimeout.current);
    taskTimeout.current = null;
  }, []);

  useEffect(() => {
    return () => {
      stopItemTimer();
    };
  }, [stopItemTimer]);

  useEffect(() => {
    function onDocumentVisibilityChange() {
      const currentProgress = new Date().getTime() - (startTime.current ?? 0);
      const currentProgressPercent = currentProgress / itemDurationRef.current;

      if (document.hidden) {
        timeoutBlockControls.stop();
      } else {
        timeoutBlockControls.start({
          clipPath: [
            `ellipse(150% 100% at 50% -${100 - currentProgressPercent * 100}%)`,
            'ellipse(150% 100% at 50% 10%)'
          ],
          transition: {
            duration: (itemDurationRef.current - currentProgress) / 1000,
            ease: 'linear'
          }
        });
      }
    }

    document.addEventListener('visibilitychange', onDocumentVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
    };
  }, [timeoutBlockControls]);

  useEffect(() => {
    if (item.duration) {
      itemDurationRef.current = item.duration * 1000;
    } else {
      const audio = new Audio();

      audio.addEventListener('loadedmetadata', () => {
        itemDurationRef.current =
          audio.duration * 1000 * TASK_TIMEOUT_MULTIPLIER + TASK_MINIMAL_TIMEOUT;
      });

      audio.preload = 'metadata';
      audio.src = item.audioURL;
    }
  }, [item]);

  const startItemDurationTimerAnimation = useCallback(() => {
    timeoutBlockControls.start({
      clipPath: 'ellipse(150% 100% at 50% 10%)',
      transition: {
        duration: itemDurationRef.current / 1000,
        ease: 'linear'
      }
    });
  }, [timeoutBlockControls]);

  const initItemDurationAnimation = useCallback(() => {
    timeoutBlockControls.set({
      clipPath: 'ellipse(150% 100% at 50% -100%)'
    });
  }, [timeoutBlockControls]);

  const handleStopAudio = useCallback(() => {
    setAudioHasPlayed(true);
    startItemDurationTimerAnimation();
  }, [startItemDurationTimerAnimation]);

  // set timer for task
  useEffect(() => {
    if (audioHasPlayed && !taskTimeout.current) {
      startItemTimer();
    }
  }, [audioHasPlayed, startItemTimer]);

  return { initItemDurationAnimation, handleStopAudio, stopTimer: stopItemTimer };
}
