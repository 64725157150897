import { IWordBlockRef } from '../../types';
import { ANSWER_BLOCK_ADDITIONAL_HEIGHT } from '../../utils';

export const answerBlockVariants = {
  initPosition: {
    x: 0,
    y: 0
  },

  visible: {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
    transition: { duration: 0.3, delay: 0.5 }
  },

  incorrectDragged: {
    x: 0,
    y: 0,
    transition: { duration: 0.5 }
  },

  incorrectDraggedTo: {
    rotate: [0, 5, -5, 0],
    x: [0, 10, -10, 0]
  }
};

const FINISHED_ITEM_PADDING = 5;

export const itemBlockVariants = {
  initPosition: {
    x: 0,
    y: 0,
    alignItems: 'center'
  },

  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.5
    }
  },

  correct: ({
    destinationRef
  }: {
    destinationRef: IWordBlockRef;
  }): Record<string, any> => {
    const destinationRect = destinationRef.node.getBoundingClientRect();

    return {
      position: 'fixed',
      pointerEvents: 'none',
      left: destinationRect.left + FINISHED_ITEM_PADDING,
      top: destinationRect.top + FINISHED_ITEM_PADDING,
      x: 0,
      y: 0,
      width: destinationRect.width - FINISHED_ITEM_PADDING * 2,
      height:
        destinationRect.height -
        FINISHED_ITEM_PADDING * 2 -
        ANSWER_BLOCK_ADDITIONAL_HEIGHT,
      transition: {
        bounce: 0,
        duration: 0.3,
        left: {
          duration: 0
        },
        top: {
          duration: 0
        }
      }
    };
  },

  hoverOnAnswersBlock: ({
    destinationRef
  }: {
    destinationRef: IWordBlockRef;
  }): Record<string, any> => {
    const { width, height } = destinationRef.node.getBoundingClientRect();

    return {
      width: width - FINISHED_ITEM_PADDING * 2,
      height: height - FINISHED_ITEM_PADDING * 2 - ANSWER_BLOCK_ADDITIONAL_HEIGHT,
      opacity: 0.8,
      transition: {
        duration: 0.4
      }
    };
  },

  restoreSize: ({ currentRef }: { currentRef: IWordBlockRef }): Record<string, any> => ({
    width: currentRef.width + 1,
    height: currentRef.height + 1,
    opacity: 1
  }),

  disappear: {
    opacity: [1, 0],
    transition: {
      duration: 0.4,
      delay: 0.6
    }
  },

  incorrectDragged: {
    x: 0,
    y: 0,
    transition: {
      duration: 0.4
    }
  },

  incorrectDraggedTo: {
    rotate: [0, 4, -4, 0],
    x: [0, 10, -10, 0]
  },

  hint: ({
    getHintMove,
    destinationRef,
    currentRef
  }: {
    getHintMove: () => { x: number; y: number };
    destinationRef: IWordBlockRef;
    currentRef: IWordBlockRef;
  }): Record<string, any> => {
    const { x, y } = getHintMove();
    const currentRect = currentRef.node.getBoundingClientRect();
    const destinationRect = destinationRef.node.getBoundingClientRect();
    const shouldAlignItemsAtTop = currentRef.node.getElementsByTagName('span').length;

    return {
      x: [0, 0, x + FINISHED_ITEM_PADDING, x + FINISHED_ITEM_PADDING, 0],
      y: [0, 0, y + FINISHED_ITEM_PADDING, y + FINISHED_ITEM_PADDING, 0],
      width: [
        currentRect.width,
        currentRect.width,
        destinationRect.width - FINISHED_ITEM_PADDING * 2,
        destinationRect.width - FINISHED_ITEM_PADDING * 2,
        currentRect.width
      ],
      height: [
        currentRect.height,
        currentRect.height,
        destinationRect.height -
          FINISHED_ITEM_PADDING * 2 -
          ANSWER_BLOCK_ADDITIONAL_HEIGHT,
        destinationRect.height -
          FINISHED_ITEM_PADDING * 2 -
          ANSWER_BLOCK_ADDITIONAL_HEIGHT,
        currentRect.height
      ],
      alignItems: shouldAlignItemsAtTop ? 'flex-start' : 'center',
      opacity: [1, 1, 0.8, 0.8, 1],
      transition: { duration: 5, times: [0, 0.6, 0.8, 0.9, 1] }
    };
  }
};
