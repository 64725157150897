import cn from 'classnames';
import { PanInfo, TapInfo, motion, useAnimation } from 'framer-motion';
import React, { useCallback, useImperativeHandle, useMemo } from 'react';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import { SSvgAudioPlayer, SWordContainer } from '../styled';
import { IWordPosition } from '../types';

const variants = {
  initPosition: {
    x: 0,
    y: 0
  },
  visible: {
    x: 0,
    y: 0,
    scale: 1,
    opacity: 1,
    transition: { duration: 0.3, delay: 0.5 + Math.random() * (1 - 0.5) }
  },
  correct: {
    opacity: 0,
    clipPath: [`circle(75%)`, `circle(0%)`],
    transition: { duration: 1, ease: 'easeInOut' },
    transitionEnd: { display: 'none' }
  },
  incorrectDragged: {
    x: 0,
    y: 0,
    transition: { duration: 0.5 }
  },
  incorrectDraggedTo: {
    rotate: [0, 5, -5, 0],
    x: [0, 10, -10, 0]
  },
  hint: ({ hintMoveX, hintMoveY }: { hintMoveX: number; hintMoveY: number }) => ({
    x: [0, 0, hintMoveX, hintMoveX, 0],
    y: [0, 0, hintMoveY, hintMoveY, 0],
    transition: { duration: 5, times: [0, 0.6, 0.8, 0.9, 1] }
  })
};

export type tVariantName =
  | 'initPosition'
  | 'visible'
  | 'incorrectDragged'
  | 'correct'
  | 'incorrectDraggedTo'
  | 'hint';

interface IProps {
  isHovering: boolean;
  correctClassName: 'correct' | 'incorrect' | '';
  sequence: number;
  word: IWordPosition;
  onItemDrag: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    index: number
  ) => void;
  onItemDragStart: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    index: number
  ) => void;
  onItemDragEnd: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    index: number
  ) => void;
  onItemTap?: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: TapInfo,
    index: number
  ) => void;
  onItemAnimationComplete: (index: number) => void;
  hintMoveX?: number;
  hintMoveY?: number;
}

export interface IMatchWordBlockRef {
  triggerAnimation: (variantName: tVariantName) => Promise<void>;
  cancelAnimation: () => Promise<void>;
}

const MatchWordBlock = React.forwardRef<IMatchWordBlockRef, IProps>(
  (
    {
      isHovering,
      correctClassName,
      sequence,
      word,
      onItemDrag,
      onItemDragStart,
      onItemDragEnd,
      onItemTap,
      onItemAnimationComplete,
      hintMoveX,
      hintMoveY
    },
    forwardedRef
  ) => {
    const animationControls = useAnimation();

    useImperativeHandle(
      forwardedRef,
      () => ({
        triggerAnimation: (variantName: tVariantName) =>
          animationControls.start(variantName),
        cancelAnimation: () => animationControls.start('initPosition')
      }),
      [animationControls]
    );

    const handleDrag = useCallback(
      (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
        onItemDrag(e, i, sequence);
      },
      [onItemDrag, sequence]
    );
    const handleDragStart = useCallback(
      (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
        onItemDragStart(e, i, sequence);
      },
      [onItemDragStart, sequence]
    );
    const handleDragEnd = useCallback(
      (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
        onItemDragEnd(e, i, sequence);
      },
      [onItemDragEnd, sequence]
    );
    const handleTap = useCallback(
      (e: MouseEvent | TouchEvent | PointerEvent, i: TapInfo) => {
        onItemTap?.(e, i, sequence);
      },
      [onItemTap, sequence]
    );
    const handleAnimationComplete = useCallback(() => {
      if (correctClassName) onItemAnimationComplete(sequence);
    }, [correctClassName, onItemAnimationComplete, sequence]);

    const blockType = useMemo(() => {
      if (!word.origin) {
        if (word.phrase.audioURL) {
          return 'audio';
        } else if (word.phrase.imageURL || word.phrase.animationURL) {
          return 'picture';
        }
      }
      return 'word';
    }, [
      word.origin,
      word.phrase.animationURL,
      word.phrase.audioURL,
      word.phrase.imageURL
    ]);

    const renderWordBlock = useCallback(() => {
      if (word.origin) {
        return (
          <div>
            {word.phrase.audioURL ? (
              <SSvgAudioPlayer
                src={word.phrase.audioURL}
                color="rgba(45, 45, 58, 0.7)"
                progressColor="#2D2D3A"
              />
            ) : (
              <span>{word.phrase.body}</span>
            )}
          </div>
        );
      }

      return (
        <div>
          {word.phrase.imageURL || word.phrase.animationURL ? (
            <PhraseIcon
              imageUrl={word.phrase.imageURL}
              colorRequired
              animationUrl={word.phrase.animationURL}
              text=""
              width="100px"
              height="100px"
            />
          ) : null}

          <p className="ln-transition">{word.phrase.wordTranslation}</p>
        </div>
      );
    }, [word]);

    return (
      <SWordContainer
        as={motion.div}
        drag
        dragMomentum={false}
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onTap={handleTap}
        ref={(el: HTMLDivElement) => word.ref(el, sequence)}
        initial={{ y: 10, scale: 0.8, opacity: 0 }}
        animate={animationControls}
        variants={variants}
        custom={{ hintMoveX, hintMoveY }}
        onAnimationComplete={handleAnimationComplete}
        data-type={blockType}
        className={cn({
          wordOrigin: word.origin,
          hovering: isHovering,
          correct: correctClassName === 'correct',
          incorrect: correctClassName === 'incorrect'
        })}
        style={{
          left: `${word.area.percentX}%`,
          top: `${word.area.percentY}%`,
          opacity: 1
        }}
        whileTap={{
          zIndex: 20,
          opacity: 0.5,
          boxShadow:
            '0px 0px 20px rgba(9, 53, 54, 0.5), 0px 6px 0px rgba(23, 105, 108, 0.5)'
        }}
      >
        {renderWordBlock()}
      </SWordContainer>
    );
  }
);

export default MatchWordBlock;
