import { useCallback, useEffect, useRef } from 'react';

import { useHintVisibility } from '../utils';

const IDLE_MATCH_TIMEOUT = 8000;

const useArrangeItemsHint = (): [boolean, () => void, (needed: boolean) => void] => {
  const needsHintRef = useRef(true);

  const [hintVisible, updateLastInteractionTime, setHintVisibility] = useHintVisibility(
    needsHintRef.current,
    IDLE_MATCH_TIMEOUT
  );

  useEffect(() => {
    if (hintVisible) {
      needsHintRef.current = false;
    }
  }, [hintVisible]);

  const markInteracted = useCallback(() => {
    updateLastInteractionTime(IDLE_MATCH_TIMEOUT);
  }, [updateLastInteractionTime]);

  const setHintNeeded = (needed: boolean) => {
    needsHintRef.current = needed;
    setHintVisibility(needed);
  };

  return [needsHintRef.current && hintVisible, markInteracted, setHintNeeded];
};

export default useArrangeItemsHint;
