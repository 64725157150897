import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { LottieOptions } from 'lottie-react';
import LottieIcon from 'students/views/shared/components/LottieIcon';

interface IProps {
  animationData: LottieOptions['animationData'];
  loop?: LottieOptions['loop'];
  width?: CSSProperties['width'];
  top: CSSProperties['top'];
  left: CSSProperties['left'];
  zIndex?: CSSProperties['zIndex'];
}

const HandHint: React.FC<IProps> = ({
  animationData,
  loop = false,
  width = '6rem',
  top,
  left,
  zIndex
}) => (
  <SHintWrapper style={{ top, left, zIndex }}>
    <LottieIcon
      width={width}
      loop={loop}
      autoPlay
      animationJSONData={animationData}
      destroyOnComplete
    />
  </SHintWrapper>
);

export default HandHint;

const SHintWrapper = styled.div`
  position: absolute;
`;
