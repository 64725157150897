import { useState, useEffect } from 'react';
import {
  IBaseTaskItemSession,
  ILessonTaskItemBase
} from '@adeptlms/lingu-students-react-shared';
import { cleanSessionItemsByTaskItems, filterItemsBySessionItems } from './helpers';

interface IReturnedObject<TaskItem, SessionItem> {
  taskItems: TaskItem[];
  sessionTaskItems: SessionItem[];
}

export function useTaskItems<
  TaskItem extends ILessonTaskItemBase,
  SessionItem extends IBaseTaskItemSession
>(
  taskItems: TaskItem[],
  sessionTaskItems?: SessionItem[]
): IReturnedObject<TaskItem, SessionItem> {
  const [filteredTaskItems, setFilteredTaskItems] = useState<TaskItem[]>([]);
  const [filteredSessionTaskItems, setFilteredSessionTaskItems] = useState<SessionItem[]>(
    []
  );

  useEffect(() => {
    if (sessionTaskItems) {
      setFilteredTaskItems(
        filterItemsBySessionItems<TaskItem, SessionItem>(taskItems, sessionTaskItems)
      );
    }
  }, [taskItems, sessionTaskItems]);

  useEffect(() => {
    if (sessionTaskItems) {
      setFilteredSessionTaskItems(
        cleanSessionItemsByTaskItems<TaskItem, SessionItem>(
          filteredTaskItems,
          sessionTaskItems
        )
      );
    }
  }, [filteredTaskItems, sessionTaskItems]);

  return {
    // we should use taskItems in case we don't have session at all (preview mode) and each task will generate its own local session
    taskItems: filteredTaskItems.length ? filteredTaskItems : taskItems,
    sessionTaskItems: filteredSessionTaskItems
  };
}
