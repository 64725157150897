import { ForwardedRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { PanInfo, TapInfo, useAnimation, AnimationControls } from 'framer-motion';

import { IItemBlock, IItemBlockRef, tAnimationVariantName } from '../types';

type tItemBlockEvent = MouseEvent | TouchEvent | PointerEvent;

type tTransformProperties = Parameters<AnimationControls['start']>['0'];

export interface IItemBlockProps {
  sequence: number;
  itemBlock: IItemBlock;
  onItemDrag: (event: tItemBlockEvent, info: PanInfo, index: number) => void;
  onItemDragStart: (event: tItemBlockEvent, info: PanInfo, index: number) => void;
  onItemDragEnd: (event: tItemBlockEvent, info: PanInfo, index: number) => void;
  onItemTap?: (event: tItemBlockEvent, info: TapInfo, index: number) => void;
  onItemAnimationComplete: (index: number) => void;
  getHintMove: () => {
    x: number;
    y: number;
  };
}
interface IReturnedValue {
  animationControls: any;
  isDragging: boolean;
  handleDrag: (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => void;
  handleDragStart: (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => void;
  handleDragEnd: (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => void;
  handleTap: (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => void;
  handleAnimationComplete: () => void;
  blockType: string;
}

function useItemBlock({
  forwardedRef,
  onItemDrag,
  sequence,
  onItemTap,
  onItemDragEnd,
  onItemDragStart,
  onItemAnimationComplete,
  itemBlock
}: Omit<IItemBlockProps, 'getHintMove'> & {
  forwardedRef: ForwardedRef<IItemBlockRef>;
}): IReturnedValue {
  const animationControls = useAnimation();

  const [isDragging, setIsDragging] = useState<boolean>(false);

  useImperativeHandle(
    forwardedRef,
    () => ({
      triggerAnimation: (variantName: tAnimationVariantName | tTransformProperties) =>
        animationControls.start(variantName),
      setAnimation: (variantName: tAnimationVariantName | tTransformProperties) =>
        animationControls.set(variantName),
      cancelAnimation: () => animationControls.start('initPosition')
    }),
    [animationControls]
  );

  const handleDrag = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
      onItemDrag(e, i, sequence);
    },
    [onItemDrag, sequence]
  );

  const handleDragStart = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
      onItemDragStart(e, i, sequence);
      setIsDragging(true);
    },
    [onItemDragStart, sequence]
  );

  const handleDragEnd = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, i: PanInfo) => {
      onItemDragEnd(e, i, sequence);
      setIsDragging(false);
    },
    [onItemDragEnd, sequence]
  );

  const handleTap = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, i: TapInfo) => {
      onItemTap?.(e, i, sequence);
    },
    [onItemTap, sequence]
  );

  const handleAnimationComplete = useCallback(() => {
    onItemAnimationComplete(sequence);
  }, [onItemAnimationComplete, sequence]);

  const blockType = useMemo(() => {
    if (itemBlock.option.audio) {
      return 'audio';
    } else if (itemBlock.option.image) {
      return 'picture';
    }

    return 'word';
  }, [itemBlock]);

  return {
    animationControls,
    isDragging,
    handleDrag,
    handleDragStart,
    handleDragEnd,
    handleTap,
    handleAnimationComplete,
    blockType
  };
}

export default useItemBlock;
