import React, { FC, ReactElement, useCallback, useEffect, useRef } from 'react';

interface ITextSelector {
  children: ReactElement;
  onTextSelect: (
    text: string,
    { clientX, clientY }: { clientX: number; clientY: number },
    anchorNode: Node | null
  ) => void;
}

const TextSelector: FC<ITextSelector> = ({ children, onTextSelect }) => {
  const blockWrapperRef = useRef<HTMLDivElement | null>(null);

  const handleTextSelect = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const selection = window.getSelection();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (selection && selection?.toString().trim().length) {
        let clientX = 0;
        let clientY = 0;

        if (e instanceof MouseEvent) {
          clientX = e.clientX;
          clientY = e.clientY + scrollTop;
        } else if (e.changedTouches.length) {
          clientX = e.changedTouches[0].clientX;
          clientY = e.changedTouches[0].clientY + scrollTop;
        }

        onTextSelect(
          selection.toString().trim(),
          {
            clientX,
            clientY
          },
          selection.anchorNode
        );
      }
    },
    [onTextSelect]
  );

  useEffect(() => {
    const ref = blockWrapperRef.current;
    if (ref) {
      ref.addEventListener('mouseup', handleTextSelect);
      ref.addEventListener('touchend', handleTextSelect);
      ref.addEventListener('touchcancel', handleTextSelect);
    }

    return () => {
      ref?.removeEventListener('mouseup', handleTextSelect);
      ref?.removeEventListener('touchend', handleTextSelect);
      ref?.removeEventListener('touchcancel', handleTextSelect);
    };
  }, [blockWrapperRef, handleTextSelect]);

  return <div ref={blockWrapperRef}>{children}</div>;
};

export default TextSelector;
