import React, { useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { IGameRoundAnswer } from '@adeptlms/lingu-students-react-shared';
import { t } from 'i18n';
import { audioItems } from 'students/views/pages/Games/MatchWord/assets/audioItems';
import useMobileVhHack from 'students/views/shared/hooks/useMobileVhHack';
import DashedPagination from 'students/views/shared/components/DashedPagination';

import { SContainer, SPaginationContainer, SShade } from './styled';
import { IMatchWordRound, IWordAnswer } from './types';
import TimeoutScreen from './screens/TimeoutScreen';
import SuccessScreen from './screens/SuccessScreen';
import { useGameAudioPlayer } from '../common/helpers';
import GameStartScreen from '../common/GameStartScreen';
import MatchWordViewport from './MatchWordViewport';

type tGameStage = 'start' | 'inGame' | 'timeout' | 'finish';

interface IProps {
  taskId: number;
  rounds: IMatchWordRound[];
  difficulty?: 'easy' | 'medium' | 'hard';
  onRoundComplete?: (answer: IGameRoundAnswer) => void;
  onFinish?: () => void;
  className?: string;
}

const MatchWord: React.FC<IProps> = ({
  taskId,
  rounds,
  difficulty = 'easy',
  onRoundComplete = () => {},
  onFinish = () => {},
  className
}) => {
  useGameAudioPlayer(audioItems);
  useMobileVhHack();

  const [round, setRound] = useState(0);
  const { phrases } = rounds[round];
  const [gameStage, setGameStage] = useState<tGameStage>('start');
  const duration: { [key: string]: number } = {
    easy: phrases.length * 12,
    medium: phrases.length * 10,
    hard: phrases.length * 8
  };

  const handleRoundComplete = (answer: IWordAnswer) => {
    onRoundComplete({
      word: answer.word,
      solved: answer.solved
    });
  };

  const handleNextRound = () => {
    if (round + 1 < rounds.length) {
      setRound(round + 1);
    } else setGameStage('finish');
  };

  const handlePrevRound = () => {
    if (round > 0) {
      setRound(round - 1);
    }
  };

  const onShadeFallDown = () => {
    setGameStage('timeout');
  };

  const restartRound = () => {
    setGameStage('inGame');
  };

  return (
    <SContainer className={cn(className, { justifyCenter: gameStage !== 'inGame' })}>
      {gameStage === 'start' && (
        <GameStartScreen
          heading={t('frontend.games.match_word.start_heading')}
          onStart={() => setGameStage('inGame')}
        />
      )}
      {gameStage === 'inGame' && (
        <>
          <SShade
            as={motion.div}
            key={`${round}-timeout`}
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            transition={{ duration: duration[difficulty] }}
            onAnimationComplete={onShadeFallDown}
          />
          <MatchWordViewport
            key={round}
            phrases={phrases}
            onRoundComplete={handleRoundComplete}
            onComplete={handleNextRound}
          />
          <SPaginationContainer>
            <DashedPagination
              itemsNumber={rounds.length}
              currentIndex={round}
              taskId={taskId}
              onPrevItem={handlePrevRound}
              onNextItem={handleNextRound}
            />
          </SPaginationContainer>
        </>
      )}
      {gameStage === 'timeout' && <TimeoutScreen onNextClick={restartRound} />}
      {gameStage === 'finish' && <SuccessScreen onNextClick={onFinish} />}
    </SContainer>
  );
};

export default MatchWord;
