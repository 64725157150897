import { useCallback, useContext, useEffect } from 'react';
import { PreferencesContext } from 'students/views/shared/providers/Preferences';
import { AudioTags } from '../models';

const useAudioPreferences = (
  addTagToMute: (tag: string) => void,
  removeTagFromMute: (tag: string) => void,
  ignorePreferences: boolean,
  onBgMusicToggle: (turnOn: boolean) => void
): void => {
  const { musicPlayback, soundEffects } = useContext(PreferencesContext);

  const updateTag = useCallback(
    (tagName: AudioTags, condition: boolean) => {
      if (!ignorePreferences) {
        if (condition) {
          removeTagFromMute(tagName);
        } else {
          addTagToMute(tagName);
        }
      }
    },
    [addTagToMute, ignorePreferences, removeTagFromMute]
  );

  useEffect(() => {
    updateTag(AudioTags.SoundEffects, soundEffects);
  }, [soundEffects, updateTag]);

  useEffect(() => {
    updateTag(AudioTags.MusicPlayback, musicPlayback);
  }, [musicPlayback, updateTag]);

  useEffect(() => {
    onBgMusicToggle(musicPlayback);
  }, [onBgMusicToggle, musicPlayback]);
};

export default useAudioPreferences;
