import { motion as m, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import styled from 'styled-components';

interface IProps {
  startAnimation: boolean;
  onFinish?: () => void;
  solution: string;
  translation: string;
}

const ItemResult: React.FC<IProps> = ({
  startAnimation,
  onFinish,
  solution,
  translation
}) => {
  const animationControls = useAnimation();

  useEffect(() => {
    animationControls.start({
      opacity: 1,
      transition: {
        duration: 0.5
      }
    });
  }, [animationControls]);

  useEffect(() => {
    async function startFinishAnimation() {
      await animationControls.start({
        y: [0, 350],
        opacity: [1, 0],
        transition: {
          opacity: {
            duration: 0.6
          },
          y: {
            duration: 0.6,
            ease: 'easeInOut'
          }
        }
      });

      onFinish && onFinish();
    }

    if (startAnimation) {
      startFinishAnimation();
    }
  }, [startAnimation, animationControls, onFinish]);

  return (
    <m.div initial={{ opacity: 0 }} animate={animationControls}>
      <SResultWord dir="ltr">{solution}</SResultWord>

      <SResultTranslation>{translation}</SResultTranslation>
    </m.div>
  );
};

const SResultWord = styled.h1`
  color: var(--lingu-font-light-color);
  text-align: center;
`;

const SResultTranslation = styled.p`
  color: var(--lingu-font-light-color);
  text-align: center;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin: 24px 0 0;
`;

export default ItemResult;
