import { ILearnVideoTask, lessonSelectors } from '@adeptlms/lingu-students-react-shared';
import { motion as m } from 'framer-motion';
import { Translate } from 'i18n';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import { VideoContent } from 'students/views/shared/components/MediaPlayer/VideoPlayer';
import ScrollToBottomButton, {
  tScrollToBottomButtonRef
} from 'students/views/shared/components/ScrollToBottomButton';
import TranslationTextTooltip from 'students/views/shared/components/TranslationTextTooltip';
import { htmlStringHasContent } from 'students/views/shared/helpers';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { FinishTaskButton } from '../../components';
import { ILearnTaskProps } from '../types';
import { useNextBtnAnimation } from './hooks';

const LearnVideoTask: React.FC<ILearnTaskProps<ILearnVideoTask>> = ({
  task,
  onFinish,
  lightFont,
  isCompleting
}) => {
  const {
    title,
    items: [{ caption, url }]
  } = task;

  const finishTaskButtonRef = useRef<HTMLButtonElement>(null);
  const scrollButtonRef = useRef<tScrollToBottomButtonRef>(null);
  const [nextBtnControls, onResizeEnd] = useNextBtnAnimation();
  const updateShowButtonTimeout = useRef<number | null>(null);
  const currentLesson = useTypedSelector(lessonSelectors.selectLesson);

  const handleResizeEnd = useCallback(() => {
    onResizeEnd();

    updateShowButtonTimeout.current = window.setTimeout(() => {
      scrollButtonRef?.current?.update();
    }, 500);
  }, [onResizeEnd]);

  useEffect(() => {
    return () => {
      updateShowButtonTimeout.current && clearTimeout(updateShowButtonTimeout.current);
    };
  }, []);

  const captionHasText = useMemo(() => htmlStringHasContent(caption), [caption]);

  const renderCaptionText = useCallback(() => {
    if (!captionHasText) {
      return null;
    }

    if (currentLesson?.languageCode === 'nb' || currentLesson?.languageCode === 'no') {
      return (
        <STextContentBlock dir="ltr">
          <STranscriptionText>
            <Translate str="frontend.lesson_task.tasks.learn.transcription" />
          </STranscriptionText>

          <TranslationTextTooltip>
            <p dangerouslySetInnerHTML={{ __html: caption }} />
          </TranslationTextTooltip>
        </STextContentBlock>
      );
    }

    return (
      <STextContentBlock>
        <p dangerouslySetInnerHTML={{ __html: caption }} />
      </STextContentBlock>
    );
  }, [currentLesson, caption, captionHasText]);

  return (
    <SVideoTaskWrapper>
      <STitle light={lightFont} dir="ltr">
        {title}
      </STitle>

      <VideoContent videoURL={url} onResizeEnd={handleResizeEnd} />

      <SContentWrapper>
        {renderCaptionText()}

        <SButtonBlock initial={{ opacity: 0 }} animate={nextBtnControls}>
          <SFinishTaskButton
            onClick={onFinish}
            isCompleting={isCompleting}
            ref={finishTaskButtonRef}
          />
        </SButtonBlock>

        <ScrollToBottomButton ref={scrollButtonRef} targetRef={finishTaskButtonRef} />
      </SContentWrapper>
    </SVideoTaskWrapper>
  );
};

export default LearnVideoTask;

const SVideoTaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  ${customMediaQuery('tablet')} {
    max-width: 921px;
    align-self: center;
    width: 100%;
  }
`;

const STitle = styled.h1<{ light: boolean }>`
  color: ${({ light }) =>
    light ? 'var(--lingu-font-light-color)' : 'var(--lingu-font-dark-color)'};
  font-size: 1.5rem;
  font-weight: bold;
  margin-block-end: 1.625rem;
  text-align: start;

  ${customMediaQuery('tablet')} {
    font-size: 1.75rem;
    text-align: center;
  }
`;

const STextContentBlock = styled.div`
  background: var(--color-white);
  padding: 1.875rem 1rem 1rem;
  width: 100%;
  letter-spacing: -0.02em;
  overflow-x: auto;

  ${customMediaQuery('tablet')} {
    padding: 2.7rem 3.125rem 2.25rem;
    border-radius: 20px;
    min-height: max-content;
    letter-spacing: -0.01em;
  }
`;

const SContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem -1rem -1rem;
  flex-grow: 1;
  background: var(--color-white);
  padding-bottom: 2rem;

  ${customMediaQuery('tablet')} {
    margin: 3.75rem 0 0;
    background: transparent;
    padding-bottom: 0;
  }
`;

const SButtonBlock = styled(m.div)`
  margin: 0;
  padding: 32px 5%;
  width: 100%;
  background: var(--color-white);

  ${customMediaQuery('tablet')} {
    width: 340px;
    padding: 40px 0;
    background: transparent;
  }
`;

const SFinishTaskButton = styled(FinishTaskButton)`
  margin: 0;
`;

const STranscriptionText = styled.p`
  color: var(--heading-text-color);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  opacity: 0.7;
  margin: 0 0 1rem;

  ${customMediaQuery('tablet')} {
    font-size: 1rem;
    line-height: 1.25;
    margin: 0 0 2rem;
  }
`;
