import {
  ICheckFillInBlanksItem,
  ICheckFillInBlanksTask,
  IFillInBlanksTaskItemSession,
  IFillInBlanksTaskSession,
  tFillInBlanksAnswer
} from '@adeptlms/lingu-students-react-shared';
import React, { useEffect, useState } from 'react';
import { usePromptContext } from 'students/views/shared/hooks';
import { BaseCheckTaskBody, CheckNavFooter, SCheckTaskWrapper } from '../../components';
import { isLessonCompleted } from '../../helpers';
import { useTaskItems } from '../../useTaskItems';
import { ICheckTaskProps } from '../types';
import SentenceItem, { ISelectAnswerPayload } from './SentenceItem';

const CheckFillInBlanksTask: React.FC<
  ICheckTaskProps<ICheckFillInBlanksTask, IFillInBlanksTaskSession>
> = ({ task, taskSession, isCompleting, onNext }) => {
  const { items } = task;
  const { makeDirty } = usePromptContext();

  const [answers, setAnswers] = useState<tFillInBlanksAnswer>([]);
  const { taskItems, sessionTaskItems } = useTaskItems<
    ICheckFillInBlanksItem,
    IFillInBlanksTaskItemSession
  >(items, taskSession?.taskItemSessions);

  useEffect(() => {
    if (sessionTaskItems.length) {
      const orderedAnswers = structuredClone(sessionTaskItems);
      orderedAnswers.forEach((answer: IFillInBlanksTaskItemSession, index: number) => {
        if (answer.answers.length !== items[index].solution.length) {
          answer.answers = new Array(items[index].solution.length).fill('');
        }
      });
      setAnswers(orderedAnswers);
    } else {
      setAnswers(
        taskItems.map((item, index) => {
          return {
            completed: false,
            id: index,
            taskItemId: item.id,
            answers: new Array(item.solution.length).fill(''),
            mistakesCount: 0,
            skipped: false,
            url: ''
          };
        })
      );
    }
  }, [taskItems, sessionTaskItems, items]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { value, itemId, wordIndex } = payload;

    const itemIndex = answers.findIndex((a) => a.taskItemId === itemId);
    if (itemIndex < 0) return;

    const item = answers[itemIndex];
    const newItemAnswers = [...item.answers];

    newItemAnswers.splice(wordIndex, 1, value);

    setAnswers([
      ...answers.slice(0, itemIndex),
      {
        ...item,
        answers: newItemAnswers,
        skipped: false
      },
      ...answers.slice(itemIndex + 1)
    ]);

    makeDirty(true);
  };

  const handleNext = () => {
    // Before submitting - trim all answers
    const trimmedAnswers = answers.map((answer) => ({
      ...answer,
      answers: [...answer.answers.map((oneAnswer) => oneAnswer.trim())]
    }));

    onNext(trimmedAnswers);
  };

  const handleSkip = () => {
    onNext(
      answers.map((answer: IFillInBlanksTaskItemSession) => ({
        ...answer,
        answers: [],
        skipped: true
      }))
    );
  };

  if (!answers.length) return null;

  return (
    <>
      <SCheckTaskWrapper>
        <BaseCheckTaskBody task={task}>
          {taskItems.map((item, itemIndex) => (
            <SentenceItem
              key={item.id}
              options={{
                id: item.id,
                sentence: item.question
              }}
              value={answers[itemIndex]?.answers}
              onSelectAnswer={handleSelectAnswer}
            />
          ))}
        </BaseCheckTaskBody>
      </SCheckTaskWrapper>

      <CheckNavFooter
        isCompleting={isCompleting}
        onNext={handleNext}
        onSkip={handleSkip}
        isLessonCompleted={isLessonCompleted(taskSession)}
      />
    </>
  );
};

export default CheckFillInBlanksTask;
