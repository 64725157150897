export const startButtonScenarios = [
  {
    name: 'disappear',
    animate: {
      width: 200,
      height: 200,
      opacity: [1, 0],
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      },
      transitionEnd: { display: 'none' }
    }
  },
  {
    name: 'appear',
    animate: {
      width: 100,
      height: 100,
      transition: {
        duration: 0.6,
        ease: 'easeInOut'
      }
    }
  }
];
export const startScreenHeadingScenarios = [
  {
    name: 'disappear',
    animate: {
      y: [0, 80],
      opacity: [1, 0],
      transition: {
        duration: 0.6,
        ease: 'easeOut'
      }
    }
  }
];
