import {
  IProfile,
  lessonSelectors,
  lessonTaskActions,
  lessonTaskSelectors,
  tLeaderboardTable
} from '@adeptlms/lingu-students-react-shared';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import bubbleAnimationManager from 'students/views/shared/bundles/bubbleAnimationManager';
import { getLessonPathFromUrl } from 'students/views/shared/helpers';

interface IReceivedObject {
  onFinish: () => void;
  onRestart: () => void;
}

interface IReturnedObject {
  handleFinish: (e: MouseEvent<HTMLElement>) => void;
  leaderboard: tLeaderboardTable;
  taskSessionXP: number;
  currentUserProfile: IProfile | null;
  handleRestartTask: () => void;
  handleGoToPrevTask: () => void;
}

function useFinishScreen({ onFinish, onRestart }: IReceivedObject): IReturnedObject {
  const leaderboard = useTypedSelector(
    lessonTaskSelectors.selectLessonTaskSessionLeaderboard
  );
  const taskSession = useTypedSelector(lessonTaskSelectors.selectLessonTaskSession);
  const currentLesson = useTypedSelector(lessonSelectors.selectLesson);
  const userProfile = useTypedSelector(userSelectors.selectProfile);
  const dispatch = useTypedDispatch();
  const history = useHistory();

  const handleFinish = (e: MouseEvent<HTMLElement>) => {
    const viewportOffset = e.currentTarget.getBoundingClientRect();
    bubbleAnimationManager.finishButtonPosition = {
      left: viewportOffset.left,
      top: viewportOffset.top,
      width: e.currentTarget.offsetWidth,
      height: e.currentTarget.offsetHeight
    };
    onFinish();
  };

  const handleRestartTask = async () => {
    if (taskSession) {
      await dispatch(
        lessonTaskActions.restartLessonTaskSession({
          taskSessionId: taskSession.id,
          lessonSessionId: taskSession.lessonSession.id
        })
      );
    }

    onRestart();
  };

  const handleGoToPrevTask = () => {
    if (taskSession) {
      const tasksList =
        currentLesson?.subjects?.reduce((acc, curr) => {
          curr.tasks.forEach((task) => acc.push(task));
          return acc;
        }, [] as any) ?? [];

      const currentTaskIndex = tasksList.findIndex(
        (task: any) => task.id === taskSession?.taskId
      );
      const prevTask =
        tasksList[(currentTaskIndex - 1 + tasksList.length) % tasksList.length];

      history.replace(
        `${getLessonPathFromUrl(window.location.pathname)}/tasks/${prevTask.id}`
      );
    }
  };

  return {
    handleFinish,
    leaderboard: leaderboard || [],
    taskSessionXP: taskSession?.xp || 0,
    currentUserProfile: userProfile,
    handleRestartTask,
    handleGoToPrevTask
  };
}

export default useFinishScreen;
