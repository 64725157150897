import { useCallback, useEffect, useRef } from 'react';

interface IReturnedObject {
  getMistakesCount: () => number;
  addOneMistake: () => void;
  resetMistakes: () => void;
}

export default function useMistakesCount(
  initialValue: number,
  resetRefs: any[]
): IReturnedObject {
  const attemptsCountRef = useRef<number>(initialValue);

  const getMistakesCount = () => attemptsCountRef.current;

  const addOneMistake = useCallback(() => {
    attemptsCountRef.current += 1;
  }, []);

  const resetMistakes = useCallback(() => {
    attemptsCountRef.current = initialValue;
  }, [initialValue]);

  useEffect(() => {
    resetMistakes();
    /* eslint-disable-next-line */
  }, [...resetRefs, resetMistakes]);

  return {
    getMistakesCount,
    addOneMistake,
    resetMistakes
  };
}
