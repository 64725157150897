import React, { useImperativeHandle } from 'react';
import { AnimatePresence, motion as m } from 'framer-motion';
import styled from 'styled-components';
import rightArrowIcon from 'students/views/shared/assets/icons/arrow_right_icon.svg';
import CircleButton from '../CircleButton';
import { customMediaQuery } from '../../styled';
import { IOptions, useScrollToTarget } from './hooks';
import { styleInnerButton } from '../ButtonGeneral/ButtonGeneral';

export type tScrollToBottomButtonRef = {
  update: () => void;
};

const ScrollToBottomButton = React.forwardRef<tScrollToBottomButtonRef, IOptions>(
  ({ targetRef }, forwardedRef) => {
    const { showButton, handleClick, updateScrollCheck } = useScrollToTarget({
      targetRef
    });

    useImperativeHandle(forwardedRef, () => ({
      update() {
        updateScrollCheck();
      }
    }));

    return (
      <AnimatePresence>
        {showButton ? (
          <m.div exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <SScrollToBottomButton
              onClick={handleClick}
              iconUrl={rightArrowIcon}
              iconColor="#00466F"
            />
          </m.div>
        ) : null}
      </AnimatePresence>
    );
  }
);

const SScrollToBottomButton = styled(CircleButton)`
  position: fixed;
  width: 2.75rem;
  height: 2.75rem;
  background: #d7df21;
  box-shadow: 0px 4px 10px rgba(13, 97, 120, 0.2);
  bottom: 1rem;
  right: 1rem;
  margin: 0;
  ${styleInnerButton()} {
    transform: rotate(90deg);
  }

  ${customMediaQuery('tablet')} {
    right: 50%;
    transform: translateX(50%);
    height: 3rem;
    width: 4.5rem;
    border-radius: 80px;

    ${styleInnerButton()} {
      transform: rotate(90deg);
    }
  }
`;

export default ScrollToBottomButton;
