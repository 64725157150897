import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

export const SItem = styled.div`
  margin-block-end: 25px;

  &:last-child {
    margin-block-end: 0;
  }
`;

export const SDescription = styled.p`
  font-size: 1rem;
  color: var(--color-black);
  letter-spacing: -0.02em;
  line-height: 1.5rem;
  margin-block-end: 27px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 22px;
    font-size: 1.375rem;
    line-height: 2.25rem;
  }
`;

export const SAnswers = styled.ul`
  padding: 0;
`;

export const SAnswer = styled.div`
  font-size: 1rem;
  background: #eeeef6;
  border-radius: 12px;
  padding: 4px;
  margin-block-end: 4px;
  display: flex;
  align-items: center;

  &.isCorrect,
  &.isIncorrect {
    border-radius: 10px;
  }

  &.isCorrect {
    border: 2px solid #39b54a;
    background: rgba(247, 255, 230, 0.8);
  }

  &.isIncorrect {
    background: rgba(255, 230, 230, 0.8);
    border: 2px solid #ff5858;
  }

  ${customMediaQuery('tablet')} {
    padding: 8px;
    margin-block-end: 12px;
    font-size: 1.375rem;
    line-height: 2rem;
  }
`;

export const SIndexNumber = styled.div`
  flex-shrink: 0;
  flex-wrap: nowrap;
  font-family: var(--lingu-font-family);
  padding-inline-start: 11px;
  padding-inline-end: 11px;

  ${customMediaQuery('tablet')} {
    padding-inline-start: 19px;
    padding-inline-end: 23px;
  }
`;

export const SAnswerBlock = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex: 1;
  padding-inline-start: 10px;
  cursor: pointer;

  &.isDragging {
    z-index: 999;
    cursor: grabbing;
  }
`;

export const SText = styled.p`
  color: var(--color-black);
  letter-spacing: -0.01em;
  margin: 0;
  padding: 10px 7px 10px 7px;

  ${customMediaQuery('tablet')} {
    padding: 17px 16px 17px 18px;
  }
`;
