import {
  IAssignmentTask,
  IAssignmentTaskItemSession,
  IAssignmentTaskSession,
  IChatMessage,
  consentsActions,
  useAssignmentTask
} from '@adeptlms/lingu-students-react-shared';
import { FC, useEffect, useRef, useState } from 'react';
import {
  AssignmentLicenseSelector,
  AssignmentNoLicenseToAccept,
  AssignmentTag,
  Messenger
} from 'students/views/shared/bundles/assignments';
import { AcceptButtonsBlock } from 'students/views/shared/bundles/assignments/components/Messenger/components';
import { NextButton } from 'students/views/shared/components/Buttons';
import { usePromptContext } from 'students/views/shared/hooks';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { Translate } from 'i18n';
import { useTypedDispatch, useTypedSelector } from 'students/stores/_utils';
import { userSelectors } from 'students/stores/user';
import { tMessengerRef } from 'students/views/shared/bundles/assignments/components/Messenger/Messenger';
import LinkElem from 'students/views/shared/components/LinkElem';
import CheckboxItem from 'students/views/shared/components/checkbox/CheckboxItem';
import { ICheckTaskProps } from '../types';

const CheckAssignmentTask: FC<
  ICheckTaskProps<IAssignmentTask, IAssignmentTaskSession>
> = ({ task, isCompleting, taskSession, onNext }) => {
  const [hiddenFooter, setHiddenFooter] = useState(false);
  const [acceptedAssignment, setAcceptedAssignment] = useState(false);
  const [consentAgreed, setConsentAgreed] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const profile = useTypedSelector(userSelectors.selectProfile);

  const {
    assignmentData,
    status,
    actions,
    canSubmit,
    canAcceptAssignmentTask,
    licensesCorrectionInfo,
    canChangeLicense,
    studentCanAddNewAnswer,
    lastStudentMessage
  } = useAssignmentTask({ task });

  const { fetchAssignment } = actions;

  const { makeDirty } = usePromptContext();
  const messengerRef = useRef<tMessengerRef>(null);
  useEffect(() => {
    if (status === 'initial') {
      fetchAssignment();
      makeDirty(false);
    }
  }, [fetchAssignment, status, makeDirty]);

  const handleSkip = () => {
    onNext(
      taskSession?.taskItemSessions.map((item: IAssignmentTaskItemSession) => ({
        ...item,
        skipped: true
      })) || []
    );
  };

  const renderNextButtonBlock = () =>
    assignmentData?.assignmentId || status === 'submit_failed' ? (
      <SNextButton
        size="90px"
        className="ln-m-0"
        onClick={handleSkip}
        disabled={isCompleting}
        iconColor="#00A5D7"
      />
    ) : null;

  if (!assignmentData) {
    return null;
  }

  const showAcceptButtons =
    assignmentData.messages.filter((message: IChatMessage) => message.from === 'outgoing')
      .length === 0 && assignmentData.status === 'pending';

  const handleOnConsentClick = async () => {
    setConsentAgreed((prev) => !prev);
    await dispatch(consentsActions.sendResearchConsent({ consentGiven: !consentAgreed }));
  };

  const renderAcceptButtonsBlock = () => {
    if (hiddenFooter) {
      return null;
    }

    return (
      <SFooterAcceptButtonsBlock>
        {canAcceptAssignmentTask &&
        (profile?.consents?.research === undefined ||
          profile?.consents?.research == null) ? (
          <SConsentBlock>
            <SCheckboxItem
              option={{
                title: (
                  <span>
                    <Translate str="frontend.auth.i_allow_to_use_my_data" />{' '}
                    <SPrivacyPolicyLink
                      target="_blank"
                      to="https://lingu.no"
                      rel="noreferrer"
                    >
                      <Translate str="frontend.course.privacy_policy" />
                    </SPrivacyPolicyLink>{' '}
                    <Translate str="frontend.auth.for_details" />
                  </span>
                ),
                value: '1'
              }}
              checked={consentAgreed}
              onClick={handleOnConsentClick}
            />
          </SConsentBlock>
        ) : null}

        {showAcceptButtons && !acceptedAssignment ? (
          <AcceptButtonsBlock
            onSkip={handleSkip}
            onAccept={
              !canAcceptAssignmentTask
                ? undefined
                : () => {
                    setAcceptedAssignment(true);
                    setHiddenFooter(true);
                    messengerRef.current?.openReply();
                  }
            }
          />
        ) : null}
      </SFooterAcceptButtonsBlock>
    );
  };

  const showLicenseSelector =
    licensesCorrectionInfo?.currentLicense &&
    canChangeLicense &&
    licensesCorrectionInfo?.changeCurrentLicense;

  return (
    <STaskWrapper>
      <SAssignmentTag type={assignmentData.inputType} />

      {canChangeLicense && showLicenseSelector ? (
        <AssignmentLicenseSelector
          licensesSubmitFor={licensesCorrectionInfo.licensesSubmitFor}
          currentLicense={licensesCorrectionInfo.currentLicense}
          changeCurrentLicense={licensesCorrectionInfo.changeCurrentLicense}
        />
      ) : null}

      {!canAcceptAssignmentTask ? <AssignmentNoLicenseToAccept /> : null}

      <Messenger
        assignment={assignmentData}
        actions={actions}
        status={status}
        ref={messengerRef}
        submitDisabled={!canSubmit}
        studentCanAddNewAnswer={studentCanAddNewAnswer}
        lastStudentMessage={lastStudentMessage}
        initialShowReplyBlock={false}
        onReplyActivityChange={(active) => {
          if (!active && !lastStudentMessage) {
            setAcceptedAssignment(false);
            setHiddenFooter(false);
          }
        }}
        footer={
          <>
            {renderAcceptButtonsBlock()}
            {renderNextButtonBlock()}
          </>
        }
        variant="light"
      />
    </STaskWrapper>
  );
};

export default CheckAssignmentTask;

const STaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${customMediaQuery('tablet')} {
    margin: 0 0 30px;
    align-items: center;
    align-self: center;
    max-width: 664px;
  }
`;
const SNextButton = styled(NextButton)`
  margin: 80px auto 10px !important;

  ${customMediaQuery('tablet')} {
    margin: 40px auto -60px !important;
  }
`;

const SAssignmentTag = styled(AssignmentTag)`
  width: max-content;
  margin: 0 auto 28px;
`;

const SFooterAcceptButtonsBlock = styled.div`
  margin-block-start: 40px;
`;

const SConsentBlock = styled.div`
  margin-block-end: 15px;
  padding: 20px;
  border-radius: 16px;
  background: var(--color-white);
`;

const SCheckboxItem = styled(CheckboxItem)`
  align-items: flex-start;

  & > .checkbox-item-label {
    color: var(--color-black);
  }

  & .checkbox_item-checkbox {
    width: 35px;
    height: 25px;
    border-color: var(--sub-text-color);
  }
`;

const SPrivacyPolicyLink = styled(LinkElem)`
  color: var(--color-black);
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: var(--color-black);
  }
`;
