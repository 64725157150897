import {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { PanInfo } from 'framer-motion';

import useDraggableBlocks from './useDraggableBlocks';
import useViewportDragHandlers from './useViewportDragHandlers';
import useViewportHint from './useViewportHint';
import {
  IArea,
  IItemBlock,
  IItemBlockRef,
  IArrangeItemsViewport,
  IItemMatch
} from '../types';

interface IReturnType {
  viewportRef: (node: HTMLDivElement) => void;
  itemBlocks: Array<IItemBlock>;
  onDrag: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    _index: number
  ) => void;
  onDragStart: (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
    index: number
  ) => void;
  onDragEnd: (
    _event: MouseEvent | TouchEvent | PointerEvent,
    _info: PanInfo,
    _index: number
  ) => void;
  onBlockAnimationComplete: (index: number) => void;
  getHintMove: () => {
    x: number;
    y: number;
  };
  itemBlocksRefs: Record<string, RefObject<IItemBlockRef>>;
  hintVisible: boolean;
  getHintAreas: () => Array<IArea>;
  answerItems: Array<IItemBlock>;
  answersBlockRef: RefObject<HTMLDivElement>;
}

export const useArrangeItemsViewport = ({
  options,
  onAnswer,
  addOneMistake
}: IArrangeItemsViewport): IReturnType => {
  const [matchedItems, setMatchedItems] = useState<Array<number>>([]);
  const [viewportRef, viewportRect, itemBlocks] = useDraggableBlocks(options);
  const selectedIndexRef = useRef(-1);
  const answersBlockRef = useRef<HTMLDivElement | null>(null);
  const [itemMatch, setItemMatch] = useState<IItemMatch>({
    status: '',
    first: -1,
    second: -1
  });

  const { getHintAreas, getHintMove } = useViewportHint({ itemBlocks, viewportRect });

  const itemBlocksRefs = useMemo(
    () =>
      itemBlocks.reduce((obj: Record<string, RefObject<IItemBlockRef>>, _word, index) => {
        obj[index] = createRef();
        return obj;
      }, {}),
    [itemBlocks]
  );

  const initBlocks = useCallback(
    (itemIndex = -1) => {
      if (itemIndex !== -1) {
        itemBlocksRefs[itemIndex].current?.triggerAnimation('initPosition');
        return;
      }

      if (selectedIndexRef.current === -1) {
        for (const key in itemBlocksRefs) {
          itemBlocksRefs[key].current?.triggerAnimation('visible');
        }
      }
    },
    [itemBlocksRefs]
  );

  const { onDrag, onDragStart, onDragEnd, answerItems, hintVisible } =
    useViewportDragHandlers({
      initBlocks,
      matchedItems,
      itemBlocks,
      itemBlocksRefs,
      addOneMistake,
      viewportRect,
      answersBlockRef,
      selectedIndexRef,
      setItemMatch,
      setMatchedItems
    });

  useEffect(() => {
    initBlocks();
  }, [initBlocks]);

  useEffect(() => {
    if (itemMatch.first !== -1 && itemMatch.second !== -1) {
      if (itemMatch.status === 'correct') {
        itemBlocksRefs[itemMatch.first].current?.setAnimation('correct');
        itemBlocksRefs[itemMatch.first].current
          ?.triggerAnimation('disappear')
          .then(() => {
            itemBlocks[itemMatch.first].originRef?.node.remove();
          });
      }

      if (itemMatch.status === 'incorrect') {
        itemBlocksRefs[itemMatch.first].current?.triggerAnimation('incorrectDragged');
        itemBlocksRefs[itemMatch.second].current?.triggerAnimation('incorrectDraggedTo');
      }
    }
  }, [itemMatch, initBlocks, itemBlocksRefs, itemBlocks]);

  useEffect(() => {
    if (matchedItems.length >= itemBlocks.length) {
      onAnswer();
    }
  }, [matchedItems, itemBlocks, onAnswer]);

  const onBlockAnimationComplete = useCallback(
    (index: number) => {
      if (itemMatch.status !== '') {
        if (itemMatch.status !== 'correct' || itemMatch.first !== index) {
          setItemMatch({ status: '', first: -1, second: -1 });
        }
      }
    },
    [itemMatch.first, itemMatch.status]
  );

  return {
    viewportRef,
    itemBlocks,
    onDrag,
    onDragStart,
    onDragEnd,
    onBlockAnimationComplete,
    getHintMove,
    itemBlocksRefs,
    hintVisible,
    getHintAreas,
    answerItems,
    answersBlockRef
  };
};
