import cn from 'classnames';
import { Translate } from 'i18n';
import React, { SyntheticEvent } from 'react';
import rightArrowIcon from 'students/views/shared/assets/icons/arrow_right_icon.svg';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { SYellowButton } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IProps {
  onClick: (e: SyntheticEvent) => void;
  className?: string;
  isCompleting?: boolean;
}

const FinishTaskButton = React.forwardRef<HTMLButtonElement | null, IProps>(
  ({ onClick, className, isCompleting }, forwardedRef) => {
    return (
      <SButton
        data-testid="students/components/TaskSubjects/Learn/FinishButton"
        onClick={onClick}
        className={cn(className)}
        showSpinner={isCompleting}
        disabled={isCompleting}
        spinnerVariant="darkBlue"
        ref={forwardedRef}
      >
        {/* span wrapping is needed here for solving node unmount removing error */}
        <SText>
          <Translate str="frontend.lesson_task.next_button" />
        </SText>
        <UrlIcon
          className="dir-auto"
          url={rightArrowIcon}
          color="#00466F"
          width="1em"
          height="1em"
        />
      </SButton>
    );
  }
);

export default FinishTaskButton;

const SButton = styled(SYellowButton)`
  border-radius: 62px;
  box-shadow: 0 4px 10px rgba(13, 97, 120, 0.2);
  font-size: 1.25rem;
  height: 3.75rem;
  width: 100%;
  text-transform: uppercase;
`;

const SText = styled.span`
  margin-inline-end: 0.625rem;
`;
