import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { motion } from 'framer-motion';
import { FC, ReactNode, useCallback } from 'react';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import useAnswerItem, { SCROLLABLE_CONTAINER_CLASSNAME } from '../../hooks/useAnswerItem';
import { IItemBlock } from '../../types';
import { SSvgAudioPlayer } from '../styled';

export { SCROLLABLE_CONTAINER_CLASSNAME };
interface IAnswerItem {
  itemBlock: IItemBlock;
}

const AnswerItem: FC<IAnswerItem> = ({ itemBlock }) => {
  const { blockType, position } = useAnswerItem({ itemBlock });

  const renderAnswerWord = useCallback(
    (children: ReactNode | null) => (
      <SAnswerWord
        as={blockType === 'word' ? motion.button : motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1], transition: { delay: 0.4, duration: 0.4 } }}
        data-type={blockType}
        style={{
          height: position.height,
          width: position.width
        }}
      >
        {children}
      </SAnswerWord>
    ),
    [blockType, position]
  );

  if (blockType === 'word') {
    return (
      <Popover className="">
        <PopoverButton
          as="div"
          style={{
            position: 'absolute',
            left: position.x,
            top: position.y
          }}
        >
          {renderAnswerWord(<span>{itemBlock.option.text || ''}</span>)}
        </PopoverButton>

        <PopoverPanel
          anchor="bottom start"
          className="ln-z-[100] ln-flex ln-items-center ln-bg-white ln-shadow-card ln-rounded-lg ln-py-2 ln-px-4 !ln-max-w-64"
        >
          {itemBlock.option.text || ''}
        </PopoverPanel>
      </Popover>
    );
  }

  return renderAnswerWord(
    blockType === 'picture' ? (
      <PhraseIcon
        imageUrl={itemBlock.option?.image || ''}
        colorRequired
        animationUrl={null}
        text={itemBlock.option.text || ''}
        width="100px"
        height="100px"
      />
    ) : blockType === 'audio' ? (
      <SSvgAudioPlayer src={itemBlock.option?.audio || ''} progressColor="#363658" />
    ) : null
  );
};

export default AnswerItem;

const SAnswerWord = styled(motion.div)`
  position: absolute;
  cursor: default;
  border: none;
  border-radius: 10px;
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &[data-type='word'] {
    padding: 12px;
    font-weight: 700;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: var(--color-black);
    text-align: center;

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    ${customMediaQuery('tablet')} {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &[data-type='audio'] {
    padding: 12px;
  }
`;
