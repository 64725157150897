import cn from 'classnames';
import { FC, ReactElement, RefObject } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import TextSelector from './TextSelector';
import useTextSelectTooltip from './useTextSelectTooltip';

interface ITextSelectTooltip {
  firstLevelTooltip: (
    ref: RefObject<HTMLDivElement>,
    selectedText: string,
    openSecondLevelTooltip: () => void
  ) => ReactElement;
  secondLevelTooltip?: (ref: RefObject<HTMLDivElement>) => ReactElement;
  children: ReactElement;
  className?: string;
}

const TextSelectTooltip: FC<ITextSelectTooltip> = ({
  firstLevelTooltip,
  secondLevelTooltip,
  children,
  className
}) => {
  const {
    showFirstLevelTooltip,
    showSecondLevelTooltip,
    openSecondLevelTooltip,
    tooltipCoordinates,
    selectedText,
    handleTextSelect,
    firstLevelTooltipRef,
    secondLevelTooltipRef
  } = useTextSelectTooltip();

  return (
    <div className={cn(className)}>
      <TextSelector onTextSelect={handleTextSelect}>{children}</TextSelector>

      {createPortal(
        <div id="text-translation-tooltip">
          {showFirstLevelTooltip ? (
            showSecondLevelTooltip && secondLevelTooltip ? (
              <STooltip
                id="second-level-tooltip"
                $tooltipCoordinates={tooltipCoordinates}
              >
                {secondLevelTooltip(secondLevelTooltipRef)}
              </STooltip>
            ) : (
              <STooltip id="first-level-tooltip" $tooltipCoordinates={tooltipCoordinates}>
                {firstLevelTooltip(
                  firstLevelTooltipRef,
                  selectedText,
                  openSecondLevelTooltip
                )}
              </STooltip>
            )
          ) : null}
        </div>,
        document.getElementById('lingu-app')!
      )}
    </div>
  );
};

export default TextSelectTooltip;

const STooltip = styled.div<{ $tooltipCoordinates: { x: string; y: string } }>`
  position: absolute;
  z-index: 999;
  display: block;

  ${({ $tooltipCoordinates }) => `
    left: calc(${$tooltipCoordinates.x} - 80px);
    top: calc(${$tooltipCoordinates.y} + 16px);
`}
`;
