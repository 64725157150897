import { FC, PropsWithChildren, useCallback, useState } from 'react';

import PromptContext from './PromptContext';

const PromptProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const makeDirty = useCallback(
    (dirty: boolean) => {
      setIsDirty(dirty);
    },
    [setIsDirty]
  );

  return (
    <PromptContext.Provider
      value={{
        isDirty,
        makeDirty
      }}
    >
      {children}
    </PromptContext.Provider>
  );
};

export default PromptProvider;
