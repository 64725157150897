import { SPECIAL_CHARACTERS_REG_EXP } from '../constants';

type tArgs = {
  input: string;
  correctAnswer: string;
  checkPunctuation: boolean;
  caseSensitive: boolean;
};
type tReturnValue = {
  correctPart: string;
  nextPartHint?: string;
};

export function getFirstMistake({
  input,
  correctAnswer,
  checkPunctuation,
  caseSensitive
}: tArgs): tReturnValue {
  const _input = input.replace(/\s\s+/g, ' ').trim();
  const _correctAnswer = correctAnswer.replace(/\s\s+/g, ' ').trim();

  if (!_input.length) {
    return {
      correctPart: '',
      nextPartHint: _correctAnswer.split(' ')[0]
    };
  }

  if (_input === _correctAnswer) {
    return {
      correctPart: _correctAnswer,
      nextPartHint: ''
    };
  }

  // soft validation
  if (!caseSensitive && !checkPunctuation) {
    const _inputWithLowCase = _input.toLowerCase();
    const _correctAnswerWithLowCase = _correctAnswer.toLowerCase();

    if (_inputWithLowCase === _correctAnswerWithLowCase) {
      return {
        correctPart: _correctAnswer,
        nextPartHint: ''
      };
    }

    return getHintForNoPunctuation(
      _input,
      _correctAnswer,
      (inputWord, correctWord) => inputWord?.toLowerCase() === correctWord?.toLowerCase()
    );
  }

  // strict validation
  if (caseSensitive && checkPunctuation) {
    return checkWordsWithPunctuation(
      _input,
      _correctAnswer,
      (inputWord, correctWord) => inputWord === correctWord
    );
  }

  // only punctuation check
  if (!caseSensitive && checkPunctuation) {
    const _inputWithLowCase = _input.toLowerCase();
    const _correctAnswerWithLowCase = _correctAnswer.toLowerCase();

    if (_inputWithLowCase === _correctAnswerWithLowCase) {
      return {
        correctPart: _correctAnswer,
        nextPartHint: ''
      };
    }

    return checkWordsWithPunctuation(
      _input,
      _correctAnswer,
      (inputWord, correctWord) => inputWord?.toLowerCase() === correctWord?.toLowerCase()
    );
  }

  // only case check
  if (caseSensitive && !checkPunctuation) {
    return getHintForNoPunctuation(
      _input,
      _correctAnswer,
      (inputWord, correctWord) => inputWord === correctWord
    );
  }

  return {
    correctPart: _correctAnswer,
    nextPartHint: ''
  };
}

function _getWordIndexFromString(source: string, word: string) {
  // check if first word match
  const REGEXP_PART = '(?=[\\s,.?!:-]|$)(?!\\p{L})';
  const firstWordMatchRegexp = new RegExp(`^${word}${REGEXP_PART}`, 'gu');
  if (firstWordMatchRegexp.test(source)) {
    return 0;
  }
  const regexpWithSpaceInFront = new RegExp(`\\s${word}${REGEXP_PART}`, 'gu');
  const regexpWithDashInFront = new RegExp(`[-_]${word}${REGEXP_PART}`, 'gu');
  const indexOfWordWithSpaceInFront =
    regexpWithSpaceInFront.exec(source)?.index ||
    regexpWithDashInFront.exec(source)?.index;
  const index =
    indexOfWordWithSpaceInFront !== undefined ? indexOfWordWithSpaceInFront + 1 : -1;

  return index;
}
function _getOneWordAfterIndex(source: string, index: number) {
  let word = /^\S*(?=\s|$)/.exec(source.slice(index))?.[0];
  const dashIndex = word?.indexOf('-');

  if (word && word.length > 1 && dashIndex !== undefined && dashIndex !== -1) {
    word = word.slice(0, dashIndex + 1);
  }

  return word ?? '';
}

function checkWordsWithPunctuation(
  input: string,
  correctSentence: string,
  validator: (inputWord?: string, correctWord?: string) => boolean
): tReturnValue {
  const correctWords = correctSentence.split(' ');
  const userWords = input.split(' ');
  let mistakeWordIndex = 0;
  let correctPart = '';
  let nextWord = '';

  if (validator(input.slice(0, correctSentence.length))) {
    return {
      correctPart: correctSentence,
      nextPartHint: ''
    };
  }
  while (
    userWords[mistakeWordIndex] !== undefined &&
    validator(userWords[mistakeWordIndex], correctWords[mistakeWordIndex]) &&
    mistakeWordIndex < correctWords.length
  ) {
    nextWord = correctWords[mistakeWordIndex + 1] || '';
    correctPart += `${correctWords[mistakeWordIndex] || ''}${nextWord ? ' ' : ''}`;
    mistakeWordIndex += 1;
  }

  const nextPartHint = _getOneWordAfterIndex(correctSentence, correctPart.length);

  return {
    correctPart,
    nextPartHint
  };
}

function getHintForNoPunctuation(
  input: string,
  correctSentence: string,
  validator: (inputWord?: string, correctWord?: string) => boolean
): tReturnValue {
  let _inputWithNoPunctuation = input.replace(SPECIAL_CHARACTERS_REG_EXP, ' ');
  // lets don't count '-' in no punctuation check
  _inputWithNoPunctuation = _inputWithNoPunctuation
    .replace(/-/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();

  let _correctAnswerWithNoPunctuation = correctSentence.replace(
    SPECIAL_CHARACTERS_REG_EXP,
    ' '
  );
  // lets don't count '-' in no punctuation check
  _correctAnswerWithNoPunctuation = _correctAnswerWithNoPunctuation
    .replace(/-/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();

  if (
    validator(_inputWithNoPunctuation, _correctAnswerWithNoPunctuation) ||
    validator(
      _inputWithNoPunctuation.slice(0, _correctAnswerWithNoPunctuation.length),
      _correctAnswerWithNoPunctuation
    )
  ) {
    return {
      correctPart: correctSentence,
      nextPartHint: ''
    };
  }

  const correctWords = _correctAnswerWithNoPunctuation.split(' ');
  const usersWords = _inputWithNoPunctuation.split(' ');
  let mistakeWordIndex = 0;

  while (
    usersWords[mistakeWordIndex] !== undefined &&
    validator(usersWords[mistakeWordIndex], correctWords[mistakeWordIndex]) &&
    mistakeWordIndex < correctWords.length
  ) {
    mistakeWordIndex += 1;
  }

  // Note: founded word with mistake could be also somewhere before mistake
  // and there it could be correct,
  // that's why we need this extra logic to count how many "mistake" words are in the correct part
  const firstWrongWord = correctWords[mistakeWordIndex];
  const timesWrongWordFoundedInCorrectPart = correctWords
    .slice(0, mistakeWordIndex)
    .filter((item) => item === firstWrongWord).length;

  let wrongWordIndexAccumulator = 0;
  const wordLength = firstWrongWord.length;

  for (
    let i = -1,
      indexOfWrongWordInTheInitialCorrectString = -1,
      searchSource = correctSentence;
    i < timesWrongWordFoundedInCorrectPart;
    i += 1
  ) {
    indexOfWrongWordInTheInitialCorrectString = _getWordIndexFromString(
      searchSource,
      firstWrongWord
    );
    if (i > -1) {
      wrongWordIndexAccumulator += wordLength;
    }

    if (indexOfWrongWordInTheInitialCorrectString !== -1) {
      wrongWordIndexAccumulator += indexOfWrongWordInTheInitialCorrectString;
      searchSource = correctSentence.slice(wrongWordIndexAccumulator + wordLength);
    }
  }

  const correctPart = correctSentence.slice(0, wrongWordIndexAccumulator);
  const hintWord = _getOneWordAfterIndex(correctSentence, wrongWordIndexAccumulator);

  return {
    correctPart,
    nextPartHint: hintWord
  };
}
