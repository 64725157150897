import { useCallback, useState } from 'react';
import { languagesApi, LanguagesApiTypes } from '@adeptlms/lingu-students-react-shared';

interface IReturnedValue {
  definitions: Array<LanguagesApiTypes.IWordSearchArticle> | null;
  isDefinitionLoading: boolean;
  handleOpenSecondLevelTooltip: (selectedText: string, cb: () => void) => () => void;
}

function useTranslationTextTooltip(): IReturnedValue {
  const [definitions, setDefinitions] =
    useState<Array<LanguagesApiTypes.IWordSearchArticle> | null>(null);
  const [isDefinitionLoading, setIsDefinitionLoading] = useState<boolean>(false);

  const handleOpenSecondLevelTooltip = useCallback(
    (selectedText: string, cb: () => void) => async () => {
      cb();

      setIsDefinitionLoading(true);
      setDefinitions(null);

      const { result } = await languagesApi.getWordDefinition(selectedText);

      setIsDefinitionLoading(false);

      if (result.length) {
        setDefinitions(result[0].articles);
      } else {
        setDefinitions([]);
      }
    },
    [setIsDefinitionLoading, setDefinitions]
  );

  return {
    definitions,
    isDefinitionLoading,
    handleOpenSecondLevelTooltip
  };
}

export default useTranslationTextTooltip;
