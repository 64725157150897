import { useCallback } from 'react';

import { IItemBlock } from '../types';

interface IReturnedValue {
  getHintAreas: () => Array<{
    x: number;
    y: number;
    height: number;
    width: number;
  }>;
  getHintMove: () => {
    x: number;
    y: number;
  };
}

function useViewportHint({
  itemBlocks,
  viewportRect
}: {
  itemBlocks: Array<IItemBlock>;
  viewportRect: DOMRect | null;
}): IReturnedValue {
  const getHintAreas = useCallback(() => {
    const firstItem = itemBlocks
      .filter((itemBlock: IItemBlock) => !itemBlock.origin)
      .find((itemBlock: IItemBlock) => itemBlock.option.position === 1);
    const originRect = firstItem?.originRef?.node?.getBoundingClientRect();
    const destinationRect = firstItem?.destinationRef?.node?.getBoundingClientRect();

    return [
      {
        height: originRect?.height || 0,
        width: originRect?.width || 0,
        x: (originRect?.x || 0) - (viewportRect?.x || 0),
        y: (originRect?.y || 0) - (viewportRect?.y || 0)
      },
      {
        height: destinationRect?.height || 0,
        width: destinationRect?.width || 0,
        x: firstItem?.destinationRef?.node.offsetLeft || 0,
        y: firstItem?.destinationRef?.node.offsetTop || 0
      }
    ];
  }, [itemBlocks, viewportRect]);

  const getHintMove = useCallback(() => {
    const firstItem = itemBlocks
      .filter((itemBlock: IItemBlock) => !itemBlock.origin)
      .find((itemBlock: IItemBlock) => itemBlock.option.position === 1);
    const originRect = firstItem?.originRef?.node?.getBoundingClientRect();
    const destinationRect = firstItem?.destinationRef?.node?.getBoundingClientRect();

    return {
      x:
        Math.abs((destinationRect?.x || 0) - (originRect?.x || 0)) *
        ((originRect?.x || 0) > (destinationRect?.x || 0) ? -1 : 1),
      y: -Math.abs((destinationRect?.y || 0) - (originRect?.y || 0))
    };
  }, [itemBlocks]);

  return {
    getHintAreas,
    getHintMove
  };
}

export default useViewportHint;
