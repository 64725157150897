import React, { useMemo } from 'react';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { useMobileBreakPoint } from 'students/views/shared/hooks';
import iconsMap from './icons';

interface IProps {
  streakPercent: number;
}

const StreakIcon: React.FC<IProps> = ({ streakPercent }) => {
  const isMobile = useMobileBreakPoint();
  const icon = useMemo(() => iconsMap.get(streakPercent), [streakPercent]);

  return (
    <>
      {icon ? (
        <UrlIcon url={icon} width="133px" height={isMobile ? '55px' : '64px'} />
      ) : null}
    </>
  );
};

export default StreakIcon;
