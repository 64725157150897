import { ILearnEmbedTask } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import {
  useHeartBeatChangeOptions,
  useHeartBeatContextApi
} from 'students/views/shared/components/HeartBeat';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { FinishTaskButton } from '../../components';
import { clearEmbedCode } from '../../helpers';
import { ILearnTaskProps } from '../types';

/* Temporary increase Idle timeout */
const HEART_BEAT_CONFIG = {
  timeout: 900000 // 15min
};
const LearnEmbedTask: React.FC<ILearnTaskProps<ILearnEmbedTask>> = ({
  task,
  onFinish,
  lightFont,
  isCompleting
}) => {
  const {
    title,
    items: [{ url }]
  } = task;

  const [urls, embedCode] = useMemo(() => {
    return clearEmbedCode(url);
  }, [url]);
  useResetIdleTimerOnMessage();
  useEffect(() => {
    urls.forEach((url) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    });
  }, [urls]);

  function createMarkup() {
    return { __html: embedCode };
  }

  function handleFinish(e: SyntheticEvent): void {
    e.preventDefault();
    onFinish();
  }

  return (
    <SEmbedTaskWrapper>
      <STitle className={cn({ light: lightFont })}>{title}</STitle>

      <SContentWrapper>
        <SMediaWrapper dangerouslySetInnerHTML={createMarkup()} />

        <SButtonBlock>
          <SFinishTaskButton onClick={handleFinish} isCompleting={isCompleting} />
        </SButtonBlock>
      </SContentWrapper>
    </SEmbedTaskWrapper>
  );
};

export default LearnEmbedTask;

function useResetIdleTimerOnMessage() {
  const heartBeatContext = useHeartBeatContextApi();
  useHeartBeatChangeOptions(HEART_BEAT_CONFIG);

  useEffect(() => {
    function handleMessage(event: MessageEvent<any>) {
      switch (event.origin) {
        case 'https://quizlet.com':
        case 'https://h5p.org': {
          heartBeatContext?.reset();
          break;
        }
        default:
          break;
      }
    }
    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [heartBeatContext]);
}

const SEmbedTaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  ${customMediaQuery('tablet')} {
    max-width: 921px;
    align-self: center;
  }
`;

const STitle = styled.h1`
  color: var(--lingu-font-dark-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin-block-end: 1.625rem;
  text-align: start;

  &.light {
    color: var(--lingu-font-light-color);
  }

  ${customMediaQuery('tablet')} {
    text-align: center;
  }
`;

const SContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -1rem -1rem;
  flex-grow: 1;
  background: white;
  overflow-x: auto;

  ${customMediaQuery('tablet')} {
    margin: 3.75rem 0 0;
    background: transparent;
  }
`;

const SMediaWrapper = styled.div`
  width: 100%;
  background: var(--color-white);
  margin: 0 -1rem;
  position: relative;

  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  &.combined {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-block-end: 0;
  }

  ${customMediaQuery('tablet')} {
    margin: 0;
    border-radius: 20px;
    overflow: hidden;
  }
`;

const SButtonBlock = styled.div`
  margin: 0;
  padding: 84px 5% 0 5%;
  width: 100%;
  background: var(--color-white);

  ${customMediaQuery('tablet')} {
    width: 340px;
    padding: 40px 0;
    background: transparent;
  }
`;

const SFinishTaskButton = styled(FinishTaskButton)`
  margin: 0;
`;
