import React, { useMemo } from 'react';
import shuffle from 'students/utils/native/shuffle';
import { IGameContainerProps } from '../types';
import Flashcard from './Flashcard';

const MAX_WORDS_NUMBER = 12;

const FlashcardContainer: React.FC<IGameContainerProps> = ({
  taskId,
  phrases,
  maxRounds,
  onRoundComplete,
  onNext,
  className
}) => {
  const words = useMemo(
    () => shuffle(phrases).slice(0, maxRounds || MAX_WORDS_NUMBER),
    [maxRounds, phrases]
  );

  return words.length ? (
    <Flashcard
      taskId={taskId}
      words={words}
      onRoundComplete={onRoundComplete}
      onNext={onNext}
      className={className}
    />
  ) : null;
};

export default FlashcardContainer;
