import {
  IGameRoundAnswer,
  tGameDifficulty,
  tWordWaterfallData
} from '@adeptlms/lingu-students-react-shared';
import { AnimationControls, TapInfo, useAnimation } from 'framer-motion';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import useMobileVhHack from 'students/views/shared/hooks/useMobileVhHack';

import { useGameAudioPlayer, useHintVisibility } from '../../common/helpers';
import { audioItems } from '../assets/audioItems';

interface IUseWordWaterfall {
  rounds: tWordWaterfallData;
  difficulty?: tGameDifficulty;
  onRoundComplete?: (answer: IGameRoundAnswer) => void;
}

interface IReturnedValue {
  containerElRef: (node: any) => void;
  theme: string;
  backgroundEl: RefObject<HTMLDivElement>;
  circleEl: RefObject<HTMLDivElement>;
  controls: AnimationControls;
  bgControls: AnimationControls;
  stage: 'start' | 'inGame' | 'finished' | 'timeout' | 'wrong';
  setStage: Dispatch<
    SetStateAction<'start' | 'inGame' | 'finished' | 'timeout' | 'wrong'>
  >;
  roundData: tWordWaterfallData[number];
  question: {
    text: string;
    imageURL: string | null;
    animationURL: string | null;
    colorRequired: boolean;
  };
  words: tWordWaterfallData[number]['list'];
  containerWidth: number;
  shuffled: number[];
  onWordClick: (e: any, info: TapInfo, word: string, buttonTheme: string) => void;
  boxVariants: any;
  onWordDropped: (wordIndex: number) => void;
  hintWordVisible: boolean;
  hintVisible: boolean;
  goToRound: (where: 'prev' | 'next') => void;
  handleNext: () => void;
  handlePrev: () => void;
  round: number;
}

export const themes = ['blue', 'orange', 'yellow', 'pink', 'green'];
export const unShuffled = [-2.5, -1.5, -0.5, 0.5, 1.5];
export const BACKGROUND_STEP = 200;
const duration: { [key: string]: number } = { easy: 20, medium: 16, hard: 12 };
const WORD_ANIMATION_DELAY = 2;
const HINT_ANIMATION_DELAY = 4000;
const AUTO_SKIP_TIMEOUT = 1500;

function useWordWaterfall({
  rounds,
  difficulty = 'easy',
  onRoundComplete = () => {}
}: IUseWordWaterfall): IReturnedValue {
  const { playCorrectSound, playIncorrectSound } = useGameAudioPlayer(audioItems);
  useMobileVhHack();
  const bgControls = useAnimation();
  const controls = useAnimation();
  const [round, setRound] = useState(0);
  const [theme, setTheme] = useState('blue');
  const [stage, setStage] = useState<
    'start' | 'inGame' | 'finished' | 'timeout' | 'wrong'
  >('start');
  const [hintVisible, updateLastInteractionTime] = useHintVisibility(
    round === 0 && stage === 'inGame'
  );
  const [hintWordVisible, setHintWordVisible] = useState(false);
  const timeoutRef = useRef<null | number>(null);
  const hintDelayTimeoutRef = useRef<null | number>(null);

  const roundData = rounds[round];

  const goToRound = useCallback(
    (where: 'prev' | 'next', returnCallback = false) => {
      const nextRound = where === 'prev' ? round - 1 : round + 1;
      const isNextAvailable = where === 'prev' ? round > 0 : round < rounds.length - 1;

      bgControls.start({
        y: BACKGROUND_STEP * nextRound,
        transition: { ease: [0.5, 1, 0.89, 1], duration: 3 }
      });

      const callback = isNextAvailable
        ? () => {
            setRound(nextRound);
            setStage('inGame');
          }
        : () => setStage('finished');
      if (returnCallback) {
        return callback;
      }
      ReactDOM.unstable_batchedUpdates(() => {
        callback();
      });
      return null;
    },
    [bgControls, rounds.length, round]
  );

  useEffect(() => {
    if (stage === 'wrong') {
      const timer1 = window.setTimeout(() => goToRound('next'), AUTO_SKIP_TIMEOUT);
      return () => clearTimeout(timer1);
    }
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      hintDelayTimeoutRef.current && clearTimeout(hintDelayTimeoutRef.current);
    };
  }, [stage, goToRound]);

  const shuffled = useMemo(
    () =>
      unShuffled
        .map((a) => ({ sort: Math.random() + round, value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value),
    [round]
  );

  const [containerWidth, setContainerWidth] = useState(0);
  const backgroundEl = useRef<HTMLDivElement>(null);
  const circleEl = useRef<HTMLDivElement>(null);
  const containerElRef = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setContainerWidth(node.offsetWidth);
    }
  }, []);

  const { question, list: words } = roundData ?? {};
  const fallingDurations =
    words?.map((_, i) => duration[difficulty] + i * (duration[difficulty] / 10)) ?? [];

  const showWordHintWithDelay = () => {
    hintDelayTimeoutRef.current = window.setTimeout(() => {
      setHintWordVisible(true);
    }, HINT_ANIMATION_DELAY);
  };

  const boxVariants = {
    dropping: ({ index, word }: { index: number; word: string }) => {
      const delay = WORD_ANIMATION_DELAY + index * (duration[difficulty] / 10);

      timeoutRef.current = window.setTimeout(() => {
        if (roundData.answer === word) {
          showWordHintWithDelay();
        }
      }, delay * 1000);

      return {
        opacity: [0, 1, 1, 0],
        top: ['15%', '25%', '95%', '99%'],
        scale: [1, 1, 1, 2],
        transition: {
          delay,
          duration: fallingDurations[index] ?? 0,
          times: [0, 0.1, 0.95, 1],
          ease: 'linear'
        }
      };
    }
  };

  const handleRoundComplete = (answer = '') => {
    const { answer: correctAnswer } = roundData;
    onRoundComplete({
      word: correctAnswer,
      solved: answer === correctAnswer
    });
  };

  const handleNext = () => {
    updateLastInteractionTime();
    handleRoundComplete();
    goToRound('next');
  };

  const handlePrev = () => {
    updateLastInteractionTime();
    goToRound('prev');
  };

  const onWordClick = (e: any, info: TapInfo, word: string, buttonTheme: string) => {
    let newTheme = buttonTheme;
    if (newTheme === theme) [newTheme] = themes.filter((t) => t !== newTheme);
    e.target.classList.add('clicked');
    updateLastInteractionTime();

    handleRoundComplete(word);
    if (roundData.answer === word) {
      playCorrectSound();
      const center = {
        x:
          info.point.x -
          (backgroundEl.current?.getBoundingClientRect().left || 0) -
          document.documentElement.scrollLeft,
        y:
          info.point.y -
          (backgroundEl.current?.getBoundingClientRect().top || 0) -
          document.documentElement.scrollTop
      };
      const centerPosition = `${center.x}px ${center.y}px`;
      circleEl?.current?.classList.remove(...themes);
      circleEl?.current?.classList.add(newTheme);
      controls.set({ display: 'block' });
      controls.set({ clipPath: `circle(0px at ${centerPosition})` });
      controls
        .start({
          clipPath: [
            null,
            `circle(300px at ${centerPosition})`,
            `circle(1500px at ${centerPosition})`
          ],
          transition: { times: [0, 0.5, 1], duration: 1 }
        })
        .then(() => {
          controls.set({ display: 'none' });
          if (roundData.answer === word) {
            const callback = goToRound('next', true);
            ReactDOM.unstable_batchedUpdates(() => {
              setTheme(newTheme);
              if (callback) callback();
            });
          }
        });
    } else {
      playIncorrectSound();
      setStage('wrong');
    }
  };

  const onWordDropped = (wordIndex: number) => {
    playIncorrectSound();
    if (wordIndex === (words?.length ?? 0) - 1) {
      handleRoundComplete();
      setStage('timeout');
    }
  };

  return {
    containerElRef,
    theme,
    backgroundEl,
    circleEl,
    controls,
    bgControls,
    stage,
    setStage,
    roundData,
    question,
    words,
    containerWidth,
    shuffled,
    onWordClick,
    boxVariants,
    onWordDropped,
    hintWordVisible,
    hintVisible,
    goToRound,
    handleNext,
    handlePrev,
    round
  };
}

export default useWordWaterfall;
