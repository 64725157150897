import cn from 'classnames';
import { t, Translate } from 'i18n';
import { FC } from 'react';
import prevTaskIcon from 'students/views/shared/assets/icons/prev_task_icon.svg';
import restartIcon from 'students/views/shared/assets/icons/restart_icon.svg';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import { NextButton } from 'students/views/shared/components/Buttons';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { withFade2 } from 'students/views/shared/HOCs';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import Leaderboard from '../components/Leaderboard';
import useFinishScreen from './useFinishScreen';

interface IProps {
  isCompleting: boolean;
  bgColor: string;
  onFinish: () => void;
  onRestart: () => void;
}

const FinishScreen: FC<IProps> = ({ isCompleting, bgColor, onFinish, onRestart }) => {
  const {
    handleFinish,
    leaderboard,
    taskSessionXP,
    currentUserProfile,
    handleRestartTask,
    handleGoToPrevTask
  } = useFinishScreen({ onFinish, onRestart });

  return (
    <SWrapper>
      {!isCompleting ? (
        <>
          {currentUserProfile ? (
            <SLeaderboard
              className={cn({ extraMargin: !leaderboard.length })}
              leaderboard={leaderboard}
              taskSessionXP={taskSessionXP}
              currentUserProfile={currentUserProfile}
              bgColor={bgColor}
            />
          ) : null}

          {!leaderboard.length ? (
            <SHeading>
              <Translate str="frontend.leaderboard.go_to_next_exercise" />
            </SHeading>
          ) : null}
        </>
      ) : null}

      <SNextButtonWrapper className={cn({ absolute: leaderboard.length > 0 })}>
        {!isCompleting ? (
          <SSubActionButton
            onClick={handleGoToPrevTask}
            title={t('frontend.lesson_task.previous_task', {
              defaultValue: 'Previous task'
            })}
          >
            <UrlIcon
              className="dir-auto"
              url={prevTaskIcon}
              height="24px"
              width="24px"
              color="#ffffff"
            />
          </SSubActionButton>
        ) : null}

        <NextButton
          data-testid="students/components/TaskSubjects/Play/FinishScreen/FinishButton"
          size="100px"
          title={t('frontend.lesson_task.finish_button')}
          onClick={handleFinish}
          disabled={isCompleting}
          showSpinner={isCompleting}
          spinnerVariant="dark"
          className="dir-auto"
        />

        {!isCompleting ? (
          <SSubActionButton
            onClick={handleRestartTask}
            title={t('frontend.lesson_task.restart_task')}
          >
            <UrlIcon
              className="dir-auto"
              url={restartIcon}
              height="24px"
              width="24px"
              color="#ffffff"
            />
          </SSubActionButton>
        ) : null}
      </SNextButtonWrapper>
    </SWrapper>
  );
};

export default withFade2<IProps>({ duration: 800, className: 'ln-flex ln-grow' })(
  FinishScreen
);

const SWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  ${customMediaQuery('tablet')} {
    width: 50vw;
  }
`;

const SHeading = styled.h2`
  color: var(--color-white);
  margin: 0 0 20px;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
`;

const SLeaderboard = styled(Leaderboard)`
  margin-block-end: 0;
  width: 100vw;
  max-width: 100vw;
  flex: 1;

  &.extraMargin {
    margin-block-end: 80px;
  }

  ${customMediaQuery('tablet')} {
    margin-block-end: 0;
    width: 100%;
    max-width: 600px;

    &.extraMargin {
      margin-block-end: 100px;
    }
  }
`;

const SNextButtonWrapper = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.absolute {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-height: 568px) {
      bottom: -24px;
    }
  }
`;

const SSubActionButton = styled(ButtonGeneral)`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: var(--assignments-tag-background);
  box-shadow: var(--card-shadow);
  margin: 0 12px;

  &:hover {
    background: var(--meeting-cancel-booking);
  }
`;
