import cn from 'classnames';
import React, { useState } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import SPlayTaskWrapper from '../components/SPlayTaskWrapper';
import usePlayAudioPlayer from '../hooks/usePlayAudioPlayer';
import { FinishScreen, StartScreen } from './index';

type tScreenName = 'start' | 'main' | 'finish';
interface IProps {
  startScreenHeading: string;
  taskColor: string;
  onExitFinishScreen: () => void;
  children: (goToFinishScreen: () => void) => JSX.Element | null;
  className?: string;
  isCompleting?: boolean;
  itemsExist: boolean; // to check if task items are not empty
  taskStatus?: string;
  completeTask: () => void;
}
const PlayTaskScreenSwitcher: React.FC<IProps> = ({
  startScreenHeading,
  taskColor,
  onExitFinishScreen,
  children,
  className,
  isCompleting,
  itemsExist,
  taskStatus,
  completeTask
}) => {
  const [currentScreen, setCurrentScreen] = useState<tScreenName>('start');
  const { playFinishSound } = usePlayAudioPlayer();

  const handleStartTask = () => {
    if (itemsExist) {
      setCurrentScreen('main');
    } else {
      completeTask();
      setCurrentScreen('finish');
    }
  };

  const handleCompleteTask = () => {
    playFinishSound();
    setCurrentScreen('finish');
  };

  const handleFinishTask = () => {
    onExitFinishScreen();
  };

  const handleRestartTask = () => {
    setCurrentScreen('start');
  };

  const renderScreen = (screen: tScreenName): JSX.Element | null => {
    if (taskStatus === 'completed') {
      return (
        <FinishScreen
          isCompleting={isCompleting}
          onFinish={handleFinishTask}
          onRestart={handleRestartTask}
          bgColor={taskColor}
        />
      );
    }

    switch (screen) {
      case 'start':
        return (
          <StartScreen
            isCompleting={isCompleting}
            onStart={handleStartTask}
            heading={startScreenHeading}
          />
        );
      case 'main':
        return (
          <>
            <SInstructions dir="ltr">{startScreenHeading}</SInstructions>

            {children(handleCompleteTask)}
          </>
        );
      case 'finish':
        return (
          <FinishScreen
            isCompleting={isCompleting}
            onFinish={handleFinishTask}
            onRestart={handleRestartTask}
            bgColor={taskColor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SPlayTaskWrapper className={cn(className)}>
      {renderScreen(currentScreen)}
    </SPlayTaskWrapper>
  );
};

export default PlayTaskScreenSwitcher;

const SInstructions = styled.p`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--color-white);
  margin: 2rem 0;
  text-align: center;

  ${customMediaQuery('tablet')} {
    margin: 1rem 0 0;
  }
`;
