import styled from 'styled-components';
import { customMediaQuery } from 'students/views/shared/styled';

const SInstruction = styled.div`
  width: 85vw;
  max-height: 200px;
  overflow: auto;
  font-size: 1.25rem;
  text-align: center;
  padding: 0 0.5rem;

  &::-webkit-scrollbar {
    -webkit-appearance: none;

    &:vertical {
      width: 5px;
    }

    &-thumb {
      border-radius: 0.5rem;
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-track {
      border-radius: 0.5rem;
      background: transparent;
    }
  }

  ${customMediaQuery('tablet')} {
    width: 65vw;
    font-size: 1.5rem;
    max-height: none;
    overflow: unset;
    padding: 0;
  }
`;

export default SInstruction;
