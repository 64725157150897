import cn from 'classnames';
import {
  FC,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useOutsideClick, useWrongAnimation } from 'students/views/shared/hooks';
import styled from 'styled-components';

const WRONG_ANIMATION_CLASS_NAME = 'wrong-animation';

interface IProps {
  show: boolean;
  onClickOutside: () => void;
  onSelectAnswer: (answer: string) => void;
  onSelectWrongAnswer: () => void;
  toggleVisibility: () => void;
  options: string[];
  triggerRef: RefObject<HTMLElement>;
  solution?: string;
}
const Options: FC<IProps> = ({
  show,
  options,
  triggerRef,
  onClickOutside,
  onSelectAnswer,
  onSelectWrongAnswer,
  toggleVisibility,
  solution
}) => {
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<any>(null);

  useOutsideClick(optionsRef, onClickOutside, [triggerRef]);

  const [wrongAnimationRef, showWrongAnimation] = useWrongAnimation(
    undefined,
    WRONG_ANIMATION_CLASS_NAME
  );

  const setAnswerWithSolution = useCallback(
    (value: string, solution: string) => {
      const correct = value === solution;

      if (correct) {
        onSelectAnswer(solution);
      } else {
        onSelectWrongAnswer();
        showWrongAnimation();
      }
    },
    [onSelectAnswer, onSelectWrongAnswer, showWrongAnimation]
  );

  const optionClickHandler = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const value = e.currentTarget.dataset.value || '';

      if (solution) {
        setAnswerWithSolution(value, solution);
      } else {
        onSelectAnswer(value);
      }
    },
    [onSelectAnswer, solution, setAnswerWithSolution]
  );

  const handleArrowUpPress = useCallback(() => {
    if (currentFocusedIndex !== null) {
      setCurrentFocusedIndex((currentFocusedIndex - 1 + options.length) % options.length);
    } else {
      setCurrentFocusedIndex(0);
    }

    if (!show) {
      toggleVisibility();
    }
  }, [show, toggleVisibility, currentFocusedIndex, options.length]);

  const handleArrowDownPress = useCallback(() => {
    if (currentFocusedIndex !== null) {
      setCurrentFocusedIndex((currentFocusedIndex + 1) % options.length);
    } else {
      setCurrentFocusedIndex(0);
    }

    if (!show) {
      toggleVisibility();
    }
  }, [show, toggleVisibility, currentFocusedIndex, options.length]);

  const handleEnterSpacePress = useCallback(
    (e: KeyboardEvent) => {
      if (show && currentFocusedIndex !== null) {
        e.preventDefault();

        const selectedValue = options[currentFocusedIndex];

        if (solution) {
          setAnswerWithSolution(selectedValue, solution);
        } else {
          onSelectAnswer(selectedValue);
        }
      }
    },
    [show, currentFocusedIndex, solution, onSelectAnswer, setAnswerWithSolution, options]
  );

  const handleEscapePress = useCallback(() => {
    if (show) {
      toggleVisibility();
    }
  }, [show, toggleVisibility]);

  const handleToggleBtnKeyPress = useCallback(
    (e: KeyboardEvent) => {
      switch (e.keyCode) {
        /* Arrow up */
        case 38:
          handleArrowUpPress();
          break;

        /* Arrow down */
        case 40:
          handleArrowDownPress();
          break;

        /* Space */
        case 32:
          handleEnterSpacePress(e);
          break;

        /* Enter */
        case 13:
          handleEnterSpacePress(e);
          break;

        /* Escape */
        case 27:
          handleEscapePress();
          break;

        default:
          break;
      }
    },
    [handleArrowUpPress, handleArrowDownPress, handleEnterSpacePress, handleEscapePress]
  );

  useEffect(() => {
    const triggerRefCurrent = triggerRef.current;

    if (triggerRefCurrent) {
      triggerRefCurrent.addEventListener('keydown', handleToggleBtnKeyPress);
    }

    return () => {
      if (triggerRefCurrent) {
        triggerRefCurrent.removeEventListener('keydown', handleToggleBtnKeyPress);
      }
    };
  }, [triggerRef, handleToggleBtnKeyPress]);

  return (
    <SOptionsBlock
      className={cn({ showOptions: show })}
      ref={(el: HTMLDivElement | null) => {
        optionsRef.current = el;
        wrongAnimationRef.current = el;
      }}
      tabIndex={-1}
      role="listbox"
      aria-labelledby="select-word-dropdown-btn"
    >
      {options.map((option: string, optionIndex: number) => (
        <SOptionItemButton
          key={optionIndex}
          data-value={option}
          onClick={optionClickHandler}
          role="option"
          tabIndex={currentFocusedIndex === optionIndex ? 0 : -1}
          className={cn({ isFocused: currentFocusedIndex === optionIndex })}
        >
          {option}
        </SOptionItemButton>
      ))}
    </SOptionsBlock>
  );
};

export default Options;

const SOptionsBlock = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: -50vh;
  background: var(--color-light-white);
  border: 2px solid var(--color-white);
  box-sizing: border-box;
  box-shadow: 0 0 60px rgba(1, 79, 127, 0.6);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  transition: 0.4s;

  &.showOptions {
    bottom: 0;

    @media (min-width: ${({ theme }) => theme.linguBptLg}) {
      display: flex;
      bottom: unset;
      top: 110%;
    }
  }

  @media (min-width: ${({ theme }) => theme.linguBptLg}) {
    display: none;
    position: absolute;
    width: 15ch;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
  }

  @keyframes wrongShakeAnimation {
    20% {
      margin-inline-start: -5px;
    }
    40% {
      margin-inline-start: 5px;
    }
    60% {
      margin-inline-start: -5px;
    }
    80% {
      margin-inline-start: 5px;
    }
    100% {
      margin-inline-start: 0;
    }
  }
  &.${WRONG_ANIMATION_CLASS_NAME} {
    animation: 0.8s wrongShakeAnimation;
  }
`;
const SOptionItemButton = styled.button`
  border: none;
  background: var(--color-light-white);
  color: #37295c;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  height: 3.75rem;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, white, #cccccc, white) 100% 1;
  outline-color: transparent !important;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: #e6e6f080;
  }

  &:focus {
    background: #e6e6f0cc;
  }

  &.isFocused {
    background: #e6e6f0cc;
  }
`;
