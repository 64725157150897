import { RefObject, useCallback, useEffect } from 'react';
import {
  IDisplayItem,
  IGameRoundAnswer,
  IPhrase,
  tCardRef,
  tStage,
  useFlashCard
} from '@adeptlms/lingu-students-react-shared';

interface IReceivedProps {
  words: IPhrase[];
  onRoundComplete?: (answer: IGameRoundAnswer) => void;
}

interface IReturnedValue {
  stage: tStage;
  handleStart: () => void;
  canGoPrev: boolean;
  handleClickPrev: () => Promise<void>;
  canGoNext: boolean;
  handleClickNext: () => Promise<void>;
  displayItems: IDisplayItem[];
  activeItem?: IPhrase;
  handleSwipeNext: () => void;
  hintVisible: boolean;
  shaking: boolean;
  round: number;
  refs: Record<string, RefObject<tCardRef>>;
  handleSkipAllRounds: () => void;
}

function useFlachCard({
  words,
  onRoundComplete = () => {}
}: IReceivedProps): IReturnedValue {
  const {
    stage,
    handleStart,
    canGoPrev,
    handleClickPrev,
    canGoNext,
    handleClickNext,
    displayItems,
    handleSwipeNext,
    hintVisible,
    shaking,
    round,
    refs,
    handleSkipAllRounds
  } = useFlashCard({ words, onRoundComplete, preferences: { trainingHints: true } });

  const handleKeyDownPress = useCallback(
    (e: KeyboardEvent) => {
      /* Arrow Left */
      if (e.keyCode === 37 && canGoPrev) {
        handleClickPrev();
      }

      /* Arrow Right */
      if (e.keyCode === 39 && canGoNext) {
        handleClickNext();
      }
    },
    [canGoPrev, canGoNext, handleClickNext, handleClickPrev]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDownPress);

    return () => {
      document.removeEventListener('keydown', handleKeyDownPress);
    };
  }, [handleKeyDownPress]);

  return {
    stage,
    handleStart,
    canGoPrev,
    handleClickPrev,
    canGoNext,
    handleClickNext,
    displayItems,
    handleSwipeNext,
    hintVisible,
    shaking,
    round,
    refs,
    handleSkipAllRounds
  };
}

export default useFlachCard;
