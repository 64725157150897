import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useAnimateScenarios } from 'students/views/shared/hooks';
import { HINT_TIMEOUT } from '../constants';
import { getFirstMistake } from './checkAnswer';

type tAnimationScenarioControls = ReturnType<typeof useAnimateScenarios>[1];

export function useHint({
  showHintPreference,
  correctItemSentence,
  currentAnswerInput,
  animateHintButtonsAnimationControls,
  validation
}: {
  showHintPreference?: boolean;
  correctItemSentence: string;
  validation: {
    caseSensitive: boolean;
    checkPunctuation: boolean;
  };
  currentAnswerInput: string;
  animateHintButtonsAnimationControls: tAnimationScenarioControls;
}): {
  tooltipVisible: boolean;
  hintButtonsBlockVisible: boolean;
  showHintButtonsBlock: () => void;
  showHintTooltip: () => void;
  currentHint: { correctPart: string; hintWord: string };
  startHintTimer: () => void;
  hideAndResetHint: () => void;
  hideHintButtonsBlock: () => void;
} {
  const idleTimeout = useRef<number | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [hintButtonsBlockVisible, setHintButtonsBlockVisible] = useState<boolean>(false);
  const { caseSensitive, checkPunctuation } = validation;

  useEffect(
    () => () => {
      idleTimeout.current && clearTimeout(idleTimeout.current);
    },
    []
  );
  const makeHintTooltipVisible = useCallback(() => {
    setTooltipVisible(true);
  }, [setTooltipVisible]);

  const hideHintTooltip = useCallback(() => {
    setTooltipVisible(false);
  }, [setTooltipVisible]);

  const showHintButtonsBlock = useCallback(() => {
    !hintButtonsBlockVisible && animateHintButtonsAnimationControls(['appear']);
    setHintButtonsBlockVisible(true);
  }, [animateHintButtonsAnimationControls, hintButtonsBlockVisible]);

  const hideHintButtonsBlock = useCallback(() => {
    hintButtonsBlockVisible && animateHintButtonsAnimationControls(['disappear']);
    setHintButtonsBlockVisible(false);
  }, [animateHintButtonsAnimationControls, hintButtonsBlockVisible]);

  const hideAndResetHint = useCallback(() => {
    hideHintTooltip();
    idleTimeout.current && clearTimeout(idleTimeout.current);

    idleTimeout.current = window.setTimeout(() => {
      showHintButtonsBlock();
    }, HINT_TIMEOUT);
  }, [hideHintTooltip, showHintButtonsBlock]);

  const startHintTimer = useCallback(() => {
    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current);
    }
    if (showHintPreference) {
      idleTimeout.current = window.setTimeout(() => {
        showHintButtonsBlock();
      }, HINT_TIMEOUT);
    }
  }, [showHintPreference, showHintButtonsBlock]);

  const currentHint = useMemo(() => {
    if (!tooltipVisible) {
      return {
        correctPart: '',
        hintWord: ''
      };
    }
    const { correctPart, nextPartHint } = getFirstMistake({
      input: currentAnswerInput,
      caseSensitive,
      checkPunctuation,
      correctAnswer: correctItemSentence
    });

    return {
      correctPart,
      hintWord: nextPartHint ?? ''
    };
  }, [
    caseSensitive,
    checkPunctuation,
    correctItemSentence,
    currentAnswerInput,
    tooltipVisible
  ]);

  const showHintTooltip = useCallback(() => {
    idleTimeout.current && clearTimeout(idleTimeout.current);
    makeHintTooltipVisible();
  }, [makeHintTooltipVisible]);

  return {
    tooltipVisible,
    hintButtonsBlockVisible,
    showHintButtonsBlock,
    showHintTooltip,
    currentHint,
    startHintTimer,
    hideAndResetHint,
    hideHintButtonsBlock
  };
}
