import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { useTaskAudioPlayer } from 'students/views/shared/bundles/audio/hooks';
import {
  AudioTags,
  tTaskAudioManagerItems
} from 'students/views/shared/bundles/audio/models';

import mp3MatchCorrect from 'students/views/shared/assets/match_correct.mp3';
import mp3MatchIncorrect from 'students/views/shared/assets/match_incorrect.mp3';
import { PreferencesContext } from 'students/views/shared/providers/Preferences';

export const useContainerDimensions = (
  myRef: React.RefObject<HTMLElement>,
  monitorResize = false
): { width: number; height: number } => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth || 0,
      height: myRef.current?.offsetHeight || 0
    });
    const handleResize = () => {
      if (myRef.current && monitorResize) setDimensions(getDimensions());
    };
    if (myRef.current) setDimensions(getDimensions());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef, monitorResize]);
  return dimensions;
};

export const useHintVisibility = (
  shouldAppear = true,
  hintTimeout = 5000
): [boolean, (timeout?: number) => void] => {
  const [showHint, setShowHint] = useState(false);
  const { hintsEffects } = useContext(PreferencesContext);
  const hintWasShownRef = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const hintTimeoutRef = useRef(hintTimeout);

  const updateLastInteractionTime = useCallback(
    (timeout = 5000) => {
      hintTimeoutRef.current = timeout;
      showHint && setShowHint(false);
    },
    [showHint]
  );

  useEffect(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);

    if (!(shouldAppear && hintsEffects) || hintWasShownRef.current) return undefined;

    timeoutRef.current = window.setTimeout(() => {
      setShowHint(true);
    }, hintTimeoutRef.current);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [shouldAppear, hintsEffects]);

  useEffect(() => {
    if (showHint) {
      hintWasShownRef.current = true;
    }
  }, [showHint]);

  return [showHint, updateLastInteractionTime];
};

export interface IGameAudioPlayer {
  playIncorrectSound: () => void;
  playCorrectSound: () => void;
}

const defaultAudioItems: tTaskAudioManagerItems = [
  {
    id: 'correct',
    tags: [AudioTags.SoundEffects],
    src: mp3MatchCorrect,
    caching: true,
    volume: 0.1
  },
  {
    id: 'incorrect',
    tags: [AudioTags.SoundEffects],
    src: mp3MatchIncorrect,
    caching: true,
    volume: 0.1
  }
];

export function useGameAudioPlayer(
  audioItems: tTaskAudioManagerItems = defaultAudioItems
): IGameAudioPlayer {
  const { playById } = useTaskAudioPlayer(audioItems);

  const playCorrectSound = useCallback(() => {
    playById('correct');
  }, [playById]);

  const playIncorrectSound = useCallback(() => {
    playById('incorrect');
  }, [playById]);

  return { playCorrectSound, playIncorrectSound };
}
