import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import {
  IGameRoundAnswer,
  tGameDifficulty,
  tWordWaterfallData
} from '@adeptlms/lingu-students-react-shared';
import { customMediaQuery } from 'students/views/shared/styled';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import DashedPagination from 'students/views/shared/components/DashedPagination';
import HandHint from 'students/views/pages/Games/common/HandHint';
import Picture from 'students/views/shared/components/Picture';
import { t, Translate } from 'i18n';

import { useWordWaterfall, BACKGROUND_STEP, themes, unShuffled } from './hooks';
import {
  BackgroundDiv,
  BackgroundImage,
  Container,
  StretchingDiv,
  wordPadding
} from './styled';
import GameCloseButton from '../common/GameCloseButton';
import GameStartScreen from '../common/GameStartScreen';
import GameFinishButton from '../common/GameFinishButton';
import hintAnimation from './assets/hint-animation.json';
import background from './assets/bg-image.png';
import backgroundWebp from './assets/bg-image.webp';

export interface IProps {
  taskId: number;
  rounds: tWordWaterfallData;
  difficulty?: tGameDifficulty;
  onRoundComplete?: (answer: IGameRoundAnswer) => void;
  onFinish?: () => void;
  className?: string;
}

const WordWaterfall: React.FC<IProps> = ({
  taskId,
  rounds,
  difficulty = 'easy',
  onRoundComplete = () => {},
  onFinish = () => {},
  className
}) => {
  const {
    containerElRef,
    theme,
    backgroundEl,
    circleEl,
    controls,
    bgControls,
    stage,
    setStage,
    roundData,
    question,
    words,
    containerWidth,
    shuffled,
    onWordClick,
    boxVariants,
    onWordDropped,
    hintWordVisible,
    hintVisible,
    goToRound,
    handleNext,
    handlePrev,
    round
  } = useWordWaterfall({
    rounds,
    difficulty,
    onRoundComplete
  });

  if (!rounds.length) return null;

  return (
    <Container style={{ height: '100vh' }} ref={containerElRef} className={cn(className)}>
      <BackgroundDiv>
        <StretchingDiv className={`gradient-background ${theme}`} ref={backgroundEl} />
        <CircleDiv className="circle-gradient" ref={circleEl} animate={controls} />
        <BackgroundImage
          $topIndent={rounds.length * BACKGROUND_STEP}
          animate={bgControls}
          as={motion.div}
        >
          <Picture lazy src={background} srcSet={backgroundWebp} />
        </BackgroundImage>
      </BackgroundDiv>
      {stage === 'start' ? (
        <div className="foreground-wrapper">
          <GameStartScreen
            heading={t('frontend.games.word_waterfall.start_heading')}
            onStart={() => setStage('inGame')}
          />
        </div>
      ) : (
        <>
          <AnimatePresence>
            {stage === 'inGame' && roundData && (
              <motion.div
                className="foreground-wrapper"
                key={roundData.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  className="question-wrapper title-heading"
                  initial={{ top: '15%' }}
                  animate={{ top: '10%' }}
                  exit={{ top: '15%' }}
                  transition={{ duration: 0.5 }}
                >
                  <SPhraseIcon
                    text={question.text}
                    imageUrl={question.imageURL}
                    animationUrl={question.animationURL}
                    colorRequired={question.colorRequired}
                    iconClassName="waterfall-icon"
                  />
                </motion.div>
                <div className="words-wrapper">
                  {words.map(({ word, id: wordId }, i) => {
                    const buttonTheme = themes[i % themes.length];
                    let gap = 100;
                    if (containerWidth) gap = Math.min(gap, containerWidth / 2 / 2.5);
                    let offsetX = shuffled[i % unShuffled.length] * gap;
                    if (containerWidth) {
                      if (offsetX <= -containerWidth / 2 + 10) {
                        offsetX = -containerWidth / 2 + 10;
                      } else if (
                        offsetX + word.length * 10 + wordPadding.horizontal * 2 >
                        containerWidth / 2
                      ) {
                        offsetX =
                          containerWidth / 2 -
                          word.length * 10 -
                          wordPadding.horizontal * 2;
                      }
                    }
                    return (
                      <motion.div
                        className={`word-block ${buttonTheme}`}
                        key={wordId}
                        onTap={(e, info) => onWordClick(e, info, word, buttonTheme)}
                        variants={boxVariants}
                        custom={{ index: i, word }}
                        animate="dropping"
                        style={{ left: offsetX }}
                        onAnimationComplete={() => onWordDropped(i)}
                      >
                        {word}
                        {hintWordVisible && hintVisible && roundData.answer === word && (
                          <HandHint
                            top="0"
                            left="30%"
                            animationData={hintAnimation}
                            zIndex={999}
                          />
                        )}
                      </motion.div>
                    );
                  })}
                </div>
              </motion.div>
            )}
            {stage !== 'inGame' && (
              <motion.div
                key="center"
                initial={{ scale: 1.5 }}
                animate={{ scale: 1 }}
                exit={{ opacity: 0 }}
                className="foreground-wrapper"
              >
                {['wrong', 'timeout'].indexOf(stage) > -1 && (
                  <GameCloseButton onTap={() => goToRound('next')} />
                )}
                {stage === 'finished' && (
                  <>
                    <p className="subtitle-heading final-message">
                      <Translate str="frontend.games.finished.go_to_next_exercise" />
                    </p>
                    <div style={{ margin: 0 }}>
                      <GameFinishButton onClickAnimationComplete={onFinish} />
                    </div>
                  </>
                )}
                {stage === 'wrong' && (
                  <>
                    <p style={{ margin: '16px 0' }} className="subtitle-heading">
                      {roundData.answer}
                    </p>
                    <p style={{ margin: 0 }} className="sub-heading">
                      {question.text}
                    </p>
                  </>
                )}
                {stage === 'timeout' && (
                  <p className="desc-heading">
                    <Translate str="frontend.games.word_waterfall.do_not_hesitate" />
                    <br />
                    <Translate str="frontend.games.word_waterfall.select_correct_translation" />
                  </p>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <div className="game-progress-bar">
            <DashedPagination
              itemsNumber={rounds.length}
              currentIndex={round}
              taskId={taskId}
              onNextItem={handleNext}
              onPrevItem={handlePrev}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default WordWaterfall;

const CircleDiv = motion(StretchingDiv, { forwardMotionProps: true });

const SPhraseIcon = styled(PhraseIcon)`
  .waterfall-icon {
    min-width: 100px !important;
    min-height: 100px !important;
    width: 100px !important;
    height: 100px !important;

    ${customMediaQuery('tablet')} {
      min-width: 160px !important;
      min-height: 160px !important;
      width: 160px !important;
      height: 160px !important;
    }
  }
`;
