import React, { useMemo, useCallback, useRef } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { tAnswer, tPlayTaskType } from '@adeptlms/lingu-students-react-shared';
import Prompt from 'students/views/shared/components/Prompt';
import useHeartBeatPing from 'students/views/shared/components/HeartBeat/useHeartBeatPing';
import { useExitPrompt, usePromptContext } from 'students/views/shared/hooks';

import { playTaskMap } from './Tasks';
import TaskTransitioner from './TaskTransitioner';
import useTaskTransitioner from './useTaskTransitioner';
import { PlayPage } from './common/components';
import { PLAY_PAGE_DEFAULT_COLOR } from './common/components/PlayPage';
import { tSubjectComponentProps } from '../types';
import NotSupportedTask from '../../NotSupportedTask';

export interface IPlaySubjectComponentProps extends tSubjectComponentProps {
  task: tPlayTaskType;
}

const PlayTaskContainer: React.FC<IPlaySubjectComponentProps> = ({
  task,
  taskSession,
  lessonPhrases,
  isLoading,
  isCompleting,
  onNext,
  onFinish,
  onFinishAndNext,
  onSubmitTaskItem
}) => {
  const TaskComponent = playTaskMap.get(task.type);
  const prevTaskIdRef = useRef(0);
  const {
    startButtonPositioned,
    setStartButtonPositioned,
    showBubbleAnimation,
    bubbleChanger
  } = useTaskTransitioner();

  const pingAction = useHeartBeatPing();
  const { isDirty, makeDirty } = usePromptContext();

  useExitPrompt(isDirty, pingAction);

  const handleNext = useCallback(() => {
    if (!isCompleting) {
      makeDirty(false);
      onNext();
      prevTaskIdRef.current = task.id;
      setStartButtonPositioned(false);
    }
  }, [isCompleting, onNext, task.id, setStartButtonPositioned, makeDirty]);

  const handleSkip = useCallback(() => {
    if (!isCompleting) {
      makeDirty(false);
      onFinishAndNext(taskSession?.taskItemSessions);
      prevTaskIdRef.current = task.id;
      setStartButtonPositioned(false);
    }
  }, [
    isCompleting,
    onFinishAndNext,
    task.id,
    taskSession,
    setStartButtonPositioned,
    makeDirty
  ]);

  const handleFinish = useCallback(
    (answers: tAnswer) => {
      if (!isCompleting) {
        makeDirty(false);
        onFinish(answers);
      }
    },
    [isCompleting, onFinish, makeDirty]
  );

  const taskBody = useMemo(() => {
    let currentTaskBody: JSX.Element | null;
    if (isLoading) {
      currentTaskBody = null;
    } else {
      currentTaskBody = (
        <>
          {TaskComponent ? (
            <TaskComponent
              className="ln-grow"
              key={task.id}
              lessonPhrases={lessonPhrases}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              task={task}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              taskSession={taskSession}
              isCompleting={!!isCompleting}
              onFinish={handleFinish}
              onAnswer={onSubmitTaskItem}
              onNext={handleNext}
              onSkip={handleSkip}
            />
          ) : (
            <NotSupportedTask
              color={PLAY_PAGE_DEFAULT_COLOR}
              isCompleting={!!isCompleting}
              onNext={handleSkip}
            />
          )}

          {startButtonPositioned && bubbleChanger}
        </>
      );
    }

    return currentTaskBody;
  }, [
    TaskComponent,
    handleNext,
    handleSkip,
    handleFinish,
    onSubmitTaskItem,
    isCompleting,
    isLoading,
    task,
    taskSession,
    lessonPhrases,
    startButtonPositioned,
    bubbleChanger
  ]);

  return (
    <>
      {isLoading && (
        <TaskTransitioner
          showBubbleAnimation={showBubbleAnimation}
          loadingOnly={task.type === 'Tasks::WordGames'}
        />
      )}

      <PlayPage
        backgroundComponent={TaskComponent?.backgroundComponent}
        className={cn({
          invisible:
            showBubbleAnimation &&
            prevTaskIdRef.current !== task.id &&
            !startButtonPositioned
        })}
      >
        <SPlayTaskContainer>{taskBody}</SPlayTaskContainer>
      </PlayPage>

      <Prompt />
    </>
  );
};

export default PlayTaskContainer;

const SPlayTaskContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
