import React from 'react';
import { IGameContainerProps } from 'students/views/pages/Games/types';
import MatchWord from './MatchWord';
import useRounds from './hooks/useRounds';

const MatchWordContainer: React.FC<IGameContainerProps> = ({
  taskId,
  phrases,
  onNext = () => {},
  onRoundComplete = () => {},
  className
}) => {
  const rounds = useRounds(phrases);
  if (!rounds?.length) {
    onNext();
    return null;
  }
  return (
    <MatchWord
      taskId={taskId}
      rounds={rounds}
      onRoundComplete={onRoundComplete}
      onFinish={onNext}
      className={className}
    />
  );
};

export default MatchWordContainer;
