import cn from 'classnames';
import { FC } from 'react';

interface ICheckIcon {
  color?: string;
  className?: string;
}

const CrossIcon: FC<ICheckIcon> = ({ color, className }) => (
  <svg
    className={cn(className)}
    width="18"
    height="18"
    viewBox="0 0 22 22"
    fill={color || 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5872 3.14225C17.2382 2.80154 16.6722 2.80154 16.3232 3.14225L3.13882 16.3266C2.80155 16.6759 2.80269 17.2392 3.14225 17.5871L4.37613 18.851C4.71684 19.2 5.26924 19.2001 5.60998 18.8511L18.8511 5.61C19.2001 5.26926 19.2001 4.71684 18.8511 4.37613L17.5872 3.14225Z"
      fill="inherit"
    />
    <path
      d="M4.41282 3.14225C4.76184 2.80154 5.32776 2.80154 5.67678 3.14225L18.8612 16.3266C19.1985 16.6759 19.1973 17.2392 18.8577 17.5871L17.6239 18.851C17.2832 19.2 16.7308 19.2001 16.39 18.8511L3.14889 5.61C2.79988 5.26926 2.79988 4.71684 3.14889 4.37613L4.41282 3.14225Z"
      fill="inherit"
    />
  </svg>
);

export default CrossIcon;
