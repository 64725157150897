import { useEffect } from 'react';

export const PLAYER_ID = 'minimal-pairs-video-player';

interface IReceivedValue {
  isPlaying: boolean;
  onVideoWaiting: () => void;
  onVideoPlaying: () => void;
}

function useVideoLoading({
  isPlaying,
  onVideoPlaying,
  onVideoWaiting
}: IReceivedValue): void {
  useEffect(() => {
    if (isPlaying) {
      const videoPlayerElement = document.getElementById(PLAYER_ID);

      if (
        videoPlayerElement?.firstChild &&
        videoPlayerElement.firstChild.nodeName === 'VIDEO'
      ) {
        videoPlayerElement.firstChild.addEventListener('waiting', onVideoWaiting);
        videoPlayerElement.firstChild.addEventListener('playing', onVideoPlaying);
      }

      return () => {
        if (
          videoPlayerElement?.firstChild &&
          videoPlayerElement.firstChild.nodeName === 'VIDEO'
        ) {
          videoPlayerElement.firstChild.removeEventListener('waiting', onVideoWaiting);
          videoPlayerElement.firstChild.removeEventListener('playing', onVideoPlaying);
        }
      };
    }
    return undefined;
  }, [isPlaying, onVideoWaiting, onVideoPlaying]);
}

export default useVideoLoading;
