import { IGameRoundAnswer, IPhrase } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import { Translate, t } from 'i18n';
import React from 'react';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import CircleButton from 'students/views/shared/components/CircleButton';
import DashedPagination from 'students/views/shared/components/DashedPagination';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import styled from 'styled-components';
import GameFinishButton from '../common/GameFinishButton';
import GameStartScreen from '../common/GameStartScreen';
import { SwipeCard } from './SwipeCard';
import WordList from './WordList';
import arrowLeftIcon from './assets/arrow_left.svg';
import arrowRightIcon from './assets/arrow_right.svg';
import googleLogo from './assets/google_logo.svg';
import useFlashCard from './hooks/useFlashCard';
import {
  SButtonContainer,
  SCardContainer,
  SContainer,
  SHeader,
  SNextButtonContainer,
  SProgressBar,
  STranslatedByLabel
} from './styled';

interface IProps {
  taskId: number;
  words: IPhrase[];
  onRoundComplete?: (answer: IGameRoundAnswer) => void;
  onExit?: () => void;
  onNext?: () => void;
  className?: string;
}
const Flashcard: React.FC<IProps> = ({
  taskId,
  words,
  onRoundComplete = () => {},
  onNext = () => {},
  className
}) => {
  const {
    stage,
    handleStart,
    canGoPrev,
    handleClickPrev,
    canGoNext,
    handleClickNext,
    displayItems,
    handleSwipeNext,
    hintVisible,
    shaking,
    round,
    refs
  } = useFlashCard({ words, onRoundComplete });

  return (
    <SContainer className={cn(className)}>
      {stage === 'start' && (
        <GameStartScreen
          heading={t('frontend.games.flashcard.check_out_new_words')}
          onStart={handleStart}
        />
      )}

      {stage === 'finished' && (
        <>
          <div className="ln-w-full ln-px-4">
            <WordList words={words} />
          </div>
          <SNextButtonContainer>
            <GameFinishButton
              onClickAnimationComplete={onNext}
              title={t('frontend.games.flashcard.buttons.finish')}
            />
          </SNextButtonContainer>
        </>
      )}

      {stage === 'inGame' && (
        <>
          <SHeader>
            <p>
              <Translate str="frontend.games.flashcard.check_out_new_words" />
            </p>
          </SHeader>

          <SCardContainer>
            <SButtonContainer>
              <SPrevButton
                title={t('frontend.games.flashcard.buttons.previous')}
                iconUrl={arrowLeftIcon}
                disabled={!canGoPrev}
                onClick={handleClickPrev}
                size="56px"
                shadowColor="none"
                bgColor="transparent"
                iconColor="#fff"
                className="dir-auto"
              />

              <SNextButton
                title={t('frontend.games.flashcard.buttons.next')}
                iconUrl={arrowRightIcon}
                disabled={!canGoNext}
                onClick={handleClickNext}
                size="56px"
                shadowColor="none"
                bgColor="transparent"
                iconColor="#fff"
                className="dir-auto"
              />
            </SButtonContainer>

            <SCardWrap>
              {/* Current item should be on top, next one under current, previous doesn't matter */}
              {[...displayItems].reverse().map((item) => (
                <SwipeCard
                  key={item.word.id}
                  hasImage={item.hasImage}
                  isActive={item.itemType === 'current'}
                  isPrev={item.itemType === 'prev'}
                  onSwipe={handleSwipeNext}
                  phrase={item.word}
                  round={round}
                  ref={refs[item.itemType]}
                  canGoNext={canGoNext}
                  hintVisible={hintVisible}
                  shaking={shaking}
                />
              ))}
            </SCardWrap>

            <STranslatedByLabel>
              <Translate str="frontend.lesson_task.tasks.learn.translatable_text.translated_by" />

              <UrlIcon url={googleLogo} height="18px" width="56px" />
            </STranslatedByLabel>
          </SCardContainer>

          <SProgressBar>
            <DashedPagination
              itemsNumber={words.length}
              currentIndex={round}
              taskId={taskId}
              onNextItem={handleClickNext}
              onPrevItem={handleClickPrev}
            />
          </SProgressBar>
        </>
      )}
    </SContainer>
  );
};

export default Flashcard;

const SNextButton = styled(CircleButton)`
  ${styleInnerButton()} {
    border: 2px solid var(--color-white);
    color: var(--color-white);
  }
`;

const SPrevButton = styled(SNextButton)`
  transform: rotate(180deg);
`;

const SCardWrap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
