import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { PreferencesContext } from 'students/views/shared/providers/Preferences';

export const useHintVisibility = (
  shouldAppear = true,
  hintTimeout = 5000
): [boolean, (timeout?: number) => void, (isVisible: boolean) => void] => {
  const [showHint, setShowHint] = useState(false);
  const { hintsEffects } = useContext(PreferencesContext);
  const hintWasShownRef = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const hintTimeoutRef = useRef(hintTimeout);

  const createTimer = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      setShowHint(true);
    }, hintTimeoutRef.current);
  }, []);

  const clearTimer = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const updateLastInteractionTime = useCallback(
    (timeout = 5000) => {
      hintTimeoutRef.current = timeout;
      clearTimer();
      createTimer();
      showHint && setShowHint(false);
    },
    [showHint, clearTimer, createTimer]
  );

  useEffect(() => {
    clearTimer();

    if (!shouldAppear || !hintsEffects || hintWasShownRef.current) return undefined;

    createTimer();

    return () => {
      clearTimer();
    };
  }, [shouldAppear, hintsEffects, createTimer, clearTimer]);

  useEffect(() => {
    if (showHint) {
      hintWasShownRef.current = true;
    }
  }, [showHint]);

  const setHintVisibility = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        setShowHint(true);
      } else {
        setShowHint(false);
        clearTimer();
      }
    },
    [clearTimer, setShowHint]
  );

  return [showHint, updateLastInteractionTime, setHintVisibility];
};

type tColors = 'green' | 'orange' | 'pink' | 'red' | 'blue';
const BACKGROUND_GRADIENTS_CLASSNAMES: Array<tColors> = [
  'green',
  'orange',
  'pink',
  'red',
  'blue'
];

export const ANSWER_BLOCK_ADDITIONAL_HEIGHT = 40;

export function getBackgroundGradientClassname(position: number): tColors {
  return BACKGROUND_GRADIENTS_CLASSNAMES[
    position % BACKGROUND_GRADIENTS_CLASSNAMES.length
  ];
}
