export default function shuffle<T>(array: Array<T>): Array<T> {
  const result = [...array]
  let currentIndex = result.length;

  while (currentIndex != 0) {

    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [result[currentIndex], result[randomIndex]] = [
      result[randomIndex], result[currentIndex]];
  }

  return result
}
