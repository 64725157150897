import { RefObject, useCallback, useRef, useState } from 'react';
import ZoomJS from 'zooming';

const ZOOMABLE_IMG_CLASSNAME = 'zoomable-img';

interface IReceivedProps {
  onBeforeOpen?: () => void;
  onBeforeClose?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

interface IReturnedValue {
  contentRef: RefObject<HTMLDivElement>;
  backdropVisible: boolean;
  setElementRefAndInitialize: (ref: HTMLDivElement) => void;
}

function useZoomableImage({
  onBeforeOpen,
  onBeforeClose,
  onOpen,
  onClose
}: IReceivedProps): IReturnedValue {
  const [backdropVisible, setBackdropVisible] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [elementRef, setElementRef] = useState<HTMLDivElement | null>(null);

  const initializeZoom = useCallback(() => {
    if (!elementRef) {
      return;
    }

    const zooming = new ZoomJS({
      bgOpacity: 0,
      scaleBase: 0.8,

      onBeforeOpen() {
        contentRef.current?.classList.add('no-overflow');
        setBackdropVisible(true);

        onBeforeOpen?.();
      },

      onBeforeClose() {
        setBackdropVisible(false);

        onBeforeClose?.();
      },

      onOpen() {
        onOpen?.();
      },

      onClose() {
        contentRef.current?.classList.remove('no-overflow');

        onClose?.();
      }
    });

    zooming.listen(`.${ZOOMABLE_IMG_CLASSNAME}`);
  }, [elementRef, onBeforeOpen, onBeforeClose, onOpen, onClose]);

  const populateHTMLWithZoomableClassname = useCallback(
    (renderedHtml: HTMLDivElement) => {
      renderedHtml.querySelectorAll('img').forEach((element) => {
        element.classList.add(ZOOMABLE_IMG_CLASSNAME);
      });
    },
    []
  );

  const setElementRefAndInitialize = useCallback(
    (ref: HTMLDivElement) => {
      if (ref) {
        setElementRef(ref);
        populateHTMLWithZoomableClassname(ref);
        initializeZoom();
      }
    },
    [initializeZoom, populateHTMLWithZoomableClassname]
  );

  return {
    contentRef,
    backdropVisible,
    setElementRefAndInitialize
  };
}

export default useZoomableImage;
