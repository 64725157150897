import { useEffect } from 'react';

export default function useFadingOnScroll(
  tableRefGetter: () => HTMLElement | null,
  itemsRefGetter: () => NodeListOf<HTMLElement>,
  initialCalc?: boolean
): void {
  useEffect(() => {
    const tableRef = tableRefGetter();
    const itemsRef = itemsRefGetter();

    if (!tableRef || !itemsRef) {
      return undefined;
    }

    const min = 0;
    const max = 1;
    const threshold = 0.01;

    const calcAndSetTopOpacity = (itemRef: HTMLElement) => {
      const windowTop = tableRef.scrollTop;
      const windowBottom = windowTop + tableRef.offsetHeight;
      const objectHeight = itemRef.offsetHeight;
      const objectTop =
        itemRef.getBoundingClientRect().top +
        windowTop -
        tableRef.getBoundingClientRect().top +
        10;
      const objectBottom =
        itemRef.getBoundingClientRect().top +
        windowTop +
        objectHeight -
        tableRef.getBoundingClientRect().top -
        10;

      if (objectTop < windowTop) {
        if (objectBottom > windowTop) {
          itemRef.style.opacity = String(
            min + (max - min) * ((objectBottom - windowTop) / objectHeight)
          );
        } else if (parseFloat(itemRef.style.opacity) || min + threshold <= 1) {
          itemRef.style.opacity = String(min);
        }
      } else if (objectBottom > windowBottom) {
        if (objectTop < windowBottom) {
          itemRef.style.opacity = String(
            min + (max - min) * ((windowBottom - objectTop) / objectHeight)
          );
        } else if (parseFloat(itemRef.style.opacity) || min + threshold <= 1) {
          itemRef.style.opacity = String(min);
        }
      } else if (parseFloat(itemRef.style.opacity) || max - threshold >= 1) {
        itemRef.style.opacity = String(max);
      }
    };

    const onScrollHandler = () => {
      itemsRef.forEach(calcAndSetTopOpacity);
    };

    initialCalc && onScrollHandler();

    tableRef.addEventListener('scroll', onScrollHandler);

    return () => {
      tableRef.removeEventListener('scroll', onScrollHandler);
    };
  }, [tableRefGetter, itemsRefGetter, initialCalc]);
}
