import {
  IFillInTableItem,
  IFillInTableTask,
  IFillInTableTaskItemSession,
  IFillInTableTaskSession,
  tFillInTableAnswer
} from '@adeptlms/lingu-students-react-shared';
import { t } from 'i18n';
import { FC, useEffect, useMemo, useState } from 'react';
import { usePromptContext } from 'students/views/shared/hooks';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { BaseCheckTaskBody, CheckNavFooter, SCheckTaskWrapper } from '../../components';
import { isLessonCompleted } from '../../helpers';
import { useTaskItems } from '../../useTaskItems';
import { ICheckTaskProps } from '../types';
import DemoStatementItem from './DemoStatementItem';
import StatementItem, { ISelectAnswerPayload } from './StatementItem';

const CheckFillInTableTask: FC<
  ICheckTaskProps<IFillInTableTask, IFillInTableTaskSession>
> = ({ task, taskSession, isCompleting, onNext }) => {
  const { items } = task;
  const { makeDirty } = usePromptContext();

  const [answers, setAnswers] = useState<tFillInTableAnswer>([]);
  const { taskItems, sessionTaskItems } = useTaskItems<
    IFillInTableItem,
    IFillInTableTaskItemSession
  >(items, taskSession?.taskItemSessions);

  const columnTitles = useMemo(
    () => ({
      h1:
        task.h1 ||
        t('frontend.lesson_task.tasks.check.fill_in_table.column_question_placeholder'),
      h2:
        task.h2 ||
        `${t(
          'frontend.lesson_task.tasks.check.fill_in_table.column_answer_placeholder'
        )} 1`,
      h3:
        task.h3 ||
        `${t(
          'frontend.lesson_task.tasks.check.fill_in_table.column_answer_placeholder'
        )} 2`
    }),
    [task]
  );

  const demoQuestion = useMemo(
    () =>
      task.hasDemo
        ? {
            question: task.questionDemo || '',
            column1Question: task.column1Demo || '',
            column2Question: task.column2Demo || '',
            ...(task.audioQuestionDemo && { questionAudio: task.audioQuestionDemo })
          }
        : null,
    [task]
  );

  useEffect(() => {
    if (sessionTaskItems.length) {
      setAnswers(sessionTaskItems);
    } else {
      setAnswers(
        taskItems.map((item, index) => ({
          completed: false,
          id: index,
          taskItemId: item.id,
          answers: Array.from({ length: task.columns }).map(() => ''),
          mistakesCount: 0,
          skipped: false,
          url: taskSession
            ? `/task_sessions/${taskSession.id}/task_item_sessions/${item.id}`
            : ''
        }))
      );
    }
  }, [taskSession, task, sessionTaskItems, taskItems]);

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { answers: userAnswers, itemId } = payload;

    const answerIndex = answers.findIndex((answer) => answer.taskItemId === itemId);
    if (answerIndex < 0) return;

    const answer = answers[answerIndex];

    setAnswers([
      ...answers.slice(0, answerIndex),
      {
        ...answer,
        answers: userAnswers,
        skipped: false
      },
      ...answers.slice(answerIndex + 1)
    ]);

    makeDirty(true);
  };

  const handleNext = () => {
    onNext(answers);
  };

  const handleSkip = () => {
    onNext(
      answers.map((answer: IFillInTableTaskItemSession) => ({
        ...answer,
        answers: [],
        skipped: true
      }))
    );
  };

  if (!answers.length) return null;

  return (
    <>
      <SCheckTaskWrapper>
        <BaseCheckTaskBody task={task}>
          <SColumnTitles dir="ltr">
            {Object.values(columnTitles)
              .slice(0, task.columns + 1)
              .map((title: string) => (
                <SColumnTitle key={title}>{title}</SColumnTitle>
              ))}
          </SColumnTitles>

          {demoQuestion && (
            <DemoStatementItem question={demoQuestion} columnTitles={columnTitles} />
          )}

          {taskItems.map((item: IFillInTableItem, itemIndex) => (
            <StatementItem
              key={item.id}
              item={{
                id: item.id,
                question: item.question,
                audioURL: item.audioURL
              }}
              columnTitles={columnTitles}
              columnsNumber={item.options.length}
              value={answers[itemIndex]?.answers}
              onSelectAnswer={handleSelectAnswer}
            />
          ))}
        </BaseCheckTaskBody>
      </SCheckTaskWrapper>

      <CheckNavFooter
        isCompleting={isCompleting}
        onNext={handleNext}
        onSkip={handleSkip}
        isLessonCompleted={isLessonCompleted(taskSession)}
      />
    </>
  );
};

export default CheckFillInTableTask;

const SColumnTitles = styled.div`
  display: none;

  ${customMediaQuery('tablet')} {
    display: grid;
    grid-template-rows: unset;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-block-end: 12px;
  }
`;

const SColumnTitle = styled.h2`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  color: var(--color-grey);
  margin-block: 0;
  margin-inline: 22px 0;
  padding: 0;
  text-transform: uppercase;

  &:first-child {
    margin: 0;
  }
`;
