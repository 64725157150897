import { tCardRef } from '@adeptlms/lingu-students-react-shared';
import {
  AnimationControls,
  MotionValue,
  PanInfo,
  Variants,
  useAnimation,
  useMotionValue,
  useTransform
} from 'framer-motion';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

const PAN_DELTA = 100;

interface IReturnUseSwipeCard {
  x: MotionValue<number>;
  rotate: MotionValue<number>;
  opacity: MotionValue<number>;
  handleDragEnd: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
  controls: AnimationControls;
  isContentHidden: boolean;
}

interface IUseSwipeCard {
  isActive: boolean;
  isPrev: boolean;
  forwardedRef?: React.ForwardedRef<tCardRef>;
  canGoNext: boolean;
  onSwipe: () => void;
}

export const useSwipeCard = ({
  isActive,
  isPrev,
  forwardedRef,
  canGoNext,
  onSwipe
}: IUseSwipeCard): IReturnUseSwipeCard => {
  const controls = useAnimation();
  const [isContentHidden, setContentHidden] = useState(false);
  const x = useMotionValue(0);
  const rotate = useTransform(x, [-PAN_DELTA, 0, PAN_DELTA], [20, 0, -20]);
  const opacity = useTransform(
    x,
    [-PAN_DELTA * 2, -PAN_DELTA, 0, PAN_DELTA, PAN_DELTA * 2],
    [0, 0.7, 1, 0.7, 0]
  );

  useImperativeHandle(
    forwardedRef,
    () => ({
      isActive,
      nextAnimation: () => controls.start('next'),
      prevAnimation: () => {
        setContentHidden(false);
        return controls.start('prev');
      },
      initialAnimation: () => controls.start('initial'),
      mountedAnimation: () => controls.start('mounted'),
      enterAnimation: () => controls.start('enter'),
      removeAnimation: () => controls.start('remove'),
      hideContent: () => {
        setContentHidden(true);
      }
    }),
    [controls, isActive]
  );

  useEffect(() => {
    if (isActive) {
      controls.start('enter');
    }
    if (isPrev) {
      controls.start('remove').then(() => {
        setContentHidden(true);
      });
    }
    if (!isActive && !isPrev) {
      controls.start('mounted');
    }
  }, [isActive, controls, isPrev, x, rotate]);

  const swipeItem = useCallback(
    (condition: boolean) => {
      if (condition && canGoNext) {
        controls.start('remove');
        onSwipe();
      } else {
        controls.start({ x: 0, y: 0 });
      }
    },
    [canGoNext, controls, onSwipe]
  );

  const handleDragEnd = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      swipeItem(
        info.offset.x < -PAN_DELTA ||
          info.offset.x > PAN_DELTA ||
          info.offset.y < -PAN_DELTA ||
          info.offset.y > PAN_DELTA
      );
    },
    [swipeItem]
  );

  return {
    x,
    rotate,
    opacity,
    handleDragEnd,
    controls,
    isContentHidden
  };
};

export const INITIAL_ROTATE = -8.72;
export const INITIAL_OPACITY = 0.2;

export const variants: Variants = {
  initial: {
    rotate: INITIAL_ROTATE,
    opacity: 0
  },
  mounted: {
    rotate: INITIAL_ROTATE,
    opacity: 0.2
  },
  enter: {
    x: 0,
    rotate: 0,
    opacity: 1,
    transition: { duration: 0.2 }
  },
  next: {
    x: -PAN_DELTA * 2,
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeIn' }
  },
  remove: {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      duration: 0.1
    }
  },
  prev: {
    x: [-PAN_DELTA * 2, 0],
    y: [0, 0], // user can swipe to all directions, so make [0, 0] to prevent buggy prev direction
    visibility: 'unset',
    opacity: [0, 1],
    rotate: [20, 0],
    pointerEvents: 'none',
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    },
    transitionEnd: {
      pointerEvents: 'auto'
    }
  }
};
