import styled from 'styled-components';

const SAnswerOptionIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.9375rem;
  background: var(--color-grey);
  color: var(--color-white);
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    width: inherit;
  }

  &.answer-green {
    background: var(--color-green);
  }

  &.answer-red {
    background: var(--color-red);
  }
`;

export default SAnswerOptionIconWrap;
