import cn from 'classnames';
import React, { MouseEvent, useState } from 'react';
import ButtonGeneral from 'students/views/shared/components/ButtonGeneral';
import {
  BUTTON_INNER_CLASS_NAME,
  styleInnerButton
} from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import styled from 'styled-components';

interface IProps {
  value: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  sourceKey: number;
}
const WordBlock: React.FC<IProps> = ({ value, onClick, selected, sourceKey }) => {
  const [randomAlignment] = useState(randomAlignSelf);
  return (
    <SWordRandomBlock>
      <SWordRandomInnerBlock alignSelf={randomAlignment}>
        <SWordButton
          aria-hidden={selected}
          className={cn({ hide: selected })}
          onClick={onClick}
          disabled={selected}
          data-source-index={sourceKey}
          data-value={value}
          title={value}
        >
          <SWord>{value}</SWord>
        </SWordButton>
      </SWordRandomInnerBlock>
    </SWordRandomBlock>
  );
};

export default WordBlock;

function randomAlignSelf() {
  const aligns = ['flex-start', 'center', 'flex-end'];
  return aligns[Math.floor(Math.random() * 3)];
}

const SWordRandomBlock = styled.div`
  display: flex;
  padding: 0.8rem;
  height: 5rem;
  box-sizing: content-box;

  @media (min-width: ${({ theme }) => theme.linguBptSm}) {
    padding: 1rem;
    height: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.linguBptMd}) {
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;
    height: 6rem;
  }
`;

const SWordRandomInnerBlock = styled.div<{ alignSelf: string }>`
  align-self: ${({ alignSelf }) => alignSelf};
`;

const SWordButton = styled(ButtonGeneral)`
  background-color: var(--color-white);
  color: #37295c;
  box-shadow: 0 4px 0 #00000035;
  border: none;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  border-radius: 10px;
  opacity: 1 !important;
  max-width: 290px;

  &.hide {
    opacity: 0 !important;
  }

  ${styleInnerButton()} {
    padding: 0.875rem;
  }

  &:hover {
    box-shadow: 0 0 20px rgba(46, 33, 98, 0.5), 0 8px 0 rgba(46, 33, 98, 0.5);
  }

  &:focus > .${BUTTON_INNER_CLASS_NAME} {
    box-shadow: 0 0 0 0.35rem rgba(0, 165, 215, 0.75);
  }

  @media (min-width: ${({ theme }) => theme.linguBptSm}) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

const SWord = styled.span`
  word-break: break-word;
`;
