import cn from 'classnames';
import { Translate } from 'i18n';
import { FC } from 'react';
import Picture from 'students/views/shared/components/Picture';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import oneStarIcon from '../../assets/one_star_icon.svg';
import threeStarIcon from '../../assets/three_stars_icon.svg';

interface IResultPointsMessage {
  points: number;
  className?: string;
}
// TODO use plurals in translations
const ResultPointsMessage: FC<IResultPointsMessage> = ({ points, className }) => (
  <SWrapper
    className={cn(className)}
    data-testid="students/components/Leaderboard/ResultPointsMessage"
  >
    <SPicture src={points > 0 ? threeStarIcon : oneStarIcon} alt="stars-icon" />

    <SMessage>
      <Translate
        str={
          points > 0
            ? 'frontend.leaderboard.well_done_you_have_earned'
            : 'frontend.leaderboard.it_did_not_work_out_this_time'
        }
      />
    </SMessage>

    <SPointsText>
      {points > -1 ? points : 0}{' '}
      <Translate
        str={points === 1 ? 'frontend.leaderboard.point' : 'frontend.leaderboard.points'}
      />
    </SPointsText>
  </SWrapper>
);

export default ResultPointsMessage;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${customMediaQuery('tablet')} {
    max-width: 540px;
    margin: 50px auto 0;
  }
`;

const SPicture = styled(Picture)`
  margin-block-end: 20px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 30px;
  }

  width: auto;
  object-fit: cover;
  border-radius: 14px;
  max-width: 100%;
  max-height: 16.5rem;
`;

const SMessage = styled.p`
  color: var(--color-light-white);
  letter-spacing: -0.035em;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin: 0 0 12px;
  padding: 0;
  text-align: center;

  ${customMediaQuery('tablet')} {
    font-size: 1.625rem;
    line-height: 2rem;
    margin: 0 0 20px;
  }
`;

const SPointsText = styled.h1`
  color: rgb(254, 208, 45); /* Fallback */

  /* Text color gradient props */
  background: -webkit-linear-gradient(rgb(254, 208, 45), rgb(239, 129, 27));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  letter-spacing: -0.035em;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 3rem;
  margin: 0;
  padding: 0;
  text-align: center;
  filter: drop-shadow(0 3px 0 rgba(0, 0, 0, 0.2));

  ${customMediaQuery('tablet')} {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;
