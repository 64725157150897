import { lessonSelectors } from '@adeptlms/lingu-students-react-shared';
import { Translate } from 'i18n';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import ArrowRightIcon from 'students/views/shared/assets/icons/arrow_right_icon.svg';
import { styleInnerButton } from 'students/views/shared/components/ButtonGeneral/ButtonGeneral';
import { Spinner } from 'students/views/shared/components/Spinner';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { isLightFontNeeded } from 'students/views/shared/helpers';
import { customMediaQuery } from 'students/views/shared/styled';
import { SPrimaryButton, STransparentButton } from 'students/views/shared/styled/SButton';
import styled from 'styled-components';

interface IProps {
  onNext?: () => void;
  onSkip?: () => void;
  isCompleting: boolean;
  isLessonCompleted?: boolean;
}
const CheckNavFooter: FC<IProps> = ({
  onNext,
  onSkip,
  isCompleting,
  isLessonCompleted
}) => {
  const [nextBtnCompleting, setNextBtnCompleting] = useState<boolean>(false);
  const [skipBtnCompleting, setSkipBtnCompleting] = useState<boolean>(false);
  const lesson = useTypedSelector(lessonSelectors.selectLesson);
  const background = lesson?.color;
  const isDarkFont = useMemo(
    () => (background ? !isLightFontNeeded(background) : false),
    [background]
  );

  useEffect(() => {
    if (!isCompleting) {
      setNextBtnCompleting(false);
      setSkipBtnCompleting(false);
    }
  }, [isCompleting]);

  const handleNext = () => {
    onNext?.();
    setNextBtnCompleting(true);
  };

  const handleSkip = () => {
    onSkip?.();
    setSkipBtnCompleting(true);
  };

  const renderNextBtnContent = () =>
    nextBtnCompleting ? (
      <Spinner />
    ) : (
      <>
        <Translate str="frontend.lesson_task.check.navigation_next" />

        <SIcon
          className="dir-auto"
          url={ArrowRightIcon}
          height="1.5rem"
          width="1.5rem"
          color="#0B1420"
        />
      </>
    );

  const renderSkipBtnContent = () =>
    skipBtnCompleting ? (
      <Spinner />
    ) : (
      <Translate str="frontend.lesson_task.check.navigation_skip" />
    );

  return (
    <SCheckNavFooterWrapper>
      {isLessonCompleted ? (
        <SText
          style={{
            color: isDarkFont ? 'var(--color-black)' : 'var(--color-light-white)'
          }}
        >
          <Translate str="frontend.lesson_task.check.restart_the_lesson_to_submit_new_answer" />
        </SText>
      ) : null}

      <SCheckNavFooterButtons>
        {onSkip ? (
          <SButtonWrapper>
            <SSecondaryBtn
              data-testid="students/components/TaskSubjects/Check/NavFooter/SkipButton"
              disabled={isCompleting}
              onClick={handleSkip}
            >
              {renderSkipBtnContent()}
            </SSecondaryBtn>
          </SButtonWrapper>
        ) : null}

        {onNext ? (
          <SButtonWrapper>
            <SPrimaryBtn
              data-testid="students/components/TaskSubjects/Check/NavFooter/NextButton"
              disabled={isCompleting || isLessonCompleted}
              onClick={handleNext}
            >
              {renderNextBtnContent()}
            </SPrimaryBtn>
          </SButtonWrapper>
        ) : null}
      </SCheckNavFooterButtons>
    </SCheckNavFooterWrapper>
  );
};

export default CheckNavFooter;

const SCheckNavFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SText = styled.span`
  color: var(--color-gray-900);
  text-align: center;
`;

const SCheckNavFooterButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  & > div {
    margin: 0 7.5px;
  }

  ${customMediaQuery('tablet')} {
    & > div {
      margin: 0 16px;
    }
  }
`;

const SPrimaryBtn = styled(SPrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);

  ${styleInnerButton()} {
    padding: 0 2rem;
  }
`;

const SSecondaryBtn = styled(STransparentButton)`
  font-weight: 400;

  ${styleInnerButton()} {
    padding: 0 2rem;
  }
`;

const SButtonWrapper = styled.div`
  ${customMediaQuery('mobile')} {
    min-width: 0;

    & > * {
      max-width: 100%;
    }
  }

  & > * {
    font-size: 0.875rem;
    min-height: 3rem;

    ${customMediaQuery('tablet')} {
      font-size: 1.375rem;
      min-height: 3.75rem;
      width: 100%;
      min-width: 180px;
    }
  }
`;

export const SIcon = styled(UrlIcon)`
  margin-inline-start: 0.75rem;
  margin-inline-end: -0.75rem;
`;
