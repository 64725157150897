import { AnimatePresence, LayoutGroup, motion, Variants } from 'framer-motion';
import { FC } from 'react';
import PlayIcon from 'students/views/shared/assets/icons/play_icon.svg';
import ReplayIcon from 'students/views/shared/assets/icons/replay_icon.svg';
import speakerIcon from 'students/views/shared/assets/icons/speaker.svg';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import styled from 'styled-components';

import { Translate } from 'i18n';
import { VideoPlayer } from 'students/views/shared/components/MediaPlayer';
import { Spinner } from 'students/views/shared/components/Spinner';
import { customMediaQuery } from 'students/views/shared/styled';

import cn from 'classnames';
import OptionButton from './components/OptionButton';
import useMinimalPairsItem, {
  IMinimalPairsItem,
  ISelectAnswerPayload
} from './hooks/useMinimalPairsItem';
import { PLAYER_ID } from './hooks/useVideoLoading';

export type { ISelectAnswerPayload };

const MinimalPairsItem: FC<IMinimalPairsItem> = ({ item, onSelectAnswer }) => {
  const { question, options } = item;

  const {
    taskAnimationControl,
    ref,
    isLoading,
    isPlaying,
    videoHasError,
    isVideoUnsupported,
    handlePlay,
    isMediaWatched,
    handleMediaEnd,
    handleVideoError,
    handleVideoPlayerError,
    handlePause,
    url,
    handleMediaReady,
    handleOptionClick,
    correctOption,
    currentWrongOptions,
    isAudio
  } = useMinimalPairsItem(item, onSelectAnswer);

  const videoContainer = (() => {
    if (isVideoUnsupported) {
      return (
        <SErrorMessage>
          <Translate str="frontend.lesson_task.tasks.play.minimal_pairs.video_not_supported" />
        </SErrorMessage>
      );
    } else {
      return (
        <>
          {!isPlaying && !videoHasError && !isLoading ? (
            <SReplay
              variants={replayVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              onClick={handlePlay}
              as={motion.button}
            >
              <UrlIcon
                url={isMediaWatched ? ReplayIcon : PlayIcon}
                color="#FBFCFF"
                height="77px"
                width="77px"
              />
            </SReplay>
          ) : null}
          <VideoPlayer
            onEnded={handleMediaEnd}
            onError={handleVideoError}
            onVideoPlayerError={handleVideoPlayerError}
            onPause={handlePause}
            width="100%"
            height="100%"
            playing={isPlaying}
            url={url}
            onReady={handleMediaReady}
            volume={1}
            playsinline
            id={PLAYER_ID}
          />
        </>
      );
    }
  })();

  return (
    <LayoutGroup>
      <STaskItemWrapper
        layout
        initial="hidden"
        animate={taskAnimationControl}
        variants={taskVariants}
        as={motion.div}
      >
        <AnimatePresence>
          <SPlayerWrapper
            ref={ref}
            key="player"
            layout="position"
            as={motion.div}
            className={cn({ onlyAudio: isAudio })}
          >
            {isLoading ? <SSpinner /> : null}

            {videoContainer}
          </SPlayerWrapper>

          {isMediaWatched && (
            <SQuestionBlock
              key="questions"
              transition={commonTransition}
              variants={blockVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              as={motion.div}
              dir="ltr"
            >
              <SQuestion>{question}</SQuestion>

              <SAnswerOptions>
                {options.map((option) => (
                  <OptionButton
                    key={option.id}
                    id={option.id}
                    length={options.length}
                    onClick={handleOptionClick}
                    isCorrect={correctOption === option.id}
                    isWrong={currentWrongOptions.includes(option.id)}
                    text={option.answer}
                  />
                ))}
              </SAnswerOptions>
            </SQuestionBlock>
          )}
        </AnimatePresence>
      </STaskItemWrapper>
    </LayoutGroup>
  );
};

export default MinimalPairsItem;

const commonTransition = { duration: 0.5 };
const taskVariants: Variants = {
  prepared: {
    opacity: 0,
    scale: 1,
    y: 40,
    transition: {
      when: 'afterChildren',
      duration: 0.1
    }
  },
  show: { opacity: 1, scale: 1, y: 0 },
  hidden: { opacity: 0, scale: 0.9, y: 0 }
};
const blockVariants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 40 }
};
const replayVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

const STaskItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${customMediaQuery('tablet')} {
    margin-block-start: 0;
  }
`;

const SPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  max-width: 90vw;
  max-height: 60vh;
  overflow: hidden;
  background-color: #ffffff30;
  z-index: 1;
  margin: 0 0 2rem;
  transition: height 0.3s ease;
  min-height: 150px;
  width: 100%;

  ${customMediaQuery('tablet')} {
    max-width: 492px;
    min-height: 200px;
    margin: 2.5rem 0;
  }

  &.onlyAudio {
    background-image: url(${speakerIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
  }
`;

const SReplay = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 0;
  border: none;
  z-index: 1;

  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
    opacity: 1;
  }
`;

const SQuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
`;

const SQuestion = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 91.02%;
  text-align: center;
  letter-spacing: -0.035em;
  margin: 0;

  ${customMediaQuery('tablet')} {
    font-size: 32px;
  }
`;

const SAnswerOptions = styled.ol`
  list-style: none;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-inline-start: 0;
  justify-content: center;
  margin: 0;

  ${customMediaQuery('tablet')} {
    max-width: 612px;
  }
`;

const SSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.45);
`;

const SErrorMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.85);
  line-height: 1.5rem;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--heading-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
`;
