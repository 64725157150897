import { IDictationItem } from '@adeptlms/lingu-students-react-shared';
import { AnimationControls } from 'framer-motion';
import { FocusEvent, FormEventHandler, MutableRefObject, useCallback } from 'react';
import { useWrongAnimation } from 'students/views/shared/hooks';
import { usePlayAudioPlayer } from '../../../../common/hooks';
import { useAnimations, useAnswerInput, useHint, useInit, useItemFlow } from './hooks';
import { ISelectAnswerPayload } from './types';

export const RESULT_BLOCK_WRONG_CLASS_NAME = 'wrongAnimation';

// Expected duration = audio file duration multiplied by TASK_TIMEOUT_MULTIPLIER plus TASK_MINIMAL_TIMEOUT in case if audio is too short.

type tFn = () => void;
interface IReceivedProps {
  item: IDictationItem;
  goToWrongAnswerScreen: (payload: ISelectAnswerPayload) => void;
  onSelectAnswer: (payload: ISelectAnswerPayload) => void;
  showHint?: boolean;
  validation: {
    caseSensitive: boolean;
    checkPunctuation: boolean;
  };
}

interface IReturnedValue {
  answer: {
    currentAnswer: string;
    inputHasMistakes: boolean;
    isItemFinished: boolean;
  };
  animations: {
    timeoutBlockControls: AnimationControls;
    headerAnimationControls: AnimationControls;
    contentAnimationControls: AnimationControls;
    hintButtonsAnimationControls: AnimationControls;
  };
  handlers: {
    handleStopAudio: tFn;
    handleInputChange: (value: string) => void;
    handleEnterKeyPress: tFn;
    handleClickOnCharacter: (character: string) => void;
    handleOnAnswer: tFn;
    handleBlur: (e: FocusEvent) => void;
    handleOnGetHint: tFn;
    handleWrongAnswer: tFn;
    handleFormSubmit: FormEventHandler;
  };
  hint: {
    tooltipVisible: boolean;
    hintButtonsBlockVisible: boolean;
    currentHint: {
      correctPart: string;
      hintWord: string;
    };
  };
  refs: {
    inputRef: MutableRefObject<HTMLTextAreaElement | null>;
    inputAnimationRef: MutableRefObject<HTMLDivElement | null>;
  };
}

// TODO update this spec
/*
 * According to the UI Designs:
 * 1) After user hears the audio, they got about TASK_MINIMAL_TIMEOUT to enter anything into the input field;
 * 2) If user is idle for HINT_TIMEOUT - show hint button appears;
 * 3) After user enters the answer one should press Enter key or lose focus from input.
 * 4) If the answer is incorrect - wrong animation is fired and Hint Buttons block is show
 * 5) On the Hint Buttons block:
 *    1. if user clicks Get a Hint button - hint tooltip is shown
 *    2. if user clicks I don't know button - answer is submitted and the next task item is shown
 * 6) If the time is up:
 *    1. if current answer is equal to the correct answer - show Correct screen, submit answer and go to the next item task;
 *    2. if current answer is incorrect - show incorrect screen, submit answer and go to the next item task;
 * */

function useDictationTaskItem({
  item,
  goToWrongAnswerScreen,
  onSelectAnswer,
  showHint: showHintPreference,
  validation
}: IReceivedProps): IReturnedValue {
  const [inputAnimationRef, showWrongAnimation] = useWrongAnimation(
    undefined,
    RESULT_BLOCK_WRONG_CLASS_NAME
  );

  const {
    headerAnimationControls,
    // animateHeaderAnimationControls,
    contentAnimationControls,
    // animateContentAnimationControls,
    hintButtonsAnimationControls,
    animateHintButtonsAnimationControls,
    timeoutBlockControls,
    runItemAppearAnimation,
    runItemDisappearAnimation
  } = useAnimations();

  const { playCorrectSound, playIncorrectSound } = usePlayAudioPlayer();

  const {
    inputRef,
    initInput,
    inputHasMistakes,
    setInputHasMistakes,
    _handleInputChange,
    _handleClickOnCharacter,
    currentAnswerInput
  } = useAnswerInput();

  const {
    tooltipVisible,
    showHintButtonsBlock,
    hintButtonsBlockVisible,
    hideHintButtonsBlock,
    hideAndResetHint,
    showHintTooltip,
    currentHint
  } = useHint({
    validation,
    correctItemSentence: item.sentence,
    showHintPreference,
    currentAnswerInput,
    animateHintButtonsAnimationControls
  });

  const madeMistake = useCallback(() => {
    setInputHasMistakes(true);
    showWrongAnimation();
    playIncorrectSound();
    showHintButtonsBlock();
  }, [setInputHasMistakes, playIncorrectSound, showHintButtonsBlock, showWrongAnimation]);

  const {
    handleWrongAnswer,
    increaseMistakes,
    handleBlur,
    handleOnAnswer,
    handleEnterKeyPress,
    handleStopAudio,
    initItemDurationAnimation,
    isItemFinished
  } = useItemFlow({
    playCorrectSound,
    currentAnswerInput,
    goToWrongAnswerScreen,
    item,
    madeMistake,
    onSelectAnswer,
    validation,
    runItemDisappearAnimation,
    hideAndResetHint,
    hideHintButtonsBlock,
    timeoutBlockControls
  });

  const handleInputChange = useCallback(
    (value: string) => {
      hideAndResetHint();
      _handleInputChange(value);
    },
    [_handleInputChange, hideAndResetHint]
  );

  const handleClickOnCharacter = useCallback(
    (value: string) => {
      hideAndResetHint();
      _handleClickOnCharacter(value);
    },
    [_handleClickOnCharacter, hideAndResetHint]
  );

  const handleOnGetHint = useCallback(() => {
    if (inputHasMistakes) {
      increaseMistakes();
      madeMistake();
    }
    showHintTooltip();
  }, [increaseMistakes, inputHasMistakes, madeMistake, showHintTooltip]);

  const handleFormSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      handleEnterKeyPress();
    },
    [handleEnterKeyPress]
  );

  useInit({ item, initInput, runItemAppearAnimation, initItemDurationAnimation });

  return {
    answer: {
      currentAnswer: currentAnswerInput,
      inputHasMistakes,
      isItemFinished
    },
    animations: {
      timeoutBlockControls,
      headerAnimationControls,
      contentAnimationControls,
      hintButtonsAnimationControls
    },
    handlers: {
      handleStopAudio,
      handleInputChange,
      handleEnterKeyPress,
      handleBlur,
      handleClickOnCharacter,
      handleOnAnswer,
      handleOnGetHint,
      handleWrongAnswer,
      handleFormSubmit
    },
    hint: {
      tooltipVisible,
      hintButtonsBlockVisible,
      currentHint
    },
    refs: {
      inputRef,
      inputAnimationRef
    }
  };
}
export default useDictationTaskItem;
