import React, { FC, useMemo, useState } from 'react';
import {
  IMetrics,
  IGameRoundAnswer,
  IPhrase,
  IWordGamesItem
} from '@adeptlms/lingu-students-react-shared';
import { gamesMap } from 'students/views/pages/Games';
import TaskGames from 'students/views/pages/Games/TaskGames';

export interface IGameAnswerPayload {
  itemId: number;
  mistakesCount: number;
  rounds: IGameRoundAnswer[];
  timeSpent: number;
}

interface IWordGamesProps {
  lessonId: number;
  taskId: number;
  silentMode?: boolean; // true if there is non task session, means that in Preview mode
  items: IWordGamesItem[];
  lessonPhrases: IPhrase[];
  onGameComplete: (payload: IGameAnswerPayload) => void;
  onComplete: () => void;
  onItemComplete: (metrics: IMetrics, itemId: number) => void;
}

const WordGames: FC<IWordGamesProps> = ({
  lessonId,
  taskId,
  silentMode,
  items,
  lessonPhrases,
  onComplete,
  onItemComplete
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const enabledGames = useMemo(
    () =>
      items
        .filter((item) => item.enabled && gamesMap.has(item.gameType))
        .map((item) => ({
          id: item.id,
          type: item.gameType
        })),
    [items]
  );

  const activeGame = enabledGames[activeIndex];

  if (!activeGame) return null;

  const handleNextGame = () => {
    if (!enabledGames) return;

    const nextIndex = activeIndex + 1;
    if (nextIndex < enabledGames.length) {
      setActiveIndex(nextIndex);
    } else {
      onComplete();
    }
  };

  const handleRoundComplete = (metrics: IMetrics) => {
    onItemComplete(metrics, items[activeIndex].id);
  };

  return (
    <TaskGames
      silentMode={silentMode}
      lessonId={lessonId}
      taskId={taskId}
      phrases={lessonPhrases}
      gameType={enabledGames[activeIndex].type}
      onGameComplete={handleNextGame}
      onRoundComplete={handleRoundComplete}
    />
  );
};

export default WordGames;
