import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { customMediaQuery } from 'students/views/shared/styled';

interface IStickyHeader {
  children: ReactElement | null;
  className?: string;
}

const StickyHeader: FC<IStickyHeader> = ({ children, className }) => (
  <SStickyHeader className={cn(className)}>{children}</SStickyHeader>
);

export default StickyHeader;

const SStickyHeader = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
  }

  ${customMediaQuery('tablet')} {
    left: 1rem;
    right: 1rem;
  }

  @media screen and (min-width: 768px) {
    left: 3rem;
    right: 3rem;
  }

  ${customMediaQuery('desktop')} {
    left: 4rem;
    right: 4rem;
  }

  @media screen and (min-width: 1068px) {
    max-width: 921px;
    left: auto;
    right: auto;
  }
`;
