import { lessonSelectors, tGiphyImage } from '@adeptlms/lingu-students-react-shared';
import { ILanguage } from '@adeptlms/lingu-students-react-shared/lib.d.ts/data/models';
import cn from 'classnames';
import { locales, t } from 'i18n';
import languageFlags from 'i18n/config/localeFlags';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'students/stores/_utils';
import useZoomableImage from 'students/views/shared/bundles/zoomableImage/useZoomableImage';
import { useHeartBeatMediaControl } from 'students/views/shared/components/HeartBeat';
import ImageContent from 'students/views/shared/components/ImageContent';
import { AudioPlayer } from 'students/views/shared/components/MediaPlayer';
import { IAudioPlayerRef } from 'students/views/shared/components/MediaPlayer/AudioPlayer/AudioPlayer';
import ScrollToBottomButton, {
  tScrollToBottomButtonRef
} from 'students/views/shared/components/ScrollToBottomButton';
import TranslationTextTooltip from 'students/views/shared/components/TranslationTextTooltip';
import { usePageVisibility } from 'students/views/shared/hooks';

import LinkElem from 'students/views/shared/components/LinkElem';
import { StickyHeader } from '../../../components';
import translationBadge from './assets/cloud-translation-badge.png';
import translationBadgeWebp from './assets/cloud-translation-badge.webp';
import {
  SBackdrop,
  SButtonBlock,
  SContentWrapper,
  SFinishTaskButton,
  SFlag,
  SLangButton,
  SStickyHeaderWrapper,
  STextContentBlock,
  STextTaskWrapper,
  STitle
} from './styled';

interface ILearnTaskTemplate {
  onFinish: () => void;
  lightFont: boolean;
  isCompleting: boolean;
  task: {
    title: string;
    audioURL?: string | null;
    imageURL: string | null;
    imageAlt: string | null;
    mobileImageURL: string | null;
    coverImg: boolean | null;
    giphyImage: tGiphyImage | null;
    content: string;
    translation?: string | null;
    sourceLanguage?: Pick<ILanguage, 'code' | 'name'> | null;
    targetLanguage?: ILanguage | null;
    fallbackTargetLanguageCode?: string | null;
  };
}

const LearnTaskTemplate: FC<ILearnTaskTemplate> = ({
  onFinish,
  lightFont,
  isCompleting,
  task
}) => {
  const {
    title,
    imageURL,
    imageAlt,
    mobileImageURL,
    coverImg,
    giphyImage,
    audioURL,
    content,
    translation,
    sourceLanguage,
    targetLanguage,
    fallbackTargetLanguageCode
  } = task;

  const [showOriginal, setShowOriginal] = useState(true);
  const finishTaskButtonRef = useRef<HTMLButtonElement>(null);
  const scrollButtonRef = useRef<tScrollToBottomButtonRef>(null);
  const { onStartPlaying, onStopPlaying } = useHeartBeatMediaControl();
  const audioPlayerRef = useRef<IAudioPlayerRef>(null);
  const isTranslationTask = Boolean(translation);
  const fallbackLocale = locales.find(({ lKey }) => lKey === fallbackTargetLanguageCode);
  const targetLang = targetLanguage || {
    code: fallbackTargetLanguageCode,
    name: t(fallbackLocale?.labelKey || '')
  };
  const lang = showOriginal ? targetLang : sourceLanguage;
  const hasImageContent = !!imageURL || giphyImage;
  const currentLesson = useTypedSelector(lessonSelectors.selectLesson);

  const { contentRef, backdropVisible, setElementRefAndInitialize } = useZoomableImage(
    {}
  );

  const createTranslationMarkup = () => {
    return {
      __html: showOriginal ? content : translation ?? ''
    };
  };

  const renderRegularMarkup = useCallback(() => {
    if (currentLesson?.languageCode === 'nb' || currentLesson?.languageCode === 'no') {
      return (
        <TranslationTextTooltip>
          <div
            dir="ltr"
            ref={setElementRefAndInitialize}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </TranslationTextTooltip>
      );
    }

    return (
      <div
        ref={setElementRefAndInitialize}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }, [currentLesson, content, setElementRefAndInitialize]);

  useEffect(() => {
    scrollButtonRef?.current?.update();
  }, [showOriginal]);

  const onPageVisibilityChange = useCallback((isHidden: boolean) => {
    isHidden && audioPlayerRef.current?.handlePause();
  }, []);

  usePageVisibility(onPageVisibilityChange);

  const handleLanguageToggle = () => {
    setShowOriginal((showOriginal) => !showOriginal);
  };

  function getTranslateButtonLabelText() {
    if (showOriginal) {
      return lang?.name
        ? t('frontend.lesson_task.tasks.learn.translatable_text.translate_into', {
            languageName: lang?.name
          })
        : t(
            'frontend.lesson_task.tasks.learn.translatable_text.translate_into_your_lang'
          );
    } else {
      return t('frontend.lesson_task.tasks.learn.translatable_text.show_original');
    }
  }

  const renderStickyHeader = () =>
    audioURL || isTranslationTask ? (
      <StickyHeader>
        <SStickyHeaderWrapper>
          {isTranslationTask && lang && targetLang.code !== sourceLanguage?.code ? (
            <SLangButton type="button" onClick={handleLanguageToggle}>
              <SFlag
                url={(lang.code && languageFlags.get(lang.code)?.flag) || ''}
                height="28px"
                width="28px"
              />

              {getTranslateButtonLabelText()}
            </SLangButton>
          ) : null}

          {audioURL ? (
            <AudioPlayer
              ref={audioPlayerRef}
              url={audioURL}
              onPlay={onStartPlaying}
              onPause={onStopPlaying}
              onEnded={onStopPlaying}
              size="small"
            />
          ) : null}
        </SStickyHeaderWrapper>
      </StickyHeader>
    ) : null;

  const renderMediaBlock = () => {
    if (hasImageContent || !!audioURL) {
      return (
        <>
          {hasImageContent ? (
            <ImageContent
              image={imageURL}
              smallImage={mobileImageURL}
              cover={coverImg}
              giphyImage={giphyImage}
              isZoomable
              alt={imageAlt || ''}
            />
          ) : null}

          {renderStickyHeader()}
        </>
      );
    }

    return renderStickyHeader();
  };

  const renderContentBlock = () => {
    if (isTranslationTask) {
      return (
        <>
          <STextContentBlock
            ref={contentRef}
            dir={showOriginal ? 'ltr' : 'auto'}
            lang={
              !showOriginal
                ? `${targetLang.code}-x-mtfrom-${sourceLanguage?.code ?? ''}`
                : undefined
            }
          >
            <div
              ref={setElementRefAndInitialize}
              dangerouslySetInnerHTML={createTranslationMarkup()}
            />
          </STextContentBlock>

          <SBackdrop className={cn({ visible: backdropVisible })} />

          {!showOriginal && (
            <LinkElem
              aria-label="Translate"
              to="http://translate.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <picture>
                <source srcSet={translationBadgeWebp} type="image/webp" />
                <img src={translationBadge} alt="Google Translate" />
              </picture>
            </LinkElem>
          )}
        </>
      );
    }

    return (
      <STextContentBlock ref={contentRef}>
        {renderRegularMarkup()}

        <SBackdrop className={cn({ visible: backdropVisible })} />
      </STextContentBlock>
    );
  };

  return (
    <STextTaskWrapper>
      <STitle light={lightFont}>{title}</STitle>

      <SContentWrapper>
        {renderMediaBlock()}

        {renderContentBlock()}

        <ScrollToBottomButton ref={scrollButtonRef} targetRef={finishTaskButtonRef} />
      </SContentWrapper>

      <SButtonBlock>
        <SFinishTaskButton
          onClick={onFinish}
          isCompleting={isCompleting}
          ref={finishTaskButtonRef}
        />
      </SButtonBlock>
    </STextTaskWrapper>
  );
};

export default LearnTaskTemplate;
