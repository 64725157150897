import {
  IArrangeItemsItemOption,
  IPlayArrangeItemsItem
} from '@adeptlms/lingu-students-react-shared';
import { motion as m } from 'framer-motion';
import React, { FC, useCallback } from 'react';
import PhraseIcon from 'students/views/shared/components/PhraseIcon';
import UrlIcon from 'students/views/shared/components/UrlIcon';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import doneIcon from '../assets/doneIcon.svg';
import useItemResultScreen from '../hooks/useItemResultScreen';
import { SSvgAudioPlayer } from './styled';

interface IItemResultScreen {
  item: IPlayArrangeItemsItem;
  onFinish: () => void;
}

const ItemResultScreen: FC<IItemResultScreen> = ({ item, onFinish }) => {
  const { getBlockType, animationControls } = useItemResultScreen({ onFinish });

  const renderAnswerOption = useCallback(
    (option: IArrangeItemsItemOption, index: number) => {
      const blockType = getBlockType(option);

      return (
        <SResultWordWrapper key={index}>
          <SResultWord data-type={blockType}>
            {blockType === 'word' ? (
              <span>{option.text || ''}</span>
            ) : blockType === 'picture' ? (
              <PhraseIcon
                imageUrl={option?.image || ''}
                colorRequired
                animationUrl={null}
                text={option.text || ''}
                width="90px"
                height="90px"
              />
            ) : blockType === 'audio' ? (
              <SSvgAudioPlayer src={option?.audio || ''} progressColor="#363658" />
            ) : null}
          </SResultWord>
        </SResultWordWrapper>
      );
    },
    [getBlockType]
  );

  if (!item) return null;

  return (
    <SWrapper as={m.div} initial={{ opacity: 0 }} animate={animationControls}>
      <SUrlIcon url={doneIcon} height="66px" width="66px" />

      <SResultWrapper>{item.options.map(renderAnswerOption)}</SResultWrapper>
    </SWrapper>
  );
};

export default React.memo(ItemResultScreen);

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
`;

const SResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${customMediaQuery('tablet')} {
    flex-direction: row;
  }
`;

const SUrlIcon = styled(UrlIcon)`
  margin-block-start: -70px;
  margin-block-end: 40px;

  ${customMediaQuery('tablet')} {
    margin-block-end: 60px;
  }
`;

const SResultWordWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: 100%;
  margin-block-end: 12px;

  ${customMediaQuery('tablet')} {
    background: rgba(0, 0, 0, 0.18);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.56), inset 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    height: 100px;
    max-width: 280px;
    min-width: 100px;
    width: max-content;
    padding: 4px;
    margin-block-end: 0;
    margin-inline-end: 12px;
  }
`;

const SResultWord = styled.div`
  cursor: default;
  border-radius: 10px;
  background: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;

  &[data-type='word'] {
    padding: 8px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--color-black);
    text-align: center;
    min-height: 42px;

    &,
    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    ${customMediaQuery('tablet')} {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &[data-type='audio'] {
    padding: 8px;
  }

  &[data-type='picture'] {
    height: 110px;
  }

  ${customMediaQuery('tablet')} {
    height: 90px;
    max-width: 280px;

    &[data-type='audio'] {
      width: 90px;
    }

    &[data-type='picture'] {
      width: 90px;
      height: 90px;
    }
  }
`;
