import React, { useEffect, useRef, MutableRefObject, useState } from 'react';
import { useIsMounted } from '@adeptlms/lingu-students-react-shared';
import useAudioRecording from './useAudioRecording';
import useOutsideClick from './useOutsideClick';
import useBreakPoint, {
  useMobileBreakPoint,
  useDesktopBreakPoint
} from './useBreakPoint';
import useBreakpointNumber from './useBreakpointNumber';
import usePageVisibility from './usePageVisibility';
import useAnimateScenarios from './useAnimateScenarios';
import useMobileVhHack from './useMobileVhHack';
import useOnScreen from './useOnScreen';
import useExitPrompt from './useExitPrompt';
import usePromptContext from './usePromptContext';
import useLessonPause from './useLessonPause';
import useAccessibleListNavigation from './useKeyboardListNavigation';

export {
  useAudioRecording,
  useOutsideClick,
  useBreakPoint,
  usePageVisibility,
  useAnimateScenarios,
  useBreakpointNumber,
  useMobileVhHack,
  useMobileBreakPoint,
  useDesktopBreakPoint,
  useOnScreen,
  useExitPrompt,
  usePromptContext,
  useLessonPause,
  useIsMounted,
  useAccessibleListNavigation
};

export function useCombinedRefs<T>(
  ...refs: MutableRefObject<T | null>[]
): React.RefObject<T> {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      ref.current = targetRef.current;
    });
  }, [refs]);

  return targetRef;
}

export function useMatchMedia(mediaQueryString = ''): MediaQueryList {
  return window.matchMedia(mediaQueryString);
}

const onMountClassName = 'mountedComponentAnimation';
function useAddClassNameOnMount(className = onMountClassName): string {
  const [finalClassName, setFinalClassName] = useState('');

  useEffect(() => {
    setFinalClassName(className);
  }, [className]);

  return finalClassName;
}
useAddClassNameOnMount.className = onMountClassName;

export { useAddClassNameOnMount };

export function useWrongAnimation(
  duration = 800,
  wrongClassName = 'wrongAnimation'
): [React.MutableRefObject<HTMLDivElement | null>, () => void] {
  const wrongTimer = useRef<number | undefined>(undefined);
  const wrongBlockRef = useRef<HTMLDivElement>(null);

  function markWrong() {
    wrongBlockRef.current?.classList.remove(wrongClassName);
    wrongBlockRef.current?.getBoundingClientRect();
    wrongBlockRef.current?.classList.add(wrongClassName);
    clearTimeout(wrongTimer.current);
    wrongTimer.current = window.setTimeout(() => {
      wrongBlockRef.current?.classList.remove(wrongClassName);
    }, duration);
  }

  return [wrongBlockRef, markWrong];
}
