import { useEffect, useState } from 'react';
import bubbleAnimationManager from 'students/views/shared/bundles/bubbleAnimationManager';

interface IReturnedValue {
  startButtonPositioned: boolean;
  setStartButtonPositioned: (value: boolean) => void;
  showBubbleAnimation: boolean;
  bubbleChanger: JSX.Element | null;
}

function useTaskTransitioner(): IReturnedValue {
  const { showBubbleAnimation } = bubbleAnimationManager;
  const bubbleChanger = bubbleAnimationManager.shrinkingBubbleChanger;
  const [startButtonPositioned, setStartButtonPositioned] = useState(false);

  useEffect(() => {
    const handleStartButtonPositioned = () => {
      setStartButtonPositioned(true);
    };

    bubbleAnimationManager.onStartButtonPositioned(handleStartButtonPositioned);

    return () => {
      bubbleAnimationManager.offStartButtonPositioned(handleStartButtonPositioned);
      bubbleAnimationManager.clearAnimationData();
    };
  }, []);

  return {
    startButtonPositioned,
    setStartButtonPositioned,
    showBubbleAnimation,
    bubbleChanger
  };
}

export default useTaskTransitioner;
