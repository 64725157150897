import React from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

interface IProps {
  title?: string | null;
}

const LessonTaskTitle: React.FC<IProps> = ({ title }) => {
  if (!title) {
    return null;
  }

  return (
    <SLessonTitleBlock>
      <SLessonTaskTitle title={title}>{title}</SLessonTaskTitle>
    </SLessonTitleBlock>
  );
};

export default LessonTaskTitle;

const SLessonTitleBlock = styled.div`
  font-size: 1rem;
  color: var(--color-light-white);
  letter-spacing: -0.41px;
  font-weight: bold;
  padding: 0 5.5rem;
  display: flex;
  justify-content: center;
  margin-block-end: 1.5rem;

  ${customMediaQuery('tablet')} {
    letter-spacing: normal;
    font-weight: normal;
    opacity: 0.6;
    margin-block-end: 3.5rem;
  }
`;

const SLessonTaskTitle = styled.span`
  color: var(--color-light-white);
  font-size: 1rem;
  line-height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${customMediaQuery('tablet')} {
    font-size: 1.375rem;
    line-height: 3.35rem;
    padding-top: 0.5rem;
  }
`;
