import React, { FC } from 'react';
import { motion } from 'framer-motion';
import LottieIcon from 'students/views/shared/components/LottieIcon';

import handHintAnimation from './assets/handHint.json';
import { IArea } from '../../types';

const POINTER_ICON_OFFSET = {
  x: 15,
  y: 15
};

interface IProps {
  getHintAreas: () => Array<IArea>;
}

const ArrangeItemsHint: FC<IProps> = ({ getHintAreas }) => {
  const hintAreas = getHintAreas();

  return (
    <motion.div
      className="ln-absolute"
      animate={{
        left: [
          hintAreas[0].x,
          hintAreas[0].x,
          hintAreas[0].x + POINTER_ICON_OFFSET.x,
          hintAreas[1].x + POINTER_ICON_OFFSET.x
        ],
        top: [
          hintAreas[0].y + 100,
          hintAreas[0].y + 50,
          hintAreas[0].y + POINTER_ICON_OFFSET.y,
          hintAreas[1].y + POINTER_ICON_OFFSET.y
        ]
      }}
      transition={{ duration: 4, times: [0, 0.5, 0.75, 1] }}
      style={{ zIndex: 11 }}
    >
      <LottieIcon
        destroyOnComplete
        width="6rem"
        loop={false}
        autoPlay
        animationJSONData={handHintAnimation}
      />
    </motion.div>
  );
};

export default ArrangeItemsHint;
