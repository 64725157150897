import { IPlayArrangeWordsItem } from '@adeptlms/lingu-students-react-shared';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SvgAudioPlayer from 'students/views/shared/components/MediaPlayer/SvgAudioPlayer';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';

import { useDelayedFinishTask } from '../../../common/hooks';
import ItemResult from './ItemResult';

interface IProps {
  item: IPlayArrangeWordsItem;
  onFinish: () => void;
}

const ItemResultScreen: React.FC<IProps> = ({ item, onFinish }) => {
  const { audioURL } = item;
  const playerRef = useRef(null);

  const handleEnd = useCallback(() => {
    setStartFinalAnimation(true);
  }, []);

  const handleFinish = useDelayedFinishTask(handleEnd, playerRef);

  useEffect(() => {
    if (!audioURL) {
      const timer = window.setTimeout(() => handleEnd(), DEFAULT_TIMEOUT);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [audioURL, handleEnd]);

  const [startFinalAnimation, setStartFinalAnimation] = useState(false);

  if (!item) return null;

  return (
    <SResultWrapper>
      {audioURL && (
        <SSvgAudioPlayer
          className={cn({ hidden: startFinalAnimation })}
          src={audioURL}
          autoplay
          color="#639cd4"
          progressColor="#ffffff"
          onEnd={handleFinish}
          ref={playerRef}
        />
      )}
      <ItemResult
        startAnimation={startFinalAnimation}
        onFinish={onFinish}
        solution={item.solution}
        translation={item.description}
      />
    </SResultWrapper>
  );
};

export default React.memo(ItemResultScreen);

const DEFAULT_TIMEOUT = 4000;

const SResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const SSvgAudioPlayer = styled(SvgAudioPlayer)`
  width: 40px;
  height: 40px;
  margin-block-end: 2rem;
  visibility: visible;

  &.hidden {
    visibility: hidden;
  }

  ${customMediaQuery('tablet')} {
    width: 61px;
    height: 61px;
    margin-block-end: 3.6rem;
  }
`;
