import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import {
  IFillSentenceGapTask,
  IFillSentenceGapTaskItemSession,
  IFillSentenceGapTaskSession,
  tFillSentenceGapAnswer
} from '@adeptlms/lingu-students-react-shared';
import { usePromptContext } from 'students/views/shared/hooks';
import { t } from 'i18n';
import bgImage from './assets/fillSentenceGapBg.svg';
import { IPlayTaskProps, tPlayTaskComponentType } from '../types';
import { ISelectAnswerPayload } from './components/SentenceGapItem';
import { usePlayAnswers, useStreakFactor } from '../../common/hooks';
import PlayTaskScreenSwitcher from '../../common/screens/PlayTaskScreenSwitcher';
import PlayFillSentenceGapMainScreen from './PlayFillSentenceGapMainScreen';
import Streak from '../../common/components/Streak';

const PlayFillSentenceGapTask: tPlayTaskComponentType<
  IPlayTaskProps<IFillSentenceGapTask, IFillSentenceGapTaskSession>
> = ({ task, taskSession, isCompleting, onFinish, onNext, onAnswer, className }) => {
  const { items, instruction } = task;
  const answersFn = usePlayAnswers<tFillSentenceGapAnswer>();
  const { streakNumber, streakPercent, onTheFirstTry } = useStreakFactor();
  const { makeDirty } = usePromptContext();

  useEffect(() => {
    if (taskSession?.taskItemSessions) {
      answersFn(taskSession.taskItemSessions);
    } else {
      answersFn(
        items.map((item, index) => {
          return {
            id: index,
            completed: false,
            answers: new Array(item.solution.length).fill(''),
            taskItemId: item.id,
            mistakesCount: 0,
            skipped: false,
            url: ''
          };
        })
      );
    }

    onTheFirstTry(false);
  }, [taskSession, items, onTheFirstTry, answersFn]);

  const updateAnswer = (
    itemId: number,
    data: Partial<tFillSentenceGapAnswer[number]>
  ) => {
    const answerIndex = answersFn().findIndex(
      (answer: IFillSentenceGapTaskItemSession) => answer.taskItemId === itemId
    );

    const { mistakesCount, skipped } = data;

    if (answerIndex < 0) return;

    const answer = answersFn()[answerIndex];
    const newAnswerData = { ...answer, ...data };

    answersFn([
      ...answersFn().slice(0, answerIndex),
      newAnswerData,
      ...answersFn().slice(answerIndex + 1)
    ]);

    onAnswer && onAnswer(newAnswerData);

    onTheFirstTry(!(mistakesCount || skipped));
  };

  const handleFinishTask = () => {
    onNext && onNext();
  };

  const submitTask = useCallback(() => {
    onFinish && onFinish(answersFn());
  }, [onFinish, answersFn]);

  const handleCompleteTask = useCallback(
    (goToFinishScreen: () => void) => () => {
      makeDirty(false);

      submitTask();

      goToFinishScreen();
    },
    [submitTask, makeDirty]
  );

  const handleSelectAnswer = (payload: ISelectAnswerPayload) => {
    const { itemId, mistakesCount, itemIndex } = payload;
    const item = items[itemIndex];

    updateAnswer(itemId, {
      mistakesCount,
      answers: item.solution,
      skipped: false
    });

    makeDirty(true);
  };

  const handleSkip = (itemId: number) => {
    updateAnswer(itemId, {
      skipped: true
    });
  };

  return (
    <>
      <Streak streakNumber={streakNumber} streakPercent={streakPercent} />
      <PlayTaskScreenSwitcher
        isCompleting={isCompleting}
        onExitFinishScreen={handleFinishTask}
        className={className}
        startScreenHeading={
          instruction ||
          t('frontend.lesson_task.tasks.play.fill_gap.start_screen.heading')
        }
        itemsExist={!!items.length}
        taskStatus={taskSession?.status}
        completeTask={submitTask}
        taskColor="#e467a2"
      >
        {(goToFinishScreen) => (
          <PlayFillSentenceGapMainScreen
            task={task}
            onSelectAnswer={handleSelectAnswer}
            onComplete={handleCompleteTask(goToFinishScreen)}
            onSkip={handleSkip}
          />
        )}
      </PlayTaskScreenSwitcher>
    </>
  );
};

export default PlayFillSentenceGapTask;

PlayFillSentenceGapTask.backgroundComponent = styled.div`
  background-color: #c7367d;
  background-image: linear-gradient(180deg, #c7367d 0%, #e467a2 100%);

  @media (min-width: ${({ theme }) => theme.linguBptLg}) {
    background-image: url(${bgImage}), linear-gradient(180deg, #c7367d 0%, #e467a2 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: cover, auto;
  }
`;
