import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LottieIcon from 'students/views/shared/components/LottieIcon';

import CorrectPointAnimationData from '../assets/CorrectPointAnimationData.json';
import ActivePointAnimationData from '../assets/ActivePointAnimationData.json';
import IncorrectPointAnimationData from '../assets/IncorrectPointAnimationData.json';
import SelectPointAnimationData from '../assets/SelectPointAnimationData.json';
import DeselectPointAnimationData from '../assets/DeselectPointAnimationData.json';
import StaticDefaultPoint from '../assets/StaticDefaultPoint.svg';

interface IProps {
  active: boolean;
  succeed: boolean;
  fails: number;
}

type tAnimationState =
  | 'activeAppear'
  | 'reset'
  | 'active'
  | 'inactive'
  | 'selected'
  | 'deselected'
  | 'incorrect';

const PointIcon: React.FC<IProps> = ({ active, succeed, fails }) => {
  const [status, setStatus] = useState<tAnimationState>('inactive');
  const transitionTimeout = useRef<number | null>(null);
  const failsRef = useRef<number>(0);

  const delayStateUpdate = useCallback(
    (newState: tAnimationState, delay: number) => {
      if (!succeed && transitionTimeout.current) clearTimeout(transitionTimeout.current);
      transitionTimeout.current = window.setTimeout(() => setStatus(newState), delay);
    },
    [succeed]
  );

  useEffect(() => {
    return () => {
      if (transitionTimeout.current) clearTimeout(transitionTimeout.current);
    };
  }, [transitionTimeout]);

  useEffect(() => {
    // This hook updates fail count and runs incorrect animation if user selected incorrect answer
    // Additional check - if the point is 'active'. If not - do nothing.
    if (fails !== failsRef.current && !succeed && active) {
      setStatus('reset');
      delayStateUpdate('incorrect', 0);
    }
    failsRef.current = fails;
  }, [fails, succeed, active, delayStateUpdate]);

  useEffect(() => {
    if (active) {
      setStatus('selected');
    } else {
      setStatus((prevState) => (prevState !== 'inactive' ? 'deselected' : 'inactive'));
    }
  }, [active]);

  // Be careful, do not create endless useEffect! :angry_smile:
  useEffect(() => {
    if (status === 'incorrect') delayStateUpdate('activeAppear', 1800);
    if (status === 'activeAppear') delayStateUpdate('active', 1000);
    if (status === 'selected') delayStateUpdate('active', 450);
    if (status === 'deselected') delayStateUpdate('inactive', 800);
  }, [status, delayStateUpdate]);

  if (status === 'reset') {
    return null;
  }

  if (succeed) {
    return (
      <SLottieIcon
        width="100px"
        height="100px"
        animationJSONData={CorrectPointAnimationData}
        loop={false}
        autoPlay
      />
    );
  }

  if (status === 'activeAppear') {
    return (
      <SLottieIcon
        animationJSONData={ActivePointAnimationData}
        loop={false}
        width="100px"
        height="100px"
        autoPlay
      />
    );
  }

  const isLooped = status === 'active' || status === 'inactive';

  switch (status) {
    case 'selected':
      return (
        <SLottieIcon
          animationJSONData={SelectPointAnimationData}
          loop={isLooped}
          width="100px"
          height="100px"
          autoPlay
        />
      );

    case 'deselected':
      return (
        <SLottieIcon
          animationJSONData={DeselectPointAnimationData}
          loop={isLooped}
          width="100px"
          height="100px"
          autoPlay
        />
      );

    case 'active':
      return (
        <SLottieIcon
          animationJSONData={ActivePointAnimationData}
          loop={isLooped}
          width="100px"
          height="100px"
          autoPlay
        />
      );

    case 'inactive':
      return <SDefaultIcon />;

    case 'incorrect':
      return (
        <SLottieIcon
          animationJSONData={IncorrectPointAnimationData}
          loop={isLooped}
          width="100px"
          height="100px"
          autoPlay
        />
      );

    default:
      return <SDefaultIcon />;
  }
};

export default PointIcon;

const SDefaultIcon = styled.div`
  background-image: url(${StaticDefaultPoint});
  background-position: 6px 6px;
  background-size: 72px 72px;
  width: 80px;
  height: 80px;
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.7));
`;

const SLottieIcon = styled(LottieIcon)`
  filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.7));
`;
