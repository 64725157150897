import { motion, useAnimation } from 'framer-motion';
import { forwardRef, useImperativeHandle } from 'react';
import { customMediaQuery } from 'students/views/shared/styled';
import styled from 'styled-components';
import { ANSWER_BLOCK_ADDITIONAL_HEIGHT } from '../../utils';

import { IItemBlock, tAnimationVariantName } from '../../types';
import { answerBlockVariants } from './animationVariants';

interface IAnswerBlock {
  itemBlock: IItemBlock;
}

interface IArrangeItemsAnswerBlockRef {
  triggerAnimation: (variantName: tAnimationVariantName) => Promise<void>;
  cancelAnimation: () => Promise<void>;
}

const AnswerBlock = forwardRef<IArrangeItemsAnswerBlockRef, IAnswerBlock>(
  ({ itemBlock }, forwardedRef) => {
    const animationControls = useAnimation();

    useImperativeHandle(
      forwardedRef,
      () => ({
        triggerAnimation: (variantName: tAnimationVariantName) =>
          animationControls.start(variantName),
        cancelAnimation: () => animationControls.start('initPosition')
      }),
      [animationControls]
    );

    return (
      <SViewportAnswerBlock
        as={motion.div}
        drag={false}
        ref={(el: HTMLDivElement) =>
          itemBlock.setRef(el, `${itemBlock.option.id}_origin`, itemBlock.option.position)
        }
        initial={{ y: 10, scale: 0.8, opacity: 0 }}
        exit={{ opacity: [1, 0] }}
        animate={animationControls}
        variants={answerBlockVariants}
      >
        <SAnswerBlockInner>{itemBlock.option.position}</SAnswerBlockInner>
      </SViewportAnswerBlock>
    );
  }
);

export default AnswerBlock;

const SViewportAnswerBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-inline-end: 8px;
  height: 120px;
  width: 80px;
  flex: 0 0 80px;

  &:last-child {
    margin-inline-end: 16px;
  }

  ${customMediaQuery('tablet')} {
    height: ${100 + ANSWER_BLOCK_ADDITIONAL_HEIGHT}px;
    width: 100px;
    flex: 0 0 100px;
    margin-inline-end: 12px;

    &:first-child {
      margin-inline-start: 0;
    }
  }

  &.hovering > div {
    border: 4px solid var(--color-blue-400);
    background: rgba(0, 0, 0, 0.28);
  }
`;

const SAnswerBlockInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.18);
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.56), inset 0 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 1.5rem;
  font-weight: 700;
  height: 80px;
  width: 80px;
  flex: 0 0 80px;
  transition: 0.4s border, 0.4s background;
  user-select: none;

  ${customMediaQuery('tablet')} {
    height: 100px;
    width: 100px;
    flex: 0 0 100px;
  }
`;
