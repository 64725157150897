import { useCallback, useEffect, useMemo, useState } from 'react';

interface IReturnedObject {
  show: boolean;
  handleHide: () => void;
}

export const useShowStreak = (
  streakNumber: number,
  minStreakNumber: number
): IReturnedObject => {
  const [animatedOut, setAnimatedOut] = useState(false);

  useEffect(() => {
    setAnimatedOut(false);
  }, [streakNumber]);

  const show = useMemo(() => {
    return !(animatedOut || streakNumber < minStreakNumber);
  }, [animatedOut, streakNumber, minStreakNumber]);

  const handleHide = useCallback(() => {
    setAnimatedOut(true);
  }, []);

  return {
    show,
    handleHide
  };
};
