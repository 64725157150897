import { AnimationControls, useAnimation } from 'framer-motion';
import { useCallback } from 'react';
import { useAnimateScenarios } from 'students/views/shared/hooks';
import { dictationAnimateScenarios, hintButtonsScenarios } from '../../animations';

type tAnimationScenarioControls = ReturnType<typeof useAnimateScenarios>[1];
type tFn = () => void;

export function useAnimations(): {
  headerAnimationControls: AnimationControls;
  animateHeaderAnimationControls: tAnimationScenarioControls;
  contentAnimationControls: AnimationControls;
  animateContentAnimationControls: tAnimationScenarioControls;
  hintButtonsAnimationControls: AnimationControls;
  animateHintButtonsAnimationControls: tAnimationScenarioControls;
  timeoutBlockControls: AnimationControls;
  runItemAppearAnimation: tFn;
  runItemDisappearAnimation: tFn;
} {
  const [headerAnimationControls, animateHeaderAnimationControls] = useAnimateScenarios(
    dictationAnimateScenarios
  );
  const [contentAnimationControls, animateContentAnimationControls] = useAnimateScenarios(
    dictationAnimateScenarios
  );
  const [hintButtonsAnimationControls, animateHintButtonsAnimationControls] =
    useAnimateScenarios(hintButtonsScenarios);
  const timeoutBlockControls = useAnimation();

  const runItemAppearAnimation = useCallback(() => {
    animateHeaderAnimationControls(['header-appear']);
    animateContentAnimationControls(['content-appear']);
  }, [animateContentAnimationControls, animateHeaderAnimationControls]);

  const runItemDisappearAnimation = useCallback(() => {
    animateHeaderAnimationControls(['disappear']);
    animateContentAnimationControls(['disappear']);
  }, [animateContentAnimationControls, animateHeaderAnimationControls]);

  return {
    headerAnimationControls,
    animateHeaderAnimationControls,
    contentAnimationControls,
    animateContentAnimationControls,
    hintButtonsAnimationControls,
    animateHintButtonsAnimationControls,
    timeoutBlockControls,
    runItemAppearAnimation,
    runItemDisappearAnimation
  };
}
